import ReplaceUtils from '@MP/util/ReplaceUtils';
import { makeAutoObservable } from 'mobx';
import React from 'react';

export default class FindPasswordStore {
  email: string = '';
  name: string = '';
  phoneNumber: string = '';
  certNumber: string = '';
  certId: number | undefined;
  certOK: boolean | undefined;

  password: string = '';
  confirmPassword: string = '';
  constructor() {
    makeAutoObservable(this);
  }

  setEmail(email: string) {
    this.email = email.trim();
  }

  setName(name: string) {
    this.name = name.trim();
    this.setCertId(undefined);
    this.setCertOK(undefined);
  }

  setPhoneNumber(phoneNumber: string) {
    this.phoneNumber = ReplaceUtils.replaceNumber(phoneNumber);
    this.setCertId(undefined);
    this.setCertOK(undefined);
  }

  setCertNumber(certNumber: string) {
    this.certNumber = ReplaceUtils.replaceNumber(certNumber);
    this.setCertOK(undefined);
  }

  setCertId(certId?: number) {
    this.certId = certId;
  }

  setCertOK(ok?: boolean) {
    this.certOK = ok;
  }

  setPassword(pw: string, confirm: string) {
    this.password = pw.trim();
    this.confirmPassword = confirm.trim();
  }

  get isPossibleReset() {
    return (
      !!this.email &&
      !!this.certId &&
      !!this.certNumber &&
      !!this.password &&
      !!this.certOK &&
      this.password === this.confirmPassword
    );
  }

  get resetBody() {
    return {
      email: this.email,
      id: this.certId!,
      code: this.certNumber,
      password: this.password,
    };
  }
}

export const FindPasswordStoreContext: React.Context<FindPasswordStore> = React.createContext<FindPasswordStore>(
  {} as FindPasswordStore
);

export const useFindPasswordStore = () => React.useContext(FindPasswordStoreContext);
