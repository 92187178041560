/* tslint:disable */
/* eslint-disable */
/**
 * MINDD Survey API Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 부트페이 결제 요청 기록
 * @export
 * @interface PaymentRecord
 */
export interface PaymentRecord {
    /**
     * 청구서 ID
     * @type {string}
     * @memberof PaymentRecord
     */
    'invoiceId': string;
    /**
     * 결제 고유 ID
     * @type {string}
     * @memberof PaymentRecord
     */
    'orderId': string;
    /**
     * 결제 카드 키
     * @type {string}
     * @memberof PaymentRecord
     */
    'billingKey': string;
    /**
     * 부트페이가 부여하는 고유 영수증 ID. 이 값을 기반으로 결제 조회/취소 가능
     * @type {string}
     * @memberof PaymentRecord
     */
    'receiptId': string;
    /**
     * 결제/취소 금액
     * @type {number}
     * @memberof PaymentRecord
     */
    'price': number;
    /**
     * 결제 아이템 이름(예:유료 설문 과금)
     * @type {string}
     * @memberof PaymentRecord
     */
    'orderName': string;
    /**
     * 결제 수단(예:카드자동, 카드)
     * @type {string}
     * @memberof PaymentRecord
     */
    'methodOrigin': string;
    /**
     * 응답 시간
     * @type {string}
     * @memberof PaymentRecord
     */
    'responseAt': string;
    /**
     * 요청 시간
     * @type {string}
     * @memberof PaymentRecord
     */
    'requestedAt': string;
    /**
     * PG 사에서 제공하는 결제 영수증 URL
     * @type {string}
     * @memberof PaymentRecord
     */
    'receiptUrl': string;
    /**
     * 요청 결과
     * @type {string}
     * @memberof PaymentRecord
     */
    'status': PaymentRecordStatusEnum;
    /**
     * 요청 결과 코드(BootPay): 결제 성공(1), 결제 실패(-2), 취소 성공(20)
     * @type {number}
     * @memberof PaymentRecord
     */
    'statusCode'?: number;
    /**
     * 요청 결과 메시지(BootPay)
     * @type {string}
     * @memberof PaymentRecord
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRecord
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRecord
     */
    'createdTime': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRecord
     */
    'modifiedTime': string;
}

export const PaymentRecordStatusEnum = {
    Paid: 'PAID',
    Canceled: 'CANCELED',
    PaymentRequested: 'PAYMENT_REQUESTED',
    CancelRequested: 'CANCEL_REQUESTED',
    PaymentFailed: 'PAYMENT_FAILED',
    CancelFailed: 'CANCEL_FAILED'
} as const;

export type PaymentRecordStatusEnum = typeof PaymentRecordStatusEnum[keyof typeof PaymentRecordStatusEnum];


