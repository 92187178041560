import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Notice } from './typs';

const NOTICE_URL = process.env.REACT_APP_NOTICE_URL || '';
const API_KEY = process.env.REACT_APP_NOTICE_API_KEY || '';

export const useFetchNotice = () => {
  return useQuery<Notice[]>({
    queryKey: ['NOTICES'],
    queryFn: async () => {
      const res = await axios.get(`${NOTICE_URL}?serviceType=SURVEY`, {
        headers: { 'x-api-key': API_KEY },
      });
      return res.data;
    },
  });
};
