import React from 'react';
import { Outlet } from 'react-router';
import SignUpStore, { SignUpStoreContext } from './SignUpStore';

const SignUpPage = () => {
  const store = React.useMemo(() => new SignUpStore(), []);
  return (
    <SignUpStoreContext.Provider value={store}>
      <Outlet />
    </SignUpStoreContext.Provider>
  );
};

export default SignUpPage;
