/* tslint:disable */
/* eslint-disable */
/**
 * Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { Organization } from './organization';

/**
 * 
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'orgzId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    'tempPwd'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'tel'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'created'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    'salesman'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    'isSubscribeMarketingEvent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'role'?: EmployeeRoleEnum;
    /**
     * 
     * @type {Organization}
     * @memberof Employee
     */
    'orgz'?: Organization;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    'svcEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     * @deprecated
     */
    'partnered'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Employee
     * @deprecated
     */
    'branchOrgzId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     * @deprecated
     */
    'code'?: string;
}

export const EmployeeRoleEnum = {
    UNormal: 'U_NORMAL',
    UAdmin: 'U_ADMIN',
    USubject: 'U_SUBJECT',
    YAdmin: 'Y_ADMIN',
    YManager: 'Y_MANAGER',
    YOperator: 'Y_OPERATOR',
    YDistributor: 'Y_DISTRIBUTOR',
    PAdmin: 'P_ADMIN',
    PNormal: 'P_NORMAL'
} as const;

export type EmployeeRoleEnum = typeof EmployeeRoleEnum[keyof typeof EmployeeRoleEnum];


