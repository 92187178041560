import { Gender, Handedness } from '@MP/api/survey';
import { useExistsCode } from '@MP/api/surveyApi';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  Typography,
} from '@mui/joy';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, FC } from 'react';
import { PatientEditFormData } from './type';

const PatientEditForm: FC<{
  value: PatientEditFormData;
}> = observer(({ value }) => {
  const [birthError, setBirthError] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState(false);
  const [codeError, setCodeError] = React.useState(false);
  const { mutateAsync: existsCode } = useExistsCode();

  const handleBlurCode = async () => {
    if (value.originCode !== value.code) {
      const exists = await existsCode(value.code);
      value.setExistsCode(exists);
      setCodeError(exists);
    }
  };

  const handleBlurBirth = () => {
    if (value.birth) {
      setBirthError(!value.validateBirth());
    }
  };

  const handleBlurPhone = () => {
    if (value.phoneNumber) {
      setPhoneError(!value.validatePhoneNumber());
    }
  };

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    value.setCode(e.target.value);
    setCodeError(false);
  };

  const handleBirthChange = (e: ChangeEvent<HTMLInputElement>) => {
    value.setBirth(e.target.value);
    setBirthError(false);
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    value.setPhoneNumber(e.target.value);
    setPhoneError(false);
  };

  return (
    <Stack rowGap={'16px'}>
      <Stack direction={'row'} spacing={'16px'}>
        <FormControl size="lg" sx={{ width: 200 }} required>
          <FormLabel sx={{ fontSize: 13, color: '#666' }}>환자코드</FormLabel>
          <Input
            value={value.code}
            onChange={handleCodeChange}
            slotProps={{ input: { maxLength: 20 } }}
            onBlur={handleBlurCode}
          />
          {codeError && (
            <FormHelperText>
              <Typography level="body-xs" color="danger">
                동일한 코드의 환자가 이미 있습니다.
              </Typography>
            </FormHelperText>
          )}
        </FormControl>
        <FormControl size="lg" sx={{ width: 200 }} required>
          <FormLabel sx={{ fontSize: 13, color: '#666' }}>이름</FormLabel>
          <Input
            value={value.name}
            onChange={(e) => value.setName(e.target.value)}
            slotProps={{ input: { maxLength: 20 } }}
          />
        </FormControl>
        <FormControl size="lg" sx={{ width: 200 }} required>
          <FormLabel sx={{ fontSize: 13, color: '#666' }}>생년월일</FormLabel>
          <Input
            error={birthError}
            placeholder="YYYYMMDD"
            value={value.birth}
            onChange={handleBirthChange}
            onBlur={handleBlurBirth}
            slotProps={{ input: { maxLength: 8 } }}
          />
          {birthError && (
            <FormHelperText>
              <Typography level="body-xs" color="danger">
                올바른 생년월일을 입력해주세요.
              </Typography>
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
      <Stack direction={'row'} spacing={'16px'}>
        <FormControl size="lg" sx={{ width: 200 }}>
          <FormLabel sx={{ fontSize: 13, color: '#666' }}>휴대전화번호</FormLabel>
          <Input
            error={phoneError}
            value={value.phoneNumber}
            onChange={handlePhoneChange}
            onBlur={handleBlurPhone}
            slotProps={{ input: { pattern: '[0-9]{3}[0-9]{4}[0-9]{4}', maxLength: 11 } }}
          />
          {phoneError && (
            <FormHelperText>
              <Typography level="body-xs" color="danger">
                올바른 휴대전화번호를 입력해주세요.
              </Typography>
            </FormHelperText>
          )}
        </FormControl>
        <FormControl size="lg" sx={{ width: 200 }}>
          <FormLabel sx={{ fontSize: 13, color: '#666' }}>성별</FormLabel>
          <ButtonGroup sx={{ height: 44 }}>
            <Button
              variant={value.gender === 'MALE' ? 'solid' : undefined}
              color={value.gender === 'MALE' ? 'primary' : undefined}
              fullWidth
              onClick={() => value.setGender(Gender.Male)}>
              <Typography sx={{ fontSize: 16, color: 'inherit', fontWeight: 400 }}> 남성</Typography>
            </Button>
            <Button
              variant={value.gender === 'FEMALE' ? 'solid' : undefined}
              color={value.gender === 'FEMALE' ? 'primary' : undefined}
              fullWidth
              sx={{ fontWeight: 400 }}
              onClick={() => value.setGender(Gender.Female)}>
              <Typography sx={{ fontSize: 16, color: 'inherit', fontWeight: 400 }}> 여성</Typography>
            </Button>
            <Button
              variant={value.gender === 'OTHER' ? 'solid' : undefined}
              color={value.gender === 'OTHER' ? 'primary' : undefined}
              fullWidth
              sx={{ fontWeight: 400 }}
              onClick={() => value.setGender(Gender.Other)}>
              <Typography sx={{ fontSize: 16, color: 'inherit', fontWeight: 400 }}> 기타</Typography>
            </Button>
          </ButtonGroup>
        </FormControl>
        <FormControl size="lg" sx={{ width: 200 }}>
          <FormLabel sx={{ fontSize: 13, color: '#666' }}>손잡이</FormLabel>
          <Select<Handedness>
            value={value.handedness}
            defaultValue={Handedness.None}
            onChange={(_, op) => value.setHandedness(op!)}>
            <Option value={Handedness.None}>선택</Option>
            <Option value={Handedness.Right}>오른손잡이</Option>
            <Option value={Handedness.Left}>왼손잡이</Option>
            <Option value={Handedness.Ambidextrous}>양손잡이</Option>
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
});

export default PatientEditForm;
