import MRootContainer from '@MP/mobile/MRootContainer';
import { useGlobalStore } from '@MP/store/GlobalUIStore';
import WebTokenManger from '@MP/util/WebTokenManger';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  useRouteError,
} from 'react-router';
import { useFetchNotice } from './api/noticeApi';
import FindPasswordPage from './page/Auth/findPw/FindPasswordPage';
import SignUpPage from './page/Auth/signup/SignUpPage';
import MainLayout from './page/MainLayout';
import PatientPage from './page/Patient/PatientPage';

const ModuleChoiceContents = React.lazy(() => import('./page/Patient/contents/module/ModuleChoiceContents'));
const UsagePage = React.lazy(() => import('./page/Payment/UsagePage'));

if (window.MinddPlugin) {
  const env = process.env.REACT_APP_ENV as 'dev' | 'qa' | 'stg' | 'prod';
  window.MinddPlugin.on('MINDD SURVEY', env || 'dev', axios.interceptors.response);
}

export function HydrateFallback() {
  return <></>;
}

const ErrorBoundary = () => {
  const error = useRouteError();
  React.useEffect(() => {
    if ((error as any)?.name === 'ChunkLoadError') {
      window.location.reload();
    }
  }, [error]);

  return <></>;
};

const CheckAuth: FC<{ children: ReactNode }> = ({ children }) => {
  if (!WebTokenManger.hasToken()) {
    return <Navigate to={'/login'} replace />;
  }

  return <>{children}</>;
};

const App = observer(() => {
  const globalStore = useGlobalStore();
  const { data: notice } = useFetchNotice();

  React.useEffect(() => {
    if (notice) {
      globalStore.setNotice(notice);
    }
  }, [notice, globalStore]);

  let routes = createBrowserRouter(
    createRoutesFromElements(
      <Route ErrorBoundary={ErrorBoundary} HydrateFallback={HydrateFallback}>
        <Route
          path="m/create-subject"
          lazy={async () => {
            const component = await import('@MP/mobile/MNewPatient');
            return { Component: component.default };
          }}
        />
        <Route path="m" element={<MRootContainer />}>
          <Route
            path="session"
            lazy={async () => {
              const component = await import('@MP/mobile/MSurveySessionPage');
              return { Component: component.default };
            }}
          />
          <Route
            path="agreement"
            lazy={async () => {
              const component = await import('@MP/mobile/MServiceAgreementsPage');
              return { Component: component.default };
            }}
          />
          <Route
            path="sleep-diary"
            lazy={async () => {
              const component = await import('@MP/mobile/MSleepDiaryPage');
              return { Component: component.default };
            }}
          />
        </Route>
        <Route
          path="phr"
          lazy={async () => {
            const component = await import('@MP/mobile/MExpiredLegacySession');
            return { Component: component.default };
          }}
        />

        <Route
          path="print/stim"
          lazy={async () => {
            const component = await import('./page/Print/PrintStimAgreementPage');
            return { Component: component.default };
          }}
        />

        <Route
          path="login"
          lazy={async () => {
            const component = await import('./page/Auth/LoginPage');
            return { Component: component.default };
          }}
        />
        <Route path="signup" element={<SignUpPage />}>
          <Route
            path="agree"
            lazy={async () => {
              const component = await import('./page/Auth/signup/AgreementsPage');
              return { Component: component.default };
            }}
          />
          <Route
            path="hospital"
            lazy={async () => {
              const component = await import('./page/Auth/signup/HospitalPage');
              return { Component: component.default };
            }}
          />
          <Route
            path="account"
            lazy={async () => {
              const component = await import('./page/Auth/signup/AccountsPage');
              return { Component: component.default };
            }}
          />
          <Route path="" element={<Navigate to={`/`} replace />} />
        </Route>
        <Route path="findpw" element={<FindPasswordPage />}>
          <Route
            path="email"
            lazy={async () => {
              const component = await import('./page/Auth/findPw/EmailInputPage');
              return { Component: component.default };
            }}
          />
          <Route
            path="verification"
            lazy={async () => {
              const component = await import('./page/Auth/findPw/IdentityVerificationPage');
              return { Component: component.default };
            }}
          />
          <Route
            path="reset"
            lazy={async () => {
              const component = await import('./page/Auth/findPw/PasswordResetPage');
              return { Component: component.default };
            }}
          />
          <Route path="" element={<Navigate to={`/`} replace />} />
        </Route>

        <Route
          element={
            <CheckAuth>
              <MainLayout />
            </CheckAuth>
          }>
          <Route
            path="setting"
            lazy={async () => {
              const component = await import('./page/Setting/SettingPage');
              return { Component: component.default };
            }}
          />
          <Route
            path="stim"
            lazy={async () => {
              const component = await import('./page/Stim/StimPage');
              return { Component: component.default };
            }}
          />
          <Route path="patient" element={<PatientPage />}>
            <Route
              path="dashboard"
              lazy={async () => {
                const component = await import('./page/Patient/contents/DashboardContents');
                return { Component: component.default };
              }}
            />
            <Route
              path="survey"
              lazy={async () => {
                const component = await import('./page/Patient/contents/survey/SurveyContents');
                return { Component: component.default };
              }}
            />
            <Route
              path="stim"
              lazy={async () => {
                const component = await import('./page/Patient/contents/stim/StimContents');
                return { Component: component.default };
              }}
            />
            <Route
              path="survey/send"
              lazy={async () => {
                const component = await import('./page/Patient/contents/survey/SurveySendContents');
                return { Component: component.default };
              }}
            />
            <Route
              path="eeg/history"
              lazy={async () => {
                const component = await import('./page/Patient/contents/history/EEGHistoryContents');
                return { Component: component.default };
              }}
            />
            <Route
              path="eeg/modules"
              element={
                <Suspense fallback={<ErrorBoundary />}>
                  <ModuleChoiceContents type="EEG" />
                </Suspense>
              }
            />
            <Route
              path="eeg/prepare"
              lazy={async () => {
                const component = await import('./page/Patient/contents/prepare/EEGPrepareContents');
                return { Component: component.default };
              }}
            />
            <Route
              path="eeg/measure"
              lazy={async () => {
                const component = await import('./page/Patient/contents/measure/EEGMeasureContents');
                return { Component: component.default };
              }}
            />
            <Route
              path="eeg/compare"
              lazy={async () => {
                const component = await import('./page/Patient/contents/compare/EEGCompareContents');
                return { Component: component.default };
              }}
            />
            <Route
              path="hrv/history"
              lazy={async () => {
                const component = await import('./page/Patient/contents/history/HRVHistoryContents');
                return { Component: component.default };
              }}
            />
            <Route
              path="hrv/modules"
              element={
                <Suspense fallback={<ErrorBoundary />}>
                  <ModuleChoiceContents type="HRV" />
                </Suspense>
              }
            />
            <Route
              path="hrv/measure"
              lazy={async () => {
                const component = await import('./page/Patient/contents/measure/HRVMeasureContents');
                return { Component: component.default };
              }}
            />
            <Route
              path="sleep"
              lazy={async () => {
                const component = await import('./page/Patient/contents/sleep/SleepContents');
                return { Component: component.default };
              }}
            />
            <Route path="" element={<Navigate to={`/patient/survey`} replace />} />
          </Route>

          <Route path="mindd/payment">
            <Route
              path="manage"
              lazy={async () => {
                const component = await import('./page/Payment/PaymentManagePage');
                return { Component: component.default };
              }}
            />
            <Route
              path="usage"
              element={
                <Suspense fallback={<ErrorBoundary />}>
                  <UsagePage />
                </Suspense>
              }
            />
            <Route
              path="usage/register"
              element={
                <Suspense fallback={<ErrorBoundary />}>
                  <UsagePage register />
                </Suspense>
              }
            />
            <Route
              path="settlement"
              lazy={async () => {
                const component = await import('./page/Payment/SettlementPage');
                return { Component: component.default };
              }}
            />
          </Route>

          <Route path="/" element={<Navigate to={`/patient/survey`} replace />} />
        </Route>
        <Route path="*" element={<Navigate to={`/`} replace />} />
      </Route>
    )
  );

  return <RouterProvider router={routes} />;
});

export default App;
