import { ChannelLabel } from '@MP/feature/scan/type';
import { ICloseEvent, IMessageEvent, w3cwebsocket as W3CWebSocket } from 'websocket';
import SignalDataParser from './SignalDataParser';
import { EEGPrepareInfo, Quality, SignalInfo, SubscribeId } from './type';

enum MessageKind {
  SIGNAL = 'SIGNAL',
  EEG_QUALITY = 'EEG_QUALITY',
}

interface MessageEvent {
  type: MessageKind;
}

const NORMAL_CLOSURE_CODE = 1000;
const INVALID_TOKEN_CODE = 1011;
export class ModuleSocketImpl implements ModuleSocketClient {
  private _client: W3CWebSocket;
  connected: boolean = false;
  private hbIntervalId;
  listeners: MessageListener[] = [];
  currId: number = 0;
  constructor(client: W3CWebSocket) {
    this._client = client;
    this.hbIntervalId = this.sendHeartBeat();
    this.init();
  }

  private sendHeartBeat() {
    return setInterval(() => {
      if (this._client.readyState === W3CWebSocket.OPEN) {
        this._client.send('');
      }
    }, 60000);
  }

  private init() {
    this._client.onclose = (e: ICloseEvent) => {
      if (e.code !== NORMAL_CLOSURE_CODE && e.code !== INVALID_TOKEN_CODE) {
        setTimeout(() => {
          this._client = new W3CWebSocket(this._client.url);
          this.init();
        }, 5000);
      }
    };
    this._client.onerror = (error: Error) => {
      console.log(error);
    };
    this._client.onmessage = (message: IMessageEvent) => {
      let type: MessageKind | undefined;
      if (message.data instanceof Blob) {
        type = MessageKind.SIGNAL;
      } else {
        try {
          const event: MessageEvent = JSON.parse(message.data as string);
          type = event.type;
        } catch {
          // ignored: ping data
        }
      }

      this.listeners.forEach((listener) => {
        if (type && listener.condition(type)) {
          listener.callback(message);
        }
      });
    };
  }

  unsubscribe(id: SubscribeId) {
    this.listeners = this.listeners.filter((listen) => listen.id !== id);
  }

  subscribeOnSignal(callback: (data: SignalInfo) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.SIGNAL,
      callback: async (message: IMessageEvent) => {
        if (message.data instanceof Blob) {
          const signals = await SignalDataParser.parse(message.data);
          callback(signals);
        }
      },
    });

    return id;
  }

  subscribeOnSQI(callback: (data: EEGPrepareInfo) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.EEG_QUALITY,
      callback: (message: IMessageEvent) => {
        const data = JSON.parse(message.data as string);
        const impedanceList: Quality[] = [];
        const sqiList: Quality[] = [];
        data.payload.values.forEach(({ channel, impedance, sqi }: EEGQuality) => {
          impedanceList.push({ channel, value: impedance });
          sqiList.push({ channel, value: sqi });
        });

        callback({ impedanceList, sqiList });
      },
    });

    return id;
  }

  disconnect() {
    this.listeners = [];
    clearInterval(this.hbIntervalId);
    const intervalId = setInterval(() => {
      if (this._client.readyState !== W3CWebSocket.CONNECTING) {
        if (this._client.readyState === W3CWebSocket.OPEN) {
          this._client.close(NORMAL_CLOSURE_CODE);
        }
        clearInterval(intervalId);
      }
    }, 100);
  }
}

interface EEGQuality {
  channel: ChannelLabel;
  impedance: number;
  sqi: number;
}

export interface ModuleSocketClient {
  connected: boolean;
  subscribeOnSignal: (callback: (data: SignalInfo) => void) => SubscribeId;
  subscribeOnSQI: (callback: (data: EEGPrepareInfo) => void) => SubscribeId;
  unsubscribe: (id: SubscribeId) => void;
  disconnect: () => void;
}

interface MessageListener {
  id: number;
  condition: (kind: MessageKind) => boolean;
  callback: (message: IMessageEvent) => void;
}
