/* tslint:disable */
/* eslint-disable */
/**
 * MINDD Survey API Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BackofficePaidSurveyRecord } from '../model';
// @ts-ignore
import type { Invoice } from '../model';
// @ts-ignore
import type { InvoiceEntity } from '../model';
// @ts-ignore
import type { PaidSurveyRecord } from '../model';
// @ts-ignore
import type { PaidSurveyRecordCreateDto } from '../model';
// @ts-ignore
import type { SleepDiary } from '../model';
// @ts-ignore
import type { SleepSession } from '../model';
// @ts-ignore
import type { TestSleepDiaryUrl } from '../model';
// @ts-ignore
import type { TestSubjectMeta } from '../model';
/**
 * TestControllerApi - axios parameter creator
 * @export
 */
export const TestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 모든 병원에 대해 발행된 청구서를 확정한다.
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmInvoices: async (yearMonth: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('confirmInvoices', 'yearMonth', yearMonth)
            const localVarPath = `/test/api/v1/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 특정 병원에서 이번 달 현재까지 사용한 내역에 대해 청구서를 발행한다.
         * @param {number} orgId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceManually: async (orgId: number, yearMonth: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('createInvoiceManually', 'orgId', orgId)
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('createInvoiceManually', 'yearMonth', yearMonth)
            const localVarPath = `/test/api/v1/{orgId}/invoices`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 모든 병원에 대해 청구서를 발행한다.
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoices: async (yearMonth: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('createInvoices', 'yearMonth', yearMonth)
            const localVarPath = `/test/api/v1/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 수면 일기 목록 조회
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiaries: async (sessionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('getDiaries', 'sessionId', sessionId)
            const localVarPath = `/test/api/v1/{sessionId}/sleepDiaries`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Backoffice] 조건에 맞는 이용 내역 전달
         * @param {number} orgId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyPaidSurveyRecords: async (orgId: number, yearMonth: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getMonthlyPaidSurveyRecords', 'orgId', orgId)
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('getMonthlyPaidSurveyRecords', 'yearMonth', yearMonth)
            const localVarPath = `/test/api/v1/{orgId}/paid-survey-records`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 수면 세션 목록 조회
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessions: async (orgId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getSessions', 'orgId', orgId)
            const localVarPath = `/test/api/v1/{orgId}/sleepSessions`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 병원 환자 목록 조회
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectMetas: async (orgId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getSubjectMetas', 'orgId', orgId)
            const localVarPath = `/test/api/v1/{orgId}/subjectMetas`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 유료 설문 이력들을 추가한다.
         * @param {Array<PaidSurveyRecordCreateDto>} paidSurveyRecordCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPaidSurveyRecord: async (paidSurveyRecordCreateDto: Array<PaidSurveyRecordCreateDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paidSurveyRecordCreateDto' is not null or undefined
            assertParamExists('insertPaidSurveyRecord', 'paidSurveyRecordCreateDto', paidSurveyRecordCreateDto)
            const localVarPath = `/test/api/v1/paid-surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paidSurveyRecordCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * from, to를 같은 달로 주면 특정 달의 청구서만 결제 시도한다. statusList를 지정하지 않으면 CONFIRMED 상태의 청구서만 결제 시도한다.
         * @summary 특정 병원의 청구서에 대해 결제를 시도한다.
         * @param {number} orgId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<PaymentStatusListEnum>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payment: async (orgId: number, from?: string, to?: string, statusList?: Array<PaymentStatusListEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('payment', 'orgId', orgId)
            const localVarPath = `/test/api/v1/{orgId}/payments`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (statusList) {
                localVarQueryParameter['statusList'] = statusList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 결제 가능한 모든 청구서에 대하여 결제를 시도한다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payments: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test/api/v1/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 수면 일기 url 및 token 생성
         * @param {string} sleepDiaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sleepDiaryUrl: async (sleepDiaryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sleepDiaryId' is not null or undefined
            assertParamExists('sleepDiaryUrl', 'sleepDiaryId', sleepDiaryId)
            const localVarPath = `/test/api/v1/sleepDiaries/{sleepDiaryId}/url`
                .replace(`{${"sleepDiaryId"}}`, encodeURIComponent(String(sleepDiaryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestControllerApi - functional programming interface
 * @export
 */
export const TestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 모든 병원에 대해 발행된 청구서를 확정한다.
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmInvoices(yearMonth: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmInvoices(yearMonth, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestControllerApi.confirmInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 특정 병원에서 이번 달 현재까지 사용한 내역에 대해 청구서를 발행한다.
         * @param {number} orgId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoiceManually(orgId: number, yearMonth: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoiceManually(orgId, yearMonth, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestControllerApi.createInvoiceManually']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 모든 병원에 대해 청구서를 발행한다.
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoices(yearMonth: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoices(yearMonth, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestControllerApi.createInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 수면 일기 목록 조회
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiaries(sessionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SleepDiary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiaries(sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestControllerApi.getDiaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Backoffice] 조건에 맞는 이용 내역 전달
         * @param {number} orgId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyPaidSurveyRecords(orgId: number, yearMonth: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackofficePaidSurveyRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyPaidSurveyRecords(orgId, yearMonth, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestControllerApi.getMonthlyPaidSurveyRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 수면 세션 목록 조회
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessions(orgId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SleepSession>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessions(orgId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestControllerApi.getSessions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 병원 환자 목록 조회
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubjectMetas(orgId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TestSubjectMeta>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubjectMetas(orgId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestControllerApi.getSubjectMetas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 유료 설문 이력들을 추가한다.
         * @param {Array<PaidSurveyRecordCreateDto>} paidSurveyRecordCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertPaidSurveyRecord(paidSurveyRecordCreateDto: Array<PaidSurveyRecordCreateDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaidSurveyRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertPaidSurveyRecord(paidSurveyRecordCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestControllerApi.insertPaidSurveyRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * from, to를 같은 달로 주면 특정 달의 청구서만 결제 시도한다. statusList를 지정하지 않으면 CONFIRMED 상태의 청구서만 결제 시도한다.
         * @summary 특정 병원의 청구서에 대해 결제를 시도한다.
         * @param {number} orgId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<PaymentStatusListEnum>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payment(orgId: number, from?: string, to?: string, statusList?: Array<PaymentStatusListEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payment(orgId, from, to, statusList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestControllerApi.payment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 결제 가능한 모든 청구서에 대하여 결제를 시도한다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payments(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payments(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestControllerApi.payments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 수면 일기 url 및 token 생성
         * @param {string} sleepDiaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sleepDiaryUrl(sleepDiaryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestSleepDiaryUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sleepDiaryUrl(sleepDiaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestControllerApi.sleepDiaryUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestControllerApi - factory interface
 * @export
 */
export const TestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 모든 병원에 대해 발행된 청구서를 확정한다.
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmInvoices(yearMonth: string, options?: any): AxiosPromise<Array<Invoice>> {
            return localVarFp.confirmInvoices(yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 특정 병원에서 이번 달 현재까지 사용한 내역에 대해 청구서를 발행한다.
         * @param {number} orgId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceManually(orgId: number, yearMonth: string, options?: any): AxiosPromise<Invoice> {
            return localVarFp.createInvoiceManually(orgId, yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 모든 병원에 대해 청구서를 발행한다.
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoices(yearMonth: string, options?: any): AxiosPromise<Array<Invoice>> {
            return localVarFp.createInvoices(yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 수면 일기 목록 조회
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiaries(sessionId: string, options?: any): AxiosPromise<Array<SleepDiary>> {
            return localVarFp.getDiaries(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Backoffice] 조건에 맞는 이용 내역 전달
         * @param {number} orgId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyPaidSurveyRecords(orgId: number, yearMonth: string, options?: any): AxiosPromise<Array<BackofficePaidSurveyRecord>> {
            return localVarFp.getMonthlyPaidSurveyRecords(orgId, yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 수면 세션 목록 조회
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessions(orgId: number, options?: any): AxiosPromise<Array<SleepSession>> {
            return localVarFp.getSessions(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 병원 환자 목록 조회
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectMetas(orgId: number, options?: any): AxiosPromise<Array<TestSubjectMeta>> {
            return localVarFp.getSubjectMetas(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 유료 설문 이력들을 추가한다.
         * @param {Array<PaidSurveyRecordCreateDto>} paidSurveyRecordCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPaidSurveyRecord(paidSurveyRecordCreateDto: Array<PaidSurveyRecordCreateDto>, options?: any): AxiosPromise<Array<PaidSurveyRecord>> {
            return localVarFp.insertPaidSurveyRecord(paidSurveyRecordCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * from, to를 같은 달로 주면 특정 달의 청구서만 결제 시도한다. statusList를 지정하지 않으면 CONFIRMED 상태의 청구서만 결제 시도한다.
         * @summary 특정 병원의 청구서에 대해 결제를 시도한다.
         * @param {number} orgId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<PaymentStatusListEnum>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payment(orgId: number, from?: string, to?: string, statusList?: Array<PaymentStatusListEnum>, options?: any): AxiosPromise<Array<InvoiceEntity>> {
            return localVarFp.payment(orgId, from, to, statusList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 결제 가능한 모든 청구서에 대하여 결제를 시도한다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payments(options?: any): AxiosPromise<void> {
            return localVarFp.payments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 수면 일기 url 및 token 생성
         * @param {string} sleepDiaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sleepDiaryUrl(sleepDiaryId: string, options?: any): AxiosPromise<TestSleepDiaryUrl> {
            return localVarFp.sleepDiaryUrl(sleepDiaryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestControllerApi - object-oriented interface
 * @export
 * @class TestControllerApi
 * @extends {BaseAPI}
 */
export class TestControllerApi extends BaseAPI {
    /**
     * 
     * @summary 모든 병원에 대해 발행된 청구서를 확정한다.
     * @param {string} yearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public confirmInvoices(yearMonth: string, options?: RawAxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).confirmInvoices(yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 특정 병원에서 이번 달 현재까지 사용한 내역에 대해 청구서를 발행한다.
     * @param {number} orgId 
     * @param {string} yearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public createInvoiceManually(orgId: number, yearMonth: string, options?: RawAxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).createInvoiceManually(orgId, yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 모든 병원에 대해 청구서를 발행한다.
     * @param {string} yearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public createInvoices(yearMonth: string, options?: RawAxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).createInvoices(yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 수면 일기 목록 조회
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public getDiaries(sessionId: string, options?: RawAxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).getDiaries(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Backoffice] 조건에 맞는 이용 내역 전달
     * @param {number} orgId 
     * @param {string} yearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public getMonthlyPaidSurveyRecords(orgId: number, yearMonth: string, options?: RawAxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).getMonthlyPaidSurveyRecords(orgId, yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 수면 세션 목록 조회
     * @param {number} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public getSessions(orgId: number, options?: RawAxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).getSessions(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 병원 환자 목록 조회
     * @param {number} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public getSubjectMetas(orgId: number, options?: RawAxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).getSubjectMetas(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 유료 설문 이력들을 추가한다.
     * @param {Array<PaidSurveyRecordCreateDto>} paidSurveyRecordCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public insertPaidSurveyRecord(paidSurveyRecordCreateDto: Array<PaidSurveyRecordCreateDto>, options?: RawAxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).insertPaidSurveyRecord(paidSurveyRecordCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * from, to를 같은 달로 주면 특정 달의 청구서만 결제 시도한다. statusList를 지정하지 않으면 CONFIRMED 상태의 청구서만 결제 시도한다.
     * @summary 특정 병원의 청구서에 대해 결제를 시도한다.
     * @param {number} orgId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {Array<PaymentStatusListEnum>} [statusList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public payment(orgId: number, from?: string, to?: string, statusList?: Array<PaymentStatusListEnum>, options?: RawAxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).payment(orgId, from, to, statusList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 결제 가능한 모든 청구서에 대하여 결제를 시도한다.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public payments(options?: RawAxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).payments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 수면 일기 url 및 token 생성
     * @param {string} sleepDiaryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public sleepDiaryUrl(sleepDiaryId: string, options?: RawAxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).sleepDiaryUrl(sleepDiaryId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const PaymentStatusListEnum = {
    Estimated: 'ESTIMATED',
    Issued: 'ISSUED',
    Confirmed: 'CONFIRMED',
    Complete: 'COMPLETE',
    PaymentRequested: 'PAYMENT_REQUESTED',
    PaymentCancelRequested: 'PAYMENT_CANCEL_REQUESTED',
    Exempted: 'EXEMPTED'
} as const;
export type PaymentStatusListEnum = typeof PaymentStatusListEnum[keyof typeof PaymentStatusListEnum];
