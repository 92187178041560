import { FC } from 'react';
import NoticeDialog from './NoticeDialog';
import { Notice } from './api/typs';

const NoticeProvider: FC<{ notices: Notice[]; onClose: (notice: Notice, optionChecked: boolean) => void }> = ({
  notices,
  onClose,
}) => {
  return (
    <>
      {notices.map((v) => (
        <NoticeDialog key={v.timestampId} notice={v} onClose={(optionChecked) => onClose(v, optionChecked)} />
      ))}
    </>
  );
};

export default NoticeProvider;
