import { ModuleSocketClient } from '@MP/api/socket/scanModuleSocket';
import { OrgSocketClient } from '@MP/api/socket/scanOrgSocket';
import { ScanSocket } from '@MP/api/socket/scanSocket';
import { makeAutoObservable } from 'mobx';
import ModulesStatusManager from './ModulesStatusManager';
import { DeviceSerial, ScanModule } from './type';

class ScanModules {
  private _moduleMap: Record<DeviceSerial, ScanModule> = {};
  private modules: Readonly<ScanModule>[];
  constructor(modules: Readonly<ScanModule>[]) {
    this.modules = modules;
    modules.forEach(module => {
      this._moduleMap[module.deviceSerial] = module;
    });
  }

  get list() {
    return this.modules;
  }

  getModule(moduleSerial: DeviceSerial): ScanModule | undefined {
    return this._moduleMap[moduleSerial];
  }
}

/**
 * 모듈 상태 변화는 [module_state.png] 이미지 참고
 */
class ScanModulesStore {
  orgClient: OrgSocketClient | undefined;
  moduleClients: Record<string, ModuleSocketClient> = {};
  statusManager: ModulesStatusManager | undefined;
  scanModules: ScanModules = new ScanModules([]);
  constructor() {
    makeAutoObservable(this);
  }

  async init(deviceSerials: DeviceSerial[]) {
    this.orgClient = await ScanSocket.connectOrg();
    this.initializeOrgClient();
    this.initializeModules(this.orgClient, deviceSerials);
  }

  private initializeOrgClient() {
    this.orgClient?.subscribeOnRegistration(this.addModule.bind(this));
    this.orgClient?.subscribeOnDisconnected(this.removeModule.bind(this));
  }

  private initializeModules(orgClient: OrgSocketClient, deviceSerials: DeviceSerial[]) {
    try {
      this.statusManager = ModulesStatusManager.create(orgClient, deviceSerials, module => this.refreshModule(module));
    } catch (error) {
      console.error('Error initializing modules:', error);
    }
  }
  private refreshModule(modules: Readonly<ScanModule>[]) {
    this.scanModules = new ScanModules(modules);
  }

  private addModule(deviceSerial: DeviceSerial) {
    this.statusManager?.addModules(deviceSerial);
  }

  private removeModule(deviceSerial: DeviceSerial) {
    this.moduleClients[deviceSerial]?.disconnect();
    delete this.moduleClients[deviceSerial];
  }

  clearModule() {
    Object.values(this.moduleClients).forEach(client => {
      client.disconnect();
    });
    this.statusManager?.destroy();
    this.statusManager = undefined;
    this.moduleClients = {};
    this.scanModules = new ScanModules([]);
  }

  getModuleClient(deviceSerial: DeviceSerial) {
    if (deviceSerial) {
      if (!this.moduleClients[deviceSerial]) {
        this.moduleClients[deviceSerial] = ScanSocket.connectModule(deviceSerial);
      }

      return this.moduleClients[deviceSerial];
    }
    return undefined;
  }

  get modules() {
    return this.scanModules.list;
  }

  getModuleByUser(userId: string) {
    return this.modules.find(m => m.userId === userId);
  }

  getModule(moduleSerial: DeviceSerial) {
    return this.scanModules.getModule(moduleSerial);
  }

  clear() {
    this.clearModule();
    ScanSocket.disconnectOrg();
  }
}

export default ScanModulesStore;
