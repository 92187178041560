import { useFetchOrganizationOfCurrentUser } from '@MP/api/authApi';
import { RectangleButton } from '@MP/component/Button';
import { Box, DialogActions, DialogContent, Divider, Modal, ModalDialog, Stack, Typography } from '@mui/joy';
import React, { FC } from 'react';
import { RiPrinterFill } from 'react-icons/ri';
import QRCode from 'react-qr-code';

const NewPatientQRDialog: FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const { data: org } = useFetchOrganizationOfCurrentUser();

  const qrUrl = React.useMemo(() => {
    if (org) {
      const { protocol, host } = window.location;
      return `${protocol}//${host}/m/create-subject?orgId=${org.id}&secretKey=${org.id}_${org.code}`;
    }
    return '';
  }, [org]);

  if (!qrUrl) {
    return <></>;
  }

  return (
    <>
      <Stack
        className="print-qr-container"
        alignItems={'center'}
        justifyContent={'center'}
        position={'fixed'}
        left={0}
        top={0}
        bottom={0}
        right={0}
        spacing={'140px'}
        visibility={'hidden'}>
        <Stack spacing={'12px'} alignItems={'center'}>
          <Typography sx={{ fontSize: 46, color: '#333' }} fontWeight={600}>
            환자 추가 QR 코드
          </Typography>
          <Typography sx={{ fontSize: 30, color: '#333' }}>
            휴대전화 카메라로 QR 촬영해서 환자를 추가 해주세요.
          </Typography>
        </Stack>
        <QRCode value={qrUrl} size={500} />
      </Stack>
      <Modal open={open}>
        <ModalDialog sx={{ gap: '16px' }}>
          <Box>
            <Typography level="h4" fontWeight={600}>
              환자 추가 QR 코드
            </Typography>
            <Typography level="body-sm">휴대전화 카메라로 QR 촬영해서 환자를 추가 해주세요.</Typography>
            <Typography level="body-sm">
              환자 추가 QR 코드는 항상 동일하기 때문에, 출력해서 사용할 수 있습니다.
            </Typography>
          </Box>

          <DialogContent sx={{ width: '500px' }}>
            <Stack alignItems={'center'}>
              <QRCode value={qrUrl} />
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ pt: 0 }}>
            <RectangleButton onClick={onClose}>확인</RectangleButton>
            <RectangleButton variant="outlined" onClick={() => window.print()} startDecorator={<RiPrinterFill />}>
              출력하기
            </RectangleButton>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default NewPatientQRDialog;
