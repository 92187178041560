import { Agreement, ServiceType, SubjectMeta } from '@MP/api/survey';
import { useGetAgreementUrl, useSendAgreement, useUpdatePatient } from '@MP/api/surveyApi';
import { RectangleButton } from '@MP/component/Button';
import { useMinddSnackbar } from '@MP/component/MinddSnackbarProvider';
import Section from '@MP/component/Section';
import MemberDeleteIcon from '@MP/icon/MemberDeleteIcon';
import PhoneNumberUtils from '@MP/util/PhoneNumberUtils';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent } from 'react';
import { GiEmptyHourglass } from 'react-icons/gi';
import { TfiClose } from 'react-icons/tfi';
import QRCode from 'react-qr-code';
import AgreementList from './AgreementList';
import PatientEditForm from './PatientEditForm';
import { usePatientStore } from './PatientProvider';
import { PatientEditFormData } from './type';

interface Props {
  value: SubjectMeta;
  agreements?: Agreement[];
  onClose: () => void;
  onDelete: () => void;
}

const PatientEditDialog: FC<Props> = observer(({ value, agreements = [], onClose, onDelete }) => {
  const [step, setStep] = React.useState(0);
  const [qrUrl, setQrUrl] = React.useState('');
  const [formData, setFormData] = React.useState(new PatientEditFormData(value));

  const snackbar = useMinddSnackbar();

  const { mutateAsync: sendAgreement, isPending: isSending } = useSendAgreement();
  const { mutateAsync: getAgreementUrl, isPending: isGetting } = useGetAgreementUrl();

  React.useEffect(() => {
    setFormData(new PatientEditFormData(value));
  }, [value]);

  React.useEffect(() => {
    formData.setAgreementsStatus(agreements);
  }, [formData, agreements]);

  const handleSendAgreements = async () => {
    if (formData.id) {
      const url = await getAgreementUrl({
        service: formData.agreement,
        subjectMetaId: formData.id,
      });
      setQrUrl(url);
    }
  };

  const handleSend = async () => {
    if (formData.id) {
      await sendAgreement({
        service: formData.agreement,
        subjectMetaId: formData.id,
      });
      snackbar.add('동의서가 발송되었습니다.');
      onClose();
    }
  };

  return (
    <Modal open>
      <>
        {step === 0 && (
          <PatientEditModalDialog value={formData} onClose={onClose} onNext={() => setStep(1)} onDelete={onDelete} />
        )}
        {!qrUrl && step === 1 && (
          <AgreementListModalDialog
            value={formData}
            loading={isGetting}
            onClose={onClose}
            onPrev={() => setStep(0)}
            onSubmit={handleSendAgreements}
          />
        )}
        {qrUrl && step === 1 && (
          <QRCodeModalDialog
            url={qrUrl}
            loading={isSending}
            validPhone={PhoneNumberUtils.isValid(formData.phoneNumber)}
            onClose={onClose}
            onSend={handleSend}
          />
        )}
      </>
    </Modal>
  );
});

const PatientEditModalDialog: FC<{
  value: PatientEditFormData;
  onClose: () => void;
  onNext: () => void;
  onDelete: () => void;
}> = observer(({ value, onNext, onClose, onDelete }) => {
  const allServiceLength = Object.keys(ServiceType).length;

  const patientStore = usePatientStore();
  const snackbar = useMinddSnackbar();
  const { mutateAsync: updatePatient, isPending: isUpdating } = useUpdatePatient();

  const handleUpdate = async (e: FormEvent) => {
    e.preventDefault();
    if (value.id) {
      const res = await updatePatient({
        subjectMetaId: value.id,
        body: {
          code: value.code,
          name: value.name,
          handedness: value.handedness || 'NONE',
          gender: value.gender,
          phoneNumber: value.phoneNumber,
          birthYear: parseInt(value.birth.substring(0, 4), 10),
          birthMonth: parseInt(value.birth.substring(4, 6), 10),
          birthDay: parseInt(value.birth.substring(6, 8), 10),
        },
      });
      snackbar.add('환자 정보가 수정되었습니다.');
      patientStore.updateUser(res, true);
    }
    onClose();
  };

  const handlePopup = () => {
    const stim = value.agreed.find((v) => v.service === 'STIM');

    window.open(
      `/print/stim?userName=${value.name}&date=${stim?.createdTime}`,
      'AgreementView',
      `width=700, height=900, status=no, menubar=no, toolbar=no, resizable=no`
    );
  };

  return (
    <ModalDialog sx={{ gap: '16px' }} component={'form'} onSubmit={handleUpdate}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography level="h4" fontWeight={600}>
          환자 정보
        </Typography>
        <IconButton onClick={onClose}>
          <TfiClose fontSize={20} />
        </IconButton>
      </Stack>
      <DialogContent sx={{ gap: '16px' }}>
        <PatientEditForm value={value} />
        <Box>
          <Typography sx={{ fontSize: 13, color: '#666', fontWeight: 500 }}>동의한 항목</Typography>
          <Section sx={{ p: '16px', mt: '8px' }}>
            <Stack gap={'16px'} divider={<Divider />}>
              {value.agreedTypes.includes('THIRD_PARTY') && (
                <Typography level="body-sm" sx={{ color: '#666' }}>
                  3자 제공 동의
                </Typography>
              )}
              {value.agreedTypes.includes('SENSITIVE_INFO') && (
                <Typography level="body-sm" sx={{ color: '#666' }}>
                  민감정보의 처리에 관한 동의
                </Typography>
              )}
              {value.agreedTypes.includes('STIM') && (
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography level="body-sm" sx={{ color: '#666' }}>
                    MINDD STIM+ 비급여 동의
                  </Typography>
                  <Button variant="plain" onClick={handlePopup}>
                    동의서 출력
                  </Button>
                </Stack>
              )}
              {value.agreedTypes.length === 0 && (
                <Stack alignItems={'center'} spacing={'4px'}>
                  <GiEmptyHourglass fontSize={20} />
                  <Typography level="body-sm" sx={{ color: '#666' }}>
                    동의한 항목이 없습니다.
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Section>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ pt: 0 }}>
        <RectangleButton type="submit" loading={isUpdating}>
          저장
        </RectangleButton>
        {value.agreed.length < allServiceLength && (
          <RectangleButton variant="outlined" onClick={onNext}>
            동의서 전송
          </RectangleButton>
        )}
        <Box flexGrow={1}>
          <RectangleButton
            disabled={isUpdating}
            variant="plain"
            sx={{ height: 52 }}
            startDecorator={<MemberDeleteIcon />}
            onClick={onDelete}>
            <Typography color="danger" sx={{ fontSize: 16, fontWeight: 400 }}>
              환자 삭제
            </Typography>
          </RectangleButton>
        </Box>
      </DialogActions>
    </ModalDialog>
  );
});

const AgreementListModalDialog: FC<{
  value: PatientEditFormData;
  loading: boolean;
  onPrev: () => void;
  onClose: () => void;
  onSubmit: () => void;
}> = observer(({ value, loading, onClose, onPrev, onSubmit }) => {
  return (
    <ModalDialog sx={{ gap: '16px' }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography level="h4" fontWeight={600}>
          동의서 전송
        </Typography>
        <IconButton onClick={onClose}>
          <TfiClose fontSize={20} />
        </IconButton>
      </Stack>
      <DialogContent sx={{ width: 620 }}>
        <AgreementList value={value.agreement} agreed={value.agreedTypes} onChange={(v) => value.setAgreement(v)} />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ pt: 0 }}>
        <RectangleButton disabled={value.agreement.length === 0} onClick={onSubmit} loading={loading}>
          확인
        </RectangleButton>
        <RectangleButton color="neutral" variant="outlined" onClick={onPrev} disabled={loading}>
          이전
        </RectangleButton>
      </DialogActions>
    </ModalDialog>
  );
});

const QRCodeModalDialog: FC<{
  url: string;
  loading?: boolean;
  validPhone?: boolean;
  onClose: () => void;
  onSend: () => void;
}> = observer(({ url, validPhone, loading, onClose, onSend }) => {
  return (
    <ModalDialog sx={{ gap: '16px' }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
        <Box>
          <Typography level="h4" fontWeight={600}>
            약관 동의 QR 코드
          </Typography>
          {validPhone && (
            <Typography level="body-sm" fontWeight={500} sx={{ color: '#999999' }}>
              * 환자 휴대전화로 설문을 보내거나, QR 코드 스캔 후 약관 동의를 진행해 주세요.
            </Typography>
          )}
          {!validPhone && (
            <Typography level="body-sm" fontWeight={500} sx={{ color: '#999999' }}>
              * QR 코드 스캔 후 약관 동의를 진행해 주세요.
            </Typography>
          )}
        </Box>
        <IconButton onClick={onClose}>
          <TfiClose fontSize={20} />
        </IconButton>
      </Stack>

      <DialogContent sx={{ width: 620 }}>
        <Stack gap={'16px'}>
          <Stack alignItems={'center'}>
            <QRCode value={url} />
          </Stack>
          <Divider />
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={'8px'}>
            {validPhone && (
              <RectangleButton variant="outlined" onClick={onSend} loading={loading}>
                보내기
              </RectangleButton>
            )}
            <RectangleButton onClick={onClose}>완료</RectangleButton>
          </Stack>
        </Stack>
      </DialogContent>
    </ModalDialog>
  );
});

export default PatientEditDialog;
