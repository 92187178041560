import { IconButton, Tooltip } from '@mui/joy';
import { FC, ReactNode } from 'react';

interface NaviButtonProps {
  icon: ReactNode;
  name: string;
  active?: boolean;
  onClick: () => void;
}

const NaviButton: FC<NaviButtonProps> = ({ name, icon, active, onClick }) => {
  return (
    <Tooltip title={name} variant="outlined" color="primary" placement="right">
      <IconButton
        color="primary"
        variant="solid"
        sx={{ width: 48, height: 48, bgcolor: active ? '#058AC8' : undefined }}
        onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default NaviButton;
