import { BDF_ID_FULL_TEXT, ID_BYTE, ID_PREFIX_BYTE } from './const';
import { SignalDataView } from './type';

export default class SignalReadableContents {
  private signalFile: ArrayBuffer;
  private dataView: SignalDataView;
  private decoder: TextDecoder;
  private offset: number;

  private id: string = '';

  constructor(file: ArrayBuffer) {
    this.signalFile = file;
    this.decoder = new TextDecoder('utf-8');
    this.offset = ID_BYTE;
    this.dataView = new SignalDataView(this.signalFile);

    const prefix = this.dataView.getUint8(0);
    const valueArr: ArrayBuffer = this.signalFile.slice(ID_PREFIX_BYTE, ID_BYTE);
    const postfix = this.decoder.decode(valueArr).trim();
    this.id = `${prefix}${postfix}`;
  }

  isBdf() {
    return this.id === BDF_ID_FULL_TEXT;
  }

  /**
   * Identification code : offset 0 ~ 7
   * @returns
   */
  readId() {
    return this.id;
  }

  /**
   * 문자열 데이터를 읽고, 읽은 byte 수 만큼 offset 이동함
   * @param length byte 길이
   * @returns
   */
  readText(length: number): string {
    const valueArr: ArrayBuffer = this.signalFile.slice(this.offset, this.offset + length);
    const value = this.decoder.decode(valueArr).trim();
    this.offset += length;
    return value;
  }

  /**
   * 1byte 숫자 데이터를 읽고, 읽은 byte 수 만큼 offset 이동함
   * @returns
   */
  readUint8(): number {
    return this.dataView.getUint8(this.offset++);
  }

  /**
   * 2byte 숫자 데이터를 읽고, 읽은 byte 수 만큼 offset 이동함
   * @returns
   */
  readInt16LE(): number {
    const value = this.dataView.getInt16(this.offset, true);
    this.offset += 2;
    return value;
  }

  /**
   * 3byte 숫자 데이터를 읽고, 읽은 byte 수 만큼 offset 이동함
   * @returns
   */
  readInt24LE(): number {
    const value = this.dataView.getInt24LE(this.offset);
    this.offset += 3;
    return value;
  }
}
