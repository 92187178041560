import axios from 'axios';
import Cookies from 'js-cookie';

const addTokenCookie = (token: string) => {
  if (!window.location.host.startsWith('localhost')) {
    Cookies.set(`${process.env.REACT_APP_COOKIE_PREFIX}_token`, token, {
      domain: `.mindd.ybrain.com`,
      expires: 7,
    });
  } else {
    Cookies.set(`${process.env.REACT_APP_COOKIE_PREFIX}_token`, token, { expires: 7 });
  }
};

const removeTokenCookie = () => {
  if (!window.location.host.startsWith('localhost')) {
    Cookies.remove(`${process.env.REACT_APP_COOKIE_PREFIX}_token`, {
      domain: `.mindd.ybrain.com`,
    });
  } else {
    Cookies.remove(`${process.env.REACT_APP_COOKIE_PREFIX}_token`);
  }
};

const WebTokenManger = {
  hasToken: () => window.localStorage.getItem('token') !== null,
  getToken: () => window.localStorage.getItem('token'),
  setToken: (token: string) => {
    window.localStorage.setItem('token', token);
    addTokenCookie(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  clear: () => {
    window.localStorage.removeItem('token');
    removeTokenCookie();
    axios.defaults.headers.common['Authorization'] = ``;
  },
};

const token = window.localStorage.getItem('token');
if (token) {
  addTokenCookie(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
} else {
  removeTokenCookie();
  axios.defaults.headers.common['Authorization'] = '';
}

export default WebTokenManger;
