import { useFetchModuleSerials } from '@MP/api/scanApi';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode } from 'react';
import ScanModulesStore from './ScanModulesStore';

export const ScanModuleContext = React.createContext<ScanModulesStore>({} as ScanModulesStore);

const ScanModuleProvider: FC<{ children: ReactNode }> = observer(({ children }) => {
  const { data: moduleSerials } = useFetchModuleSerials();
  const store = React.useMemo(() => new ScanModulesStore(), []);

  React.useEffect(() => {
    if (!moduleSerials) return;

    store.init(moduleSerials);

    return () => {
      store.clear();
    };
  }, [store, moduleSerials]);

  return <ScanModuleContext.Provider value={store}>{children}</ScanModuleContext.Provider>;
});

export const useModuleStore = () => React.useContext(ScanModuleContext);

export default ScanModuleProvider;
