import { FormControl, FormHelperText, FormLabel, Input, Stack, Typography } from '@mui/joy';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import { PatientAddFormData } from './type';
import { useExistsCode } from '@MP/api/surveyApi';

const PatientAddForm: FC<{
  value: PatientAddFormData;
  hideCode?: boolean;
  editable?: boolean;
  requiredPhoneNumber?: boolean;
}> = observer(({ value, editable = true, hideCode, requiredPhoneNumber }) => {
  const [birthError, setBirthError] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState(false);
  const [codeError, setCodeError] = React.useState(false);
  const { mutateAsync: existsCode } = useExistsCode();

  const handleBlurCode = async () => {
    const exists = await existsCode(value.code);
    value.setExistsCode(exists);
    setCodeError(exists);
  };

  const handleBlurBirth = () => {
    if (value.birth) {
      setBirthError(!value.validateBirth());
    }
  };

  const handleBlurPhone = () => {
    if (value.phoneNumber) {
      setPhoneError(!value.validatePhoneNumber());
    }
  };

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    value.setCode(e.target.value);
    setCodeError(false);
  };

  const handleBirthChange = (e: ChangeEvent<HTMLInputElement>) => {
    value.setBirth(e.target.value);
    setBirthError(false);
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    value.setPhoneNumber(e.target.value);
    setPhoneError(false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  return (
    <Stack spacing={'16px'}>
      {!hideCode && (
        <FormControl size="lg" required>
          <FormLabel sx={{ fontSize: 13, color: '#666' }}>환자코드</FormLabel>
          <Input
            disabled={!editable}
            value={value.code}
            onChange={handleCodeChange}
            slotProps={{ input: { maxLength: 20 } }}
            onBlur={handleBlurCode}
          />
          {codeError && (
            <FormHelperText>
              <Typography level="body-xs" color="danger">
                동일한 코드의 환자가 이미 있습니다.
              </Typography>
            </FormHelperText>
          )}
        </FormControl>
      )}
      <FormControl size="lg" required>
        <FormLabel sx={{ fontSize: 13, color: '#666' }}>이름</FormLabel>
        <Input
          disabled={!editable}
          value={value.name}
          slotProps={{ input: { maxLength: 20 } }}
          onChange={(e) => value.setName(e.target.value)}
        />
      </FormControl>
      <FormControl size="lg" required>
        <FormLabel sx={{ fontSize: 13, color: '#666' }}>생년월일</FormLabel>
        <Input
          disabled={!editable}
          error={birthError}
          placeholder="YYYYMMDD"
          value={value.birth}
          onChange={handleBirthChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlurBirth}
          slotProps={{ input: { maxLength: 8, inputMode: 'numeric' } }}
        />
        {birthError && (
          <FormHelperText>
            <Typography level="body-xs" color="danger">
              올바른 생년월일을 입력해주세요.
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
      <FormControl size="lg" required={requiredPhoneNumber}>
        <FormLabel sx={{ fontSize: 13, color: '#666' }}>휴대전화번호</FormLabel>
        <Input
          disabled={!editable}
          error={phoneError}
          value={value.phoneNumber}
          onChange={handlePhoneChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlurPhone}
          slotProps={{ input: { pattern: '[0-9]{3}[0-9]{4}[0-9]{4}', maxLength: 11, inputMode: 'numeric' } }}
        />
        {phoneError && (
          <FormHelperText>
            <Typography level="body-xs" color="danger">
              올바른 휴대전화번호를 입력해주세요.
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
});

export default PatientAddForm;
