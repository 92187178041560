const BirthUtils = {
  isValid: (birth: string) => {
    // 입력값이 8자리 숫자인지 확인
    const dateRegex = /^\d{8}$/;
    if (!dateRegex.test(birth)) {
      return false; // 형식이 올바르지 않음
    }

    // 연도, 월, 일 분리
    const year = parseInt(birth.substring(0, 4), 10);
    const month = parseInt(birth.substring(4, 6), 10);
    const day = parseInt(birth.substring(6, 8), 10);

    // 날짜 유효성 검사
    const date = new Date(year, month - 1, day); // JavaScript에서 월은 0부터 시작
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
  },
};

export default BirthUtils;
