/* tslint:disable */
/* eslint-disable */
/**
 * Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CreateEmployeeDto } from '../model';
// @ts-ignore
import type { DepartmentDto } from '../model';
// @ts-ignore
import type { EmployeeResponseDto } from '../model';
// @ts-ignore
import type { NewPassword } from '../model';
// @ts-ignore
import type { OrganizationSummaryDto } from '../model';
// @ts-ignore
import type { RestErrorResponse } from '../model';
/**
 * PublicControllerApi - axios parameter creator
 * @export
 */
export const PublicControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailExists: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('checkEmailExists', 'email', email)
            const localVarPath = `/public/1.0/employees/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} code 
         * @param {string} clientId 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee: async (id: number, code: string, clientId: string, createEmployeeDto: CreateEmployeeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createEmployee', 'id', id)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('createEmployee', 'code', code)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('createEmployee', 'clientId', clientId)
            // verify required parameter 'createEmployeeDto' is not null or undefined
            assertParamExists('createEmployee', 'createEmployeeDto', createEmployeeDto)
            const localVarPath = `/public/1.0/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartments: async (hpid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hpid' is not null or undefined
            assertParamExists('getDepartments', 'hpid', hpid)
            const localVarPath = `/public/1.0/organizations/{hpid}/departments`
                .replace(`{${"hpid"}}`, encodeURIComponent(String(hpid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsByNameLike: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getOrganizationsByNameLike', 'name', name)
            const localVarPath = `/public/1.0/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {number} id 
         * @param {string} code 
         * @param {NewPassword} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEmployeePassword: async (email: string, id: number, code: string, newPassword: NewPassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('patchEmployeePassword', 'email', email)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchEmployeePassword', 'id', id)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('patchEmployeePassword', 'code', code)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('patchEmployeePassword', 'newPassword', newPassword)
            const localVarPath = `/public/1.0/employees/{email}/password`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsVerificationCode: async (body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sendSmsVerificationCode', 'body', body)
            const localVarPath = `/public/1.0/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} name 
         * @param {string} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmployee: async (email: string, name: string, phoneNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('validateEmployee', 'email', email)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('validateEmployee', 'name', name)
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('validateEmployee', 'phoneNumber', phoneNumber)
            const localVarPath = `/public/1.0/employees/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsCode: async (id: number, code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('verifySmsCode', 'id', id)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('verifySmsCode', 'code', code)
            const localVarPath = `/public/1.0/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicControllerApi - functional programming interface
 * @export
 */
export const PublicControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEmailExists(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkEmailExists(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicControllerApi.checkEmailExists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} code 
         * @param {string} clientId 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmployee(id: number, code: string, clientId: string, createEmployeeDto: CreateEmployeeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmployee(id, code, clientId, createEmployeeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicControllerApi.createEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} hpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartments(hpid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartments(hpid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicControllerApi.getDepartments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationsByNameLike(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationSummaryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationsByNameLike(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicControllerApi.getOrganizationsByNameLike']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {number} id 
         * @param {string} code 
         * @param {NewPassword} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchEmployeePassword(email: string, id: number, code: string, newPassword: NewPassword, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchEmployeePassword(email, id, code, newPassword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicControllerApi.patchEmployeePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSmsVerificationCode(body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSmsVerificationCode(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicControllerApi.sendSmsVerificationCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} name 
         * @param {string} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateEmployee(email: string, name: string, phoneNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateEmployee(email, name, phoneNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicControllerApi.validateEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifySmsCode(id: number, code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifySmsCode(id, code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicControllerApi.verifySmsCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PublicControllerApi - factory interface
 * @export
 */
export const PublicControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailExists(email: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkEmailExists(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} code 
         * @param {string} clientId 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee(id: number, code: string, clientId: string, createEmployeeDto: CreateEmployeeDto, options?: any): AxiosPromise<EmployeeResponseDto> {
            return localVarFp.createEmployee(id, code, clientId, createEmployeeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartments(hpid: string, options?: any): AxiosPromise<Array<DepartmentDto>> {
            return localVarFp.getDepartments(hpid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsByNameLike(name: string, options?: any): AxiosPromise<Array<OrganizationSummaryDto>> {
            return localVarFp.getOrganizationsByNameLike(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {number} id 
         * @param {string} code 
         * @param {NewPassword} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEmployeePassword(email: string, id: number, code: string, newPassword: NewPassword, options?: any): AxiosPromise<void> {
            return localVarFp.patchEmployeePassword(email, id, code, newPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsVerificationCode(body: string, options?: any): AxiosPromise<number> {
            return localVarFp.sendSmsVerificationCode(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} name 
         * @param {string} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmployee(email: string, name: string, phoneNumber: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.validateEmployee(email, name, phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsCode(id: number, code: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.verifySmsCode(id, code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicControllerApi - object-oriented interface
 * @export
 * @class PublicControllerApi
 * @extends {BaseAPI}
 */
export class PublicControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public checkEmailExists(email: string, options?: RawAxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).checkEmailExists(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} code 
     * @param {string} clientId 
     * @param {CreateEmployeeDto} createEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public createEmployee(id: number, code: string, clientId: string, createEmployeeDto: CreateEmployeeDto, options?: RawAxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).createEmployee(id, code, clientId, createEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hpid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getDepartments(hpid: string, options?: RawAxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getDepartments(hpid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getOrganizationsByNameLike(name: string, options?: RawAxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getOrganizationsByNameLike(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {number} id 
     * @param {string} code 
     * @param {NewPassword} newPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public patchEmployeePassword(email: string, id: number, code: string, newPassword: NewPassword, options?: RawAxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).patchEmployeePassword(email, id, code, newPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public sendSmsVerificationCode(body: string, options?: RawAxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).sendSmsVerificationCode(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} name 
     * @param {string} phoneNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public validateEmployee(email: string, name: string, phoneNumber: string, options?: RawAxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).validateEmployee(email, name, phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public verifySmsCode(id: number, code: string, options?: RawAxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).verifySmsCode(id, code, options).then((request) => request(this.axios, this.basePath));
    }
}

