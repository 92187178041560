import { AxiosError } from 'axios';
import React from 'react';
import { QueryCache, QueryClient } from '@tanstack/react-query';

export const useMobileQueryClient = (onAxiosError: (error: AxiosError) => void) => {
  return React.useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: (failureCount: number, error: unknown) => {
            const axiosError = error as AxiosError;
            if (axiosError.response?.status === 401 || axiosError.response?.status === 404) {
              return false;
            } else {
              return failureCount < 2;
            }
          },
          gcTime: 1000 * 60 * 60 * 3,
          staleTime: 1000 * 60 * 60 * 3,
        },
      },
      queryCache: new QueryCache({
        onError: (error: unknown) => {
          const axiosError = error as AxiosError;
          onAxiosError(axiosError);
        },
      }),
    });
  }, [onAxiosError]);
};
