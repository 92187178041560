import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosHeaders } from 'axios';
import {
  Configuration,
  EmployeeControllerApi,
  OrganizationControllerApi,
  OrganizationRequestDto,
  UpdatePasswordDto,
  UploadBusinessLicenseFileRequest,
} from './auth/1.4';
import { CreateEmployeeDto, EmployeeResponseDto, PublicControllerApi } from './auth/public1.0';
import React from 'react';

const authURL = process.env.REACT_APP_AUTH_SERVER_URL;
const config = new Configuration({ basePath: authURL });
const employee = new EmployeeControllerApi(config);
const authPublic = new PublicControllerApi(config);
const organization = new OrganizationControllerApi(config);

const ORGANIZATION_LIST_QUERY_KEY = 'organizations';
const DEPARTMENTS_LIST_QUERY_KEY = 'departments';
const MEMBER_LIST_QUERY_KEY = 'members';
const CURRENT_USER_QUERY_KEY = 'currentUser';
export const useLogin = () => {
  return useMutation<string, AxiosError, { email: string; password: string }>({
    mutationFn: ({ email, password }) =>
      employee
        .createOnlyToken(
          {
            username: email,
            password: password,
            clientId: 'mindd-hospital-web',
            clientSecret: process.env.REACT_APP_AUTH_CLIENT_SECRET || 'xp8mpKKRo0ATgsMYiJRU',
          },
          {
            headers: {
              common: new AxiosHeaders(),
            },
          }
        )
        .then((res) => res.data),
  });
};

export const useSearchOrganizationSummaryByName = (name?: string) => {
  return useQuery({
    queryKey: [ORGANIZATION_LIST_QUERY_KEY, name],
    queryFn: () => authPublic.getOrganizationsByNameLike(name!).then((res) => res.data),
    enabled: !!name && name.trim().length >= 3,
  });
};
export const useSearchOrganizationById = (orgId?: number) => {
  return useQuery({
    queryKey: [ORGANIZATION_LIST_QUERY_KEY, orgId],
    queryFn: () => organization.getOrganizationBy(orgId!).then((res) => res.data),
    enabled: !!orgId,
  });
};

export const useFetchCurrentUser = () => {
  return useQuery({
    queryKey: [CURRENT_USER_QUERY_KEY],
    queryFn: () => employee.getEmployeeMe().then((res) => res.data),
  });
};

export const useFetchOrganizationOfCurrentUser = () => {
  const { data: currentUser } = useFetchCurrentUser();
  return useSearchOrganizationById(currentUser?.orgzId);
};

export const useOrganizationMembersOfCurrentUser = () => {
  const { data: currentUser } = useFetchCurrentUser();
  return useQuery({
    queryKey: [MEMBER_LIST_QUERY_KEY, currentUser?.orgzId],
    queryFn: () => organization.getUsers(currentUser?.orgzId!).then((res) => res.data),
    enabled: !!currentUser && !!currentUser.orgzId,
  });
};

export const useCheckEmailExists = () => {
  return useMutation<boolean, AxiosError, { email: string }>({
    mutationFn: ({ email }) => authPublic.checkEmailExists(email).then((res) => res.data),
  });
};

export const useValidateEmployee = () => {
  return useMutation<boolean, AxiosError, { email: string; name: string; tel: string }>({
    mutationFn: ({ email, name, tel }) => authPublic.validateEmployee(email, name, tel).then((res) => res.data),
  });
};

export const useSendCertNumber = () => {
  return useMutation<number, AxiosError, { phone: string }>({
    mutationFn: ({ phone }) => authPublic.sendSmsVerificationCode(phone).then((res) => res.data),
  });
};

export const useVerifyCertNumber = () => {
  return useMutation<boolean, AxiosError, { certId: number; certNumber: string }>({
    mutationFn: ({ certId, certNumber }) => authPublic.verifySmsCode(certId, certNumber).then((res) => res.data),
  });
};

export const useCreateEmployee = () => {
  return useMutation<
    EmployeeResponseDto,
    AxiosError,
    { certId: number; certNumber: string; employee: CreateEmployeeDto }
  >({
    mutationFn: ({ certId, certNumber, employee }) =>
      authPublic.createEmployee(certId, certNumber, 'mindd-hospital-web', employee).then((res) => res.data),
  });
};

export const useUploadBLFile = () => {
  const client = useQueryClient();
  return useMutation<string, AxiosError, { id: number; blFile: File }>({
    mutationFn: ({ id, blFile }) => {
      const data = new FormData();
      data.append('file', blFile);
      const fileData = data as unknown;
      return organization
        .uploadBusinessLicenseFile(id, fileData as UploadBusinessLicenseFileRequest, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => res.data);
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [ORGANIZATION_LIST_QUERY_KEY] });
    },
  });
};

export const useUpdateOrganization = () => {
  const { data: currentUser } = useFetchCurrentUser();
  const client = useQueryClient();

  return useMutation<any, any, OrganizationRequestDto>({
    mutationFn: (body) => {
      if (currentUser?.orgzId) {
        return organization.updateOrganization(currentUser?.orgzId, body);
      }
      return Promise.resolve();
    },
    onSuccess: () =>
      client.invalidateQueries({
        queryKey: [ORGANIZATION_LIST_QUERY_KEY, currentUser?.orgzId],
      }),
  });
};

export const useDepartments = (hpid?: string) => {
  return useQuery({
    queryKey: [DEPARTMENTS_LIST_QUERY_KEY, hpid],
    queryFn: () => authPublic.getDepartments(hpid!).then((res) => res.data),
    enabled: !!hpid,
    gcTime: 0,
  });
};

export const useUpdatePassword = () => {
  return useMutation<any, AxiosError<{ errorCode: string }>, UpdatePasswordDto>({
    mutationFn: (model) => employee.updatePassword(model),
  });
};

export const useResetPassword = () => {
  return useMutation<any, AxiosError, { email: string; id: number; code: string; password: string }>({
    mutationFn: ({ email, id, code, password }) =>
      authPublic.patchEmployeePassword(email, id, code, { password: password }),
  });
};

export const useApproveMember = () => {
  const client = useQueryClient();
  return useMutation<any, AxiosError, { memberId: number }>({
    mutationFn: ({ memberId }) => employee.updateRoles(memberId, 'U_NORMAL'),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [MEMBER_LIST_QUERY_KEY] });
    },
  });
};

export const useDelegateAdmin = () => {
  const client = useQueryClient();
  return useMutation<any, AxiosError, { memberId: number }>({
    mutationFn: ({ memberId }) => employee.updateRoles(memberId, 'U_ADMIN'),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [MEMBER_LIST_QUERY_KEY] });
      client.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] });
    },
  });
};

export const useIsOrganizationUsingStim = () => {
  const query = useFetchOrganizationOfCurrentUser();
  return {
    ...query,
    data: React.useMemo(() => {
      if (query.data) {
        return (query.data.services! & 512) === 512;
      }
      return undefined;
    }, [query.data]),
  };
};
