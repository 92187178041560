import DashboardMenuIcon from '@MP/icon/DashboardMenuIcon';
import EEGMenuIcon from '@MP/icon/EEGMenuIcon';
import HRVMenuIcon from '@MP/icon/HRVMenuIcon';
import SleepMenuIcon from '@MP/icon/SleepMenuIcon';
import SurveyMenuIcon from '@MP/icon/SurveyMenuIcon';
import { ListItemDecorator, Tab, TabList, Tabs, tabClasses } from '@mui/joy';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { RxDashboard } from 'react-icons/rx';
import { LiaBrainSolid } from 'react-icons/lia';

const MenuTabs = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    // if (location.pathname.startsWith('/patient/dashboard')) {
    //   setActiveTab(0);
    // } else
    if (location.pathname.startsWith('/patient/survey')) {
      setActiveTab(0);
    } else if (location.pathname.toLowerCase().startsWith('/patient/eeg')) {
      setActiveTab(1);
    } else if (location.pathname.toLowerCase().startsWith('/patient/hrv')) {
      setActiveTab(2);
    } else if (location.pathname.toLowerCase().startsWith('/patient/sleep')) {
      setActiveTab(3);
    } else if (location.pathname.toLowerCase().startsWith('/patient/stim')) {
      setActiveTab(4);
    }
  }, [location]);

  const handleChange = (tabIdx: number) => {
    if (!isNaN(tabIdx)) {
      setActiveTab(tabIdx);

      switch (tabIdx) {
        case 1:
          navigate('/patient/eeg/history');
          break;
        case 2:
          navigate('/patient/hrv/history');
          break;
        case 3:
          navigate('/patient/sleep');
          break;
        case 4:
          navigate('/patient/stim');
          break;
        default:
          navigate('/patient/survey');
      }
    }
  };

  return (
    <Tabs value={activeTab} onChange={(_, v) => handleChange(Number(v))} sx={{ background: 'transparent' }}>
      <TabList
        disableUnderline
        sx={{
          height: '55px',
          [`&& .${tabClasses.root}`]: {
            bgcolor: 'transparent',
            fontWeight: 600,
            '&:hover': {
              bgcolor: 'transparent',
            },
            [`&.${tabClasses.selected}`]: {
              color: 'primary.plainColor',
            },
            [`&.${tabClasses.selected}::after`]: {
              left: '16px',
              right: '16px',
            },
          },
        }}>
        {/* <Tab>
          <ListItemDecorator>
            <DashboardMenuIcon primary={activeTab === 0} />
          </ListItemDecorator>
          대시보드
        </Tab> */}
        <Tab value={0}>
          <ListItemDecorator>
            <SurveyMenuIcon primary={activeTab === 0} />
          </ListItemDecorator>
          척도검사
        </Tab>
        <Tab value={3}>
          <ListItemDecorator>
            <SleepMenuIcon primary={activeTab === 3} />
          </ListItemDecorator>
          수면일기
        </Tab>
        <Tab value={1}>
          <ListItemDecorator>
            <EEGMenuIcon primary={activeTab === 1} />
          </ListItemDecorator>
          EEG
        </Tab>
        <Tab value={2}>
          <ListItemDecorator>
            <HRVMenuIcon primary={activeTab === 2} />
          </ListItemDecorator>
          HRV
        </Tab>
        <Tab value={4}>
          <ListItemDecorator>
            <LiaBrainSolid size={24} color={activeTab === 4 ? '#26A7E3' : undefined} />
          </ListItemDecorator>
          STIM+
        </Tab>
      </TabList>
    </Tabs>
  );
};

export default MenuTabs;
