import { FC } from 'react';
import { ic_setting, ic_setting_dark } from '.';

const SettingIcon: FC<{ width?: number; type?: 'white' | 'dark' }> = ({ width = 24, type = 'white' }) => {
  if (type === 'white') {
    return <img src={ic_setting} width={width} alt="" />;
  }

  return <img src={ic_setting_dark} width={width} alt="" />;
};

export default SettingIcon;
