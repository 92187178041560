import { Stack } from '@mui/joy';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router';
import { usePatientPageStore } from './PatientPageStore';
import PatientRightTopContents from './PatientRightTopContents';

const PatientRightContents = observer(() => {
  const store = usePatientPageStore();
  return (
    <Stack sx={{ overflow: 'auto', height: '100%', position: 'relative' }} rowGap={'8px'}>
      <PatientRightTopContents />
      <Stack
        flexGrow={1}
        sx={{
          borderRadius: store.expanded ? 0 : '8px',
          bgcolor: 'common.white',
          overflow: 'auto',
          minWidth: store.expanded ? '1300px' : undefined,
        }}>
        <Outlet />
      </Stack>
    </Stack>
  );
});

export default PatientRightContents;
