import { CircularProgress, Stack } from '@mui/joy';
import React from 'react';

const Loading = () => {
  return (
    <Stack
      position={'absolute'}
      left={0}
      top={0}
      right={0}
      bottom={0}
      justifyContent={'center'}
      alignItems={'center'}
      zIndex={1}
      sx={{
        backgroundColor: 'rgb(206 206 206 / 25%)',
      }}>
      <CircularProgress
        sx={{
          '--CircularProgress-size': '100px',
        }}
      />
    </Stack>
  );
};

export default Loading;
