import WarningIcon from '@MP/icon/WarningIcon';
import { Modal, ModalDialog, Typography } from '@mui/joy';
import { DefaultColorPalette } from '@mui/joy/styles/types';
import React, { FC, ReactNode } from 'react';
import { CiCircleCheck } from 'react-icons/ci';
import { AlertActions, AlertContent, AlertTitle } from '.';
import { RectangleButton } from '../Button';

interface Props {
  open: boolean;
  warning?: boolean;
  title: string;
  loading?: boolean;
  message: ReactNode;
  okText?: string;
  cancelText?: string;
  okColor?: DefaultColorPalette;
  disabledIcon?: boolean;
  icon?: ReactNode;
  onResult: (result: boolean) => void;
}
const ConfirmDialog: FC<Props> = ({
  open,
  warning,
  disabledIcon,
  icon,
  title,
  loading,
  message,
  okText = '확인',
  cancelText = '취소',
  okColor,
  onResult,
}) => {
  const renderMessage = React.useMemo(() => {
    if (typeof message === 'string') {
      return <Typography sx={{ fontSize: 14 }}>{message}</Typography>;
    } else {
      return message;
    }
  }, [message]);

  return (
    <Modal open={open}>
      <ModalDialog>
        <AlertContent>
          {!disabledIcon && icon && icon}
          {!disabledIcon && !icon && warning && <WarningIcon />}
          {!disabledIcon && !icon && !warning && <CiCircleCheck fontSize={66} color="#26A7E3" />}
          <AlertTitle>{title}</AlertTitle>
          {renderMessage}
        </AlertContent>
        <AlertActions>
          <RectangleButton
            color="neutral"
            variant="outlined"
            sx={{ flexGrow: 1, flexBasis: 0 }}
            onClick={() => onResult(false)}>
            {cancelText}
          </RectangleButton>
          <RectangleButton
            color={okColor}
            loading={loading}
            sx={{ flexGrow: 1, flexBasis: 0 }}
            onClick={() => onResult(true)}>
            {okText}
          </RectangleButton>
        </AlertActions>
      </ModalDialog>
    </Modal>
  );
};
export default ConfirmDialog;
