import { Button, ButtonProps } from '@mui/joy';
import { FC } from 'react';

const RoundButton: FC<ButtonProps> = ({ sx, ...remaining }) => {
  return (
    <Button
      {...remaining}
      sx={{
        minWidth: 144,
        height: 48,
        fontSize: 16,
        borderRadius: 100,
        '--joy-palette-primary-solidDisabledBg': '#A3CCDF',
        '--joy-palette-primary-solidDisabledColor': '#FFF',
        ...sx,
      }}
    />
  );
};

const RectangleButton: FC<ButtonProps> = ({ sx, ...remaining }) => {
  return (
    <Button
      {...remaining}
      sx={{
        minWidth: !remaining.fullWidth ? 144 : undefined,
        height: 52,
        fontSize: 16,
        borderRadius: 4,
        '--joy-palette-primary-solidDisabledBg': '#A3CCDF',
        '--joy-palette-primary-solidDisabledColor': '#FFF',
        ...sx,
      }}
    />
  );
};

export { RectangleButton, RoundButton };
