import { ServiceType } from '@MP/api/survey';
import Section from '@MP/component/Section';
import MPCheckbox from '@MP/component/check/MPCheckbox';
import { Divider, Stack, Typography } from '@mui/joy';
import { FC } from 'react';

const AgreementList: FC<{
  value: ServiceType[];
  agreed?: ServiceType[];
  onChange: (checked: ServiceType[]) => void;
}> = ({ value, agreed = [], onChange }) => {
  const handleChange = (type: ServiceType[] | 'all', checked: boolean) => {
    if (type === 'all') {
      onChange(checked ? ['THIRD_PARTY', 'STIM', 'SENSITIVE_INFO'] : []);
    } else if (checked) {
      onChange([...value, ...type]);
    } else {
      onChange(value.filter((v) => !type.includes(v)));
    }
  };

  return (
    <Stack height={'100%'} sx={{ overflow: 'auto' }}>
      <Typography sx={{ fontSize: 13, color: '#666', fontWeight: 500 }}>발송할 동의서 선택</Typography>
      <Section sx={{ px: '16px', mt: '8px', overflow: 'auto', flexGrow: 1 }}>
        <Stack divider={<Divider />}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} height={'70px'}>
            <MPCheckbox
              checked={value.length === 3}
              label="전체 선택"
              primaryBorder
              onChange={(checked) => handleChange('all', checked)}
            />
          </Stack>
          {(!agreed.includes('THIRD_PARTY') || !agreed.includes('SENSITIVE_INFO')) && (
            <AgreementItem
              checked={value.includes('SENSITIVE_INFO')}
              label="개인 정보 제공 동의"
              onChange={(checked) => handleChange(['THIRD_PARTY', 'SENSITIVE_INFO'], checked)}
            />
          )}

          {!agreed.includes('STIM') && (
            <AgreementItem
              checked={value.includes('STIM')}
              label="MINDD STIM+ 비급여 동의"
              onChange={(checked) => handleChange(['STIM'], checked)}
            />
          )}
        </Stack>
      </Section>
    </Stack>
  );
};

const AgreementItem: FC<{
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
}> = ({ checked, label, onChange }) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} height={'70px'}>
      <MPCheckbox checked={checked} label={label} primaryBorder onChange={onChange} />
    </Stack>
  );
};

export default AgreementList;
