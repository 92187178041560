import WebTokenManger from '@MP/util/WebTokenManger';
import { useNavigate } from 'react-router';

const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    window.MinddPlugin.setOrgName(undefined);
    navigate('/login');
    WebTokenManger.clear();
  };

  return { logout: handleLogout };
};

export default useLogout;
