import { useFetchLastPaymentStatus } from '@MP/api/surveyApi';
import ConfirmDialog from '@MP/component/dialog/ConfirmDialog';
import CookieUtils from '@MP/util/CookieUtils';
import { Box, Typography } from '@mui/joy';
import Cookies from 'js-cookie';
import React from 'react';
import { useNavigate } from 'react-router';

const COOKIE_FAIL_ALARM = 'payment_fail_popup_alarm';

const PaymentFailAlarmProvider = () => {
  const [open, setOpen] = React.useState(false);
  const { data: isPaymentSuccess } = useFetchLastPaymentStatus();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isPaymentSuccess === false && !Cookies.get(COOKIE_FAIL_ALARM)) {
      setOpen(true);
    }
  }, [isPaymentSuccess]);

  const handleResult = (result: boolean) => {
    if (!result) {
      CookieUtils.setOneDay(COOKIE_FAIL_ALARM);
    } else {
      navigate('/mindd/payment/settlement');
    }
    setOpen(false);
  };
  return (
    <ConfirmDialog
      open={open}
      warning
      okColor="danger"
      cancelText="오늘 하루 그만 보기"
      title="유료 결제 실패 내역이 있습니다."
      message={
        <Box textAlign={'center'}>
          <Typography level="body-sm">결제 실패한 유료 설문 내역이 확인되었습니다.</Typography>
          <Typography level="body-sm">정산 내역에서 청구서 및 결제 실패 사유를 확인해주세요.</Typography>
        </Box>
      }
      onResult={handleResult}
    />
  );
};

export default PaymentFailAlarmProvider;
