/* tslint:disable */
/* eslint-disable */
/**
 * MINDD Survey API Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AnswerSheetCreateRequest } from '../model';
// @ts-ignore
import type { InsuranceFeeInfoList } from '../model';
// @ts-ignore
import type { SurveySession } from '../model';
// @ts-ignore
import type { SurveySessionCreateRequest } from '../model';
/**
 * SurveyControllerApi - axios parameter creator
 * @export
 */
export const SurveyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 해당 Survey session 에 대해 응답지를 제출한다. 채점 후 결과는 PHR에 저장
         * @param {string} id 
         * @param {AnswerSheetCreateRequest} answerSheetCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answer: async (id: string, answerSheetCreateRequest: AnswerSheetCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('answer', 'id', id)
            // verify required parameter 'answerSheetCreateRequest' is not null or undefined
            assertParamExists('answer', 'answerSheetCreateRequest', answerSheetCreateRequest)
            const localVarPath = `/api/v1/survey-sessions/{id}/answer-sheets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answerSheetCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SurveySessionCreateRequest} surveySessionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSurvey: async (surveySessionCreateRequest: SurveySessionCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySessionCreateRequest' is not null or undefined
            assertParamExists('createSurvey', 'surveySessionCreateRequest', surveySessionCreateRequest)
            const localVarPath = `/api/v1/survey-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveySessionCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sessionId 에 해당하는 SurveySession 삭제 후 해당 환자의 남은 설문 발송 이력 개수를 web socket 으로 전달
         * @summary 설문 발송 이력 삭제
         * @param {Array<string>} ids 
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSurveySessions: async (ids: Array<string>, subjectMetaId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteSurveySessions', 'ids', ids)
            // verify required parameter 'subjectMetaId' is not null or undefined
            assertParamExists('deleteSurveySessions', 'subjectMetaId', subjectMetaId)
            const localVarPath = `/api/v1/survey-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (subjectMetaId !== undefined) {
                localVarQueryParameter['subjectMetaId'] = subjectMetaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subjectMetaId 
         * @param {string} [yearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuranceFeeInfo: async (subjectMetaId: string, yearMonth?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectMetaId' is not null or undefined
            assertParamExists('getInsuranceFeeInfo', 'subjectMetaId', subjectMetaId)
            const localVarPath = `/api/v1/survey-sessions/insurance-fee-info/{subjectMetaId}`
                .replace(`{${"subjectMetaId"}}`, encodeURIComponent(String(subjectMetaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 설문 세션을 조회, 반환 데이터는 설문 응답을 포함.  설문 세션이 존재하지 않을때 404 Not found 오류 반환
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveySession: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSurveySession', 'id', id)
            const localVarPath = `/api/v1/survey-sessions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 해당 Customer 의 모든 session 을 조회
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveySessions: async (subjectMetaId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectMetaId' is not null or undefined
            assertParamExists('getSurveySessions', 'subjectMetaId', subjectMetaId)
            const localVarPath = `/api/v1/survey-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subjectMetaId !== undefined) {
                localVarQueryParameter['subjectMetaId'] = subjectMetaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyUrl: async (sessionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('getSurveyUrl', 'sessionId', sessionId)
            const localVarPath = `/api/v1/survey-sessions/{sessionId}/url`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSurvey: async (sessionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('sendSurvey', 'sessionId', sessionId)
            const localVarPath = `/api/v1/survey-sessions/{sessionId}/send`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyControllerApi - functional programming interface
 * @export
 */
export const SurveyControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SurveyControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 해당 Survey session 에 대해 응답지를 제출한다. 채점 후 결과는 PHR에 저장
         * @param {string} id 
         * @param {AnswerSheetCreateRequest} answerSheetCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answer(id: string, answerSheetCreateRequest: AnswerSheetCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.answer(id, answerSheetCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SurveyControllerApi.answer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SurveySessionCreateRequest} surveySessionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSurvey(surveySessionCreateRequest: SurveySessionCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveySession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSurvey(surveySessionCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SurveyControllerApi.createSurvey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * sessionId 에 해당하는 SurveySession 삭제 후 해당 환자의 남은 설문 발송 이력 개수를 web socket 으로 전달
         * @summary 설문 발송 이력 삭제
         * @param {Array<string>} ids 
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSurveySessions(ids: Array<string>, subjectMetaId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSurveySessions(ids, subjectMetaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SurveyControllerApi.deleteSurveySessions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} subjectMetaId 
         * @param {string} [yearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsuranceFeeInfo(subjectMetaId: string, yearMonth?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceFeeInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsuranceFeeInfo(subjectMetaId, yearMonth, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SurveyControllerApi.getInsuranceFeeInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 설문 세션을 조회, 반환 데이터는 설문 응답을 포함.  설문 세션이 존재하지 않을때 404 Not found 오류 반환
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveySession(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveySession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveySession(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SurveyControllerApi.getSurveySession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 해당 Customer 의 모든 session 을 조회
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveySessions(subjectMetaId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveySession>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveySessions(subjectMetaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SurveyControllerApi.getSurveySessions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveyUrl(sessionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveyUrl(sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SurveyControllerApi.getSurveyUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSurvey(sessionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSurvey(sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SurveyControllerApi.sendSurvey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SurveyControllerApi - factory interface
 * @export
 */
export const SurveyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SurveyControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 해당 Survey session 에 대해 응답지를 제출한다. 채점 후 결과는 PHR에 저장
         * @param {string} id 
         * @param {AnswerSheetCreateRequest} answerSheetCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answer(id: string, answerSheetCreateRequest: AnswerSheetCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.answer(id, answerSheetCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SurveySessionCreateRequest} surveySessionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSurvey(surveySessionCreateRequest: SurveySessionCreateRequest, options?: any): AxiosPromise<SurveySession> {
            return localVarFp.createSurvey(surveySessionCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * sessionId 에 해당하는 SurveySession 삭제 후 해당 환자의 남은 설문 발송 이력 개수를 web socket 으로 전달
         * @summary 설문 발송 이력 삭제
         * @param {Array<string>} ids 
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSurveySessions(ids: Array<string>, subjectMetaId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSurveySessions(ids, subjectMetaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subjectMetaId 
         * @param {string} [yearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuranceFeeInfo(subjectMetaId: string, yearMonth?: string, options?: any): AxiosPromise<InsuranceFeeInfoList> {
            return localVarFp.getInsuranceFeeInfo(subjectMetaId, yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 설문 세션을 조회, 반환 데이터는 설문 응답을 포함.  설문 세션이 존재하지 않을때 404 Not found 오류 반환
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveySession(id: string, options?: any): AxiosPromise<SurveySession> {
            return localVarFp.getSurveySession(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 해당 Customer 의 모든 session 을 조회
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveySessions(subjectMetaId: string, options?: any): AxiosPromise<Array<SurveySession>> {
            return localVarFp.getSurveySessions(subjectMetaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyUrl(sessionId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getSurveyUrl(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSurvey(sessionId: string, options?: any): AxiosPromise<string> {
            return localVarFp.sendSurvey(sessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SurveyControllerApi - object-oriented interface
 * @export
 * @class SurveyControllerApi
 * @extends {BaseAPI}
 */
export class SurveyControllerApi extends BaseAPI {
    /**
     * 
     * @summary 해당 Survey session 에 대해 응답지를 제출한다. 채점 후 결과는 PHR에 저장
     * @param {string} id 
     * @param {AnswerSheetCreateRequest} answerSheetCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerApi
     */
    public answer(id: string, answerSheetCreateRequest: AnswerSheetCreateRequest, options?: RawAxiosRequestConfig) {
        return SurveyControllerApiFp(this.configuration).answer(id, answerSheetCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveySessionCreateRequest} surveySessionCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerApi
     */
    public createSurvey(surveySessionCreateRequest: SurveySessionCreateRequest, options?: RawAxiosRequestConfig) {
        return SurveyControllerApiFp(this.configuration).createSurvey(surveySessionCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * sessionId 에 해당하는 SurveySession 삭제 후 해당 환자의 남은 설문 발송 이력 개수를 web socket 으로 전달
     * @summary 설문 발송 이력 삭제
     * @param {Array<string>} ids 
     * @param {string} subjectMetaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerApi
     */
    public deleteSurveySessions(ids: Array<string>, subjectMetaId: string, options?: RawAxiosRequestConfig) {
        return SurveyControllerApiFp(this.configuration).deleteSurveySessions(ids, subjectMetaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subjectMetaId 
     * @param {string} [yearMonth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerApi
     */
    public getInsuranceFeeInfo(subjectMetaId: string, yearMonth?: string, options?: RawAxiosRequestConfig) {
        return SurveyControllerApiFp(this.configuration).getInsuranceFeeInfo(subjectMetaId, yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 설문 세션을 조회, 반환 데이터는 설문 응답을 포함.  설문 세션이 존재하지 않을때 404 Not found 오류 반환
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerApi
     */
    public getSurveySession(id: string, options?: RawAxiosRequestConfig) {
        return SurveyControllerApiFp(this.configuration).getSurveySession(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 해당 Customer 의 모든 session 을 조회
     * @param {string} subjectMetaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerApi
     */
    public getSurveySessions(subjectMetaId: string, options?: RawAxiosRequestConfig) {
        return SurveyControllerApiFp(this.configuration).getSurveySessions(subjectMetaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerApi
     */
    public getSurveyUrl(sessionId: string, options?: RawAxiosRequestConfig) {
        return SurveyControllerApiFp(this.configuration).getSurveyUrl(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyControllerApi
     */
    public sendSurvey(sessionId: string, options?: RawAxiosRequestConfig) {
        return SurveyControllerApiFp(this.configuration).sendSurvey(sessionId, options).then((request) => request(this.axios, this.basePath));
    }
}

