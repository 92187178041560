import React, { ChangeEvent } from 'react';
import { PasswordInputProps } from './PasswordInput';

function isValidPassword(password: string): boolean {
  const regex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?/~`"'\\|=])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?/~`"'\\|=]{8,16}$/;

  return regex.test(password);
}

const usePasswordInputProps = (
  currPw: string = '',
  newPw: string = '',
  confirmPw: string = '',
  onPwChange: (pw: string) => void,
  onNewPwChange: (pw: string, confirm: string) => void
) => {
  const [newPwError, setNewPwError] = React.useState<boolean>(false);
  const [confirmPwError, setConfirmPwError] = React.useState<boolean>(false);
  const currPwRef = React.useRef<HTMLInputElement>(null);

  const handleCurrPwChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onPwChange(e.target.value);
    },
    [onPwChange]
  );

  const checkConfirmPw = React.useCallback(() => {
    if (confirmPw) {
      setConfirmPwError(newPw !== confirmPw);
    }
  }, [newPw, confirmPw]);

  const checkNewPw = React.useCallback(() => {
    if (confirmPw) {
      setConfirmPwError(newPw !== confirmPw);
    }

    setNewPwError(!isValidPassword(newPw));
  }, [newPw, confirmPw]);

  const handleNewPwChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onNewPwChange(e.target.value, confirmPw);
    },
    [onNewPwChange, confirmPw]
  );

  const handleConfirmPwChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onNewPwChange(newPw, e.target.value);
      setConfirmPwError(false);
    },
    [onNewPwChange, newPw]
  );

  const currPwProps = React.useMemo(() => {
    return {
      size: 'lg',
      fullWidth: true,
      required: true,
      label: '현재 비밀번호',
      placeholder: '현재 비밀번호 입력',
      value: currPw,
      onChange: handleCurrPwChange,
      slotProps: {
        input: { autoComplete: 'new-password', ref: currPwRef, type: 'password', maxLength: 16, minLength: 8 },
      },
    } as PasswordInputProps;
  }, [currPw, currPwRef, handleCurrPwChange]);

  const newPwProps = React.useMemo(() => {
    return {
      size: 'lg',
      fullWidth: true,
      required: true,
      error: !!newPwError,
      label: '새 비밀번호',
      helperText: '* 영문, 숫자, 특수문자를 포함한 8~16자로 입력하세요.',
      placeholder: '새 비밀번호 입력',
      value: newPw,
      onChange: handleNewPwChange,
      onBlur: checkNewPw,
      slotProps: { input: { autoComplete: 'new-password', type: 'password', maxLength: 16, minLength: 8 } },
    } as PasswordInputProps;
  }, [newPw, handleNewPwChange, checkNewPw, newPwError]);

  const confirmPwProps = React.useMemo(() => {
    return {
      size: 'lg',
      fullWidth: true,
      required: true,
      error: !!confirmPwError,
      label: '새 비밀번호 확인',
      helperText: confirmPwError ? '비밀번호가 일치하지 않습니다' : '',
      placeholder: '새 비밀번호 재입력',
      value: confirmPw,
      onChange: handleConfirmPwChange,
      onBlur: checkConfirmPw,
      slotProps: {
        input: { autoComplete: 'new-password', type: 'password', maxLength: 16, minLength: 8 },
      },
    } as PasswordInputProps;
  }, [confirmPw, confirmPwError, handleConfirmPwChange, checkConfirmPw]);

  const isValid = currPw.length >= 8 && newPw.length >= 8 && confirmPw.length >= 8 && !confirmPwError && !newPwError;

  return {
    currPwProps,
    newPwProps,
    confirmPwProps,
    currPwRef,
    isValid,
  };
};

export default usePasswordInputProps;
