import { Duration, ZonedDateTime } from '@js-joda/core';
import { PaymentCard } from '@MP/api/survey';
import DateUtils from '@MP/util/DateUtils';

export enum CardStatus {
  NORMAL,
  EXPIRED,
  EXPIRING_SOON,
}
const CardStatusUtils = {
  toStatus: (info: PaymentCard) => {
    const remainingDay = Duration.between(ZonedDateTime.now(), DateUtils.parse(info.cardExpireAt)).toDays();
    if (remainingDay < 0) {
      return CardStatus.EXPIRED;
    } else if (remainingDay < 30) {
      return CardStatus.EXPIRING_SOON;
    } else {
      return CardStatus.NORMAL;
    }
  },
  toRemainingDay: (info: PaymentCard) => {
    return Duration.between(ZonedDateTime.now(), DateUtils.parse(info.cardExpireAt)).toDays();
  },

  isExpired: (info: PaymentCard) => {
    return CardStatusUtils.toStatus(info) === CardStatus.EXPIRED;
  },
};
export default CardStatusUtils;
