/* tslint:disable */
/* eslint-disable */
/**
 * Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EmployeeResponseDto
 */
export interface EmployeeResponseDto {
    /**
     * 
     * @type {number}
     * @memberof EmployeeResponseDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeResponseDto
     */
    'orgzId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponseDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponseDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponseDto
     */
    'tel'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponseDto
     */
    'department'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeResponseDto
     */
    'isSubscribeMarketingEvent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponseDto
     */
    'role'?: EmployeeResponseDtoRoleEnum;
}

export const EmployeeResponseDtoRoleEnum = {
    UNormal: 'U_NORMAL',
    UAdmin: 'U_ADMIN',
    USubject: 'U_SUBJECT',
    YAdmin: 'Y_ADMIN',
    YManager: 'Y_MANAGER',
    YOperator: 'Y_OPERATOR',
    YDistributor: 'Y_DISTRIBUTOR',
    PAdmin: 'P_ADMIN',
    PNormal: 'P_NORMAL'
} as const;

export type EmployeeResponseDtoRoleEnum = typeof EmployeeResponseDtoRoleEnum[keyof typeof EmployeeResponseDtoRoleEnum];


