import { EmployeeResponseDto } from '@MP/api/auth/1.4';
import { SurveySocket, SurveySocketClient } from '@MP/api/socket/surveySocket';
import WebTokenManger from '@MP/util/WebTokenManger';
import React from 'react';

const usePatientSocket = (currentUser?: EmployeeResponseDto) => {
  const [socketClient, setSocketClient] = React.useState<SurveySocketClient | undefined>();
  const [updatedUser, setUpdatedUser] = React.useState<
    { subjectMetaId: string; editInfo?: boolean; deleted?: boolean } | undefined
  >();
  React.useEffect(() => {
    if (currentUser && WebTokenManger.hasToken()) {
      setSocketClient(SurveySocket.connect(WebTokenManger.getToken()!));
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (socketClient) {
      socketClient.onConnect(() => {
        socketClient.subscribeOnSession((subjectMetaId: string) => {
          setUpdatedUser({ subjectMetaId: subjectMetaId });
        });
        socketClient.subscribeOnAgreement((subjectMetaId: string) => {
          setUpdatedUser({ subjectMetaId: subjectMetaId });
        });
        socketClient.subscribeOnMetadata((subjectMetaId: string) => {
          setUpdatedUser({ subjectMetaId: subjectMetaId, editInfo: true });
        });
        socketClient.subscribeOnDeleteSession((subjectMetaId: string) => {
          setUpdatedUser({ subjectMetaId: subjectMetaId, editInfo: true });
        });
        socketClient.subscribeOnDeleteUser((subjectMetaId: string) => {
          setUpdatedUser({ subjectMetaId: subjectMetaId, deleted: true });
        });
      });
      return () => {
        socketClient.deactivate();
      };
    }
  }, [socketClient]);

  return React.useMemo(() => {
    return {
      subjectMetaId: updatedUser?.subjectMetaId,
      editInfo: updatedUser?.editInfo,
      deleted: updatedUser?.deleted,
    };
  }, [updatedUser]);
};

export default usePatientSocket;
