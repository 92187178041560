import { Checkbox } from '@mui/joy';
import { FC } from 'react';
import ic_check_default from './ic_check_default.png';
import ic_check_select_all from './ic_check_select_all.png';
import ic_check_select_some from './ic_check_select_some.png';
import ic_check_select_disabled from './ic_check_select_disabled.png';
import ic_check_default_primary from './ic_check_default_primary.png';

interface Props {
  label?: string;
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  primaryBorder?: boolean;
}

const MPCheckbox: FC<Props> = ({ label, primaryBorder, checked, indeterminate, disabled, onChange }) => {
  return (
    <Checkbox
      label={label}
      disabled={disabled}
      checked={checked}
      indeterminate={indeterminate}
      onChange={() => onChange?.(!checked)}
      checkedIcon={
        disabled ? (
          <img src={ic_check_select_disabled} alt="" width={24} />
        ) : (
          <img src={ic_check_select_all} alt="" width={24} />
        )
      }
      uncheckedIcon={
        primaryBorder ? (
          <img src={ic_check_default_primary} alt="" width={24} />
        ) : (
          <img src={ic_check_default} alt="" width={24} />
        )
      }
      indeterminateIcon={<img src={ic_check_select_some} alt="" width={24} />}
    />
  );
};

export default MPCheckbox;
