import { BDF_ID_FULL_TEXT, BDF_RECORD_DATA_BYTE, EDF_RECORD_DATA_BYTE } from './const';

export interface FileHeader {
  id: string;
  subjectId: string;
  recordId: string;
  startDate: Date;
  headerLength: number; // FileHeader + ChannelHeader[] 길이의 합
  versionFormat: string;
  dataRecordCnt: number;
  intervalSec: number;
  channelCnt: number;
}
export interface IChannelHeader {
  label: string;
  transducer: string;
  physicalDimensions: string;
  physicalMin: number;
  physicalMax: number;
  digitalMin: number;
  digitalMax: number;
  preFiltering: string;
  samples: number;
  reserved: string;
}

export class ChannelHeader implements IChannelHeader {
  label: string = '';
  transducer: string = '';
  physicalDimensions: string = '';
  physicalMin: number = 0;
  physicalMax: number = 0;
  digitalMin: number = 0; // gain 이 곱해져 있다.
  digitalMax: number = 0; // gain 이 곱해져 있다.
  preFiltering: string = '';
  samples: number = 0;
  reserved: string = '';

  get scaleFactor() {
    return (this.physicalMax - this.physicalMin) / (this.digitalMax - this.digitalMin);
  }
}

type ChannelHeaderName = keyof IChannelHeader;

export interface ChannelHeaderField {
  name: ChannelHeaderName;
  length: number;
}

export class SignalFile {
  header: FileHeader;
  channelHeaders: ChannelHeader[];
  signalRecords: number[][] = [];
  totalSecond: number = 0;

  constructor(header: FileHeader, channelHeaders: ChannelHeader[]) {
    this.header = header;
    this.channelHeaders = channelHeaders;
  }

  setSignalRecords(signalRecords: number[][]) {
    this.signalRecords = signalRecords;
    if (signalRecords[0]) {
      this.totalSecond = Math.ceil(signalRecords[0].length / 500);
    } else {
      this.totalSecond = 0;
    }
  }

  isBdf() {
    return this.header.id === BDF_ID_FULL_TEXT;
  }
}

export class SignalDataView {
  dataView: DataView;
  constructor(file: ArrayBuffer) {
    this.dataView = new DataView(file);
  }
  getInt24LE(offset: number) {
    const byte0 = this.dataView.getUint8(offset);
    const byte1 = this.dataView.getUint8(offset + 1);
    const byte2 = this.dataView.getUint8(offset + 2);

    const val = byte0 + byte1 * 2 ** 8 + byte2 * 2 ** 16;
    return val | ((val & (2 ** 23)) * 0x1fe);
  }
  getUint8(byteOffset: number) {
    return this.dataView.getUint8(byteOffset);
  }

  getInt16(byteOffset: number, littleEndian?: boolean) {
    return this.dataView.getInt16(byteOffset, littleEndian);
  }

  getUint32(byteOffset: number, littleEndian?: boolean) {
    return this.dataView.getUint32(byteOffset, littleEndian);
  }

  getFloat32(byteOffset: number, littleEndian?: boolean) {
    return this.dataView.getFloat32(byteOffset, littleEndian);
  }
}

export class SignalRecordData {
  data: number[][] = [];
  isBdf: boolean;

  constructor(data: number[][], isBdf: boolean) {
    this.data = data;
    this.isBdf = isBdf;
  }

  get length() {
    return this.data.length;
  }

  get bytes() {
    const dataByte = this.isBdf ? BDF_RECORD_DATA_BYTE : EDF_RECORD_DATA_BYTE;
    return this.data.reduce((tot, curr) => tot + curr.length, 0) * dataByte;
  }

  get(channelIdx: number) {
    return this.data[channelIdx];
  }
}

//-------------------------------------
