import { FC } from 'react';
import { ic_eeg, ic_eeg_active } from '.';

const EEGMenuIcon: FC<{ width?: number; primary?: boolean }> = ({ width = 24, primary }) => {
  if (primary) {
    return <img src={ic_eeg_active} width={width} alt="" />;
  }
  return <img src={ic_eeg} width={width} alt="" />;
};

export default EEGMenuIcon;
