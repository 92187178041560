import { RectangleButton } from '@MP/component/Button';
import { Box, Checkbox, DialogContent, Modal, ModalDialog, Stack } from '@mui/joy';
import React, { FC } from 'react';
import { Notice } from './api/typs';

const NoticeDialog: FC<{
  notice: Notice;
  onClose: (optionChecked: boolean) => void;
}> = ({ notice, onClose }) => {
  const [open, setOpen] = React.useState(true);
  const [optionChecked, setOptionChecked] = React.useState(false);

  const optionLabel = React.useMemo(() => {
    if (notice.enableCheckboxToDisableToday) {
      return '오늘 하루 보지 않기';
    } else {
      return '더 이상 보지 않기';
    }
  }, [notice]);

  const handleLink = (url: string) => {
    window.open(url, '_blank');
  };

  const handleClose = () => {
    setOpen(false);
    onClose(optionChecked);
  };

  return (
    <Modal open={open}>
      <ModalDialog>
        <DialogContent>
          <Box position={'relative'}>
            <img src={notice.image} alt="" />
            {notice.link.map((l, idx) => {
              return (
                <Box
                  key={idx}
                  position={'absolute'}
                  left={l.x}
                  top={l.y}
                  width={l.width}
                  height={l.height}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleLink(l.url)}
                />
              );
            })}
          </Box>
          <Stack px={2} py={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Checkbox
              label={optionLabel}
              checked={optionChecked}
              onChange={(e) => {
                setOptionChecked(e.target.checked);
              }}
            />
            <RectangleButton variant="solid" onClick={handleClose}>
              닫기
            </RectangleButton>
          </Stack>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default NoticeDialog;
