import { SleepDiary, SleepSession } from '@MP/api/survey';
import moment from 'moment';

export enum ResultIndex {
  /** 전체 수면 시간*/
  TST = 0,
  /** 침대에서 보낸 시간*/
  TIB = 1,
  /** 수면 효율성*/
  SE = 2,
  /** 수면 지연*/
  SOL = 3,
  /** 중간에 깬 횟수*/
  WASC = 4,
  /** 중간에 깬 시간*/
  WASO = 5,
  /** 중간에 깬 시간 중 잠자리 밖에서 보낸 시간*/
  TOB = 6,
  /**  전날 낮잠 횟수*/
  NF = 7,
  /** 약물 복용 여부*/
  MU = 8,
  /** 수면 만족도*/
  SS = 9,
  /**수면 문제로 인한 지장*/
  SD = 10,
  /** 일어나려고 했던 시각*/
  IWT = 11,
  /**잠자리에 누운 시각 */
  BT = 12,
  /**자려고 눈 감은 시각 */
  AST = 13,
  /** 마지막으로 잠이 깬 시각*/
  FWT = 14,
  /**마지막으로 잠자리에서 나온 시각 */
  OBT = 15,
}

const SleepUtils = {
  dateFormat: (session: SleepSession) => {
    if (SleepUtils.isDone(session)) {
      return `${moment(session.startDate).format('YYYY년 MM월 DD일 HH:mm:ss 시작')} /
          ${moment(session.endDate).format('YYYY년 MM월 DD일 HH:mm:ss 종료')}`;
    } else {
      return `${moment(session.startDate).format('YYYY년 MM월 DD일 HH:mm:ss 시작')} /
                  ${moment(session.endDate).format('YYYY년 MM월 DD일 HH:mm:ss 종료 예정')}`;
    }
  },
  isDone: (session: SleepSession) => {
    return moment().diff(moment(session.endDate)) > 0;
  },
  isReady: (session: SleepSession) => {
    return moment().diff(moment(session.startDate)) < 0;
  },
  isInprogress: (session: SleepSession) => {
    return !SleepUtils.isDone(session) && !SleepUtils.isReady(session);
  },
  isCanceled: (session: SleepSession) => {
    return !!session.canceledAt;
  },
  totalValue: (diaries: SleepDiary[] = [], index: ResultIndex) => {
    return diaries.reduce((acc, curr) => acc + (curr.answerSheets[0]?.results?.[index].score || 0), 0);
  },

  formatTime: (minutes?: number, useHourFormat: boolean = true) => {
    if (minutes === undefined || isNaN(minutes)) {
      return '';
    }

    if (minutes % 60 === 0) {
      return useHourFormat
        ? moment.utc(minutes * 60 * 1000).format('H시간')
        : moment.utc(minutes * 60 * 1000).format('H시');
    } else if (minutes > 60) {
      return useHourFormat
        ? moment.utc(minutes * 60 * 1000).format('H시간 m분')
        : moment.utc(minutes * 60 * 1000).format('H시 m분');
    }

    return useHourFormat ? `${minutes}분` : moment.utc(minutes * 60 * 1000).format('H시 m분');
  },
};

export default SleepUtils;
