import { ICloseEvent, IMessageEvent, w3cwebsocket as W3CWebSocket } from 'websocket';

enum MessageType {
  SURVEY_SESSION_CREATED = 'SURVEY_SESSION_CREATED',
  SURVEY_SESSION_UPDATED = 'SURVEY_SESSION_UPDATED',
  SUBJECT_METADATA_UPDATED = 'SUBJECT_METADATA_UPDATED',
  SURVEY_SESSION_DELETED = 'SURVEY_SESSION_DELETED',
  SUBJECT_METADATA_DELETED = 'SUBJECT_METADATA_DELETED',
  USER_AGREED = 'USER_AGREED',
}

interface SurveyMessage {
  type: MessageType;
}

export interface SurveySocketClient {
  connected: boolean;
  subscribeOnSession: (callback: (subjectMetaId: string) => void) => void;
  subscribeOnAgreement: (callback: (subjectMetaId: string) => void) => void;
  subscribeOnMetadata: (callback: (subjectMetaId: string) => void) => void;
  subscribeOnDeleteSession: (callback: (subjectMetaId: string) => void) => void;
  subscribeOnDeleteUser: (callback: (subjectMetaId: string) => void) => void;
  onclose: (callback: () => void) => void;
  onConnect: (callback: () => void) => void;
  deactivate: () => void;
}

interface MessageListener {
  condition: (surveyMessage: SurveyMessage) => boolean;
  callback: (message: IMessageEvent) => void;
}
const NORMAL_CLOSURE_CODE = 1000;
class SurveySocketImpl implements SurveySocketClient {
  private _client: W3CWebSocket;
  connected: boolean = false;
  private hbIntervalId;
  listeners: MessageListener[] = [];

  constructor(client: W3CWebSocket) {
    this._client = client;
    this.hbIntervalId = this.sendHeartBeat();
    this.init();
  }

  private sendHeartBeat() {
    return setInterval(() => {
      if (this._client.readyState === W3CWebSocket.OPEN) {
        this._client.send('');
      }
    }, 60000);
  }

  private init() {
    this._client.onclose = (e: ICloseEvent) => {
      if (e.code !== NORMAL_CLOSURE_CODE) {
        setTimeout(() => {
          this._client = new W3CWebSocket(this._client.url);
          this.init();
        }, 5000);
      }
    };
    this._client.onerror = (error: Error) => {
      console.log(error);
    };
    this._client.onmessage = (message: IMessageEvent) => {
      const surveyMessage: SurveyMessage = JSON.parse(message.data as string);

      this.listeners.forEach((listener) => {
        if (listener.condition(surveyMessage)) {
          listener.callback(message);
        }
      });
    };
  }

  subscribeOnSession(callback: (subjectMetaId: string) => void) {
    if (this.connected) {
      this.listeners.push({
        condition: (surveyMessage: SurveyMessage) =>
          surveyMessage.type === MessageType.SURVEY_SESSION_CREATED ||
          surveyMessage.type === MessageType.SURVEY_SESSION_UPDATED,
        callback: (message: IMessageEvent) => {
          const { subjectMetaId } = JSON.parse(message.data as string);
          callback(subjectMetaId);
        },
      });
    }
  }

  subscribeOnAgreement(callback: (subjectMetaId: string) => void) {
    if (this.connected) {
      this.listeners.push({
        condition: (surveyMessage: SurveyMessage) => surveyMessage.type === MessageType.USER_AGREED,
        callback: (message: IMessageEvent) => {
          const { subjectMetaId } = JSON.parse(message.data as string);
          callback(subjectMetaId);
        },
      });
    }
  }

  subscribeOnMetadata(callback: (subjectMetaId: string) => void) {
    if (this.connected) {
      this.listeners.push({
        condition: (surveyMessage: SurveyMessage) => surveyMessage.type === MessageType.SUBJECT_METADATA_UPDATED,
        callback: (message: IMessageEvent) => {
          const { subjectMetaId } = JSON.parse(message.data as string);
          callback(subjectMetaId);
        },
      });
    }
  }
  subscribeOnDeleteSession(callback: (subjectMetaId: string) => void) {
    if (this.connected) {
      this.listeners.push({
        condition: (surveyMessage: SurveyMessage) => surveyMessage.type === MessageType.SURVEY_SESSION_DELETED,
        callback: (message: IMessageEvent) => {
          const { subjectMetaId } = JSON.parse(message.data as string);
          callback(subjectMetaId);
        },
      });
    }
  }
  subscribeOnDeleteUser(callback: (subjectMetaId: string) => void) {
    if (this.connected) {
      this.listeners.push({
        condition: (surveyMessage: SurveyMessage) => surveyMessage.type === MessageType.SUBJECT_METADATA_DELETED,
        callback: (message: IMessageEvent) => {
          const { subjectMetaId } = JSON.parse(message.data as string);
          callback(subjectMetaId);
        },
      });
    }
  }
  onclose(callback: () => void) {
    this._client.onclose = (event: ICloseEvent) => {
      console.log(event);
      this.connected = false;
      callback();
    };
  }
  onConnect(callback: () => void) {
    if (this.connected) {
      callback();
    }
    this._client.onopen = () => {
      console.log('Connected Socket');
      this.connected = true;
      callback();
    };
  }

  deactivate() {
    this._client.close();
    clearInterval(this.hbIntervalId);
  }
}

export const SurveySocket = {
  connect: (token: string): SurveySocketClient => {
    const url = `${process.env.REACT_APP_SURVEY_SERVER_URL || 'localhost:8080'}`.replace('https', 'wss');
    const surveySocket = new SurveySocketImpl(new W3CWebSocket(`${url}/ws?Authorization=Bearer ${token}`));
    return surveySocket;
  },
};
