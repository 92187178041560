import { Notice } from '@MP/api/typs';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';

const NOTICE_STORAGE_KEY_PREFIX = 'MINDD_Notice_';

export default class GlobalUIStore {
  notice: Notice[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setNotice(notice: Notice[]) {
    this.notice = notice;
    this.cleanupNoticeInStorage();
  }

  getNotice(isLogin: boolean, paidSurvey?: boolean, stim?: boolean, telecare?: boolean) {
    return this.notice
      .filter((v) => (isLogin ? true : !v.needLogin))
      .filter((v) => {
        if (isLogin) {
          if (v.publishCondition === 'paid_survey') {
            return paidSurvey;
          } else if (v.publishCondition === 'stim') {
            return stim;
          } else if (v.publishCondition === 'telecare') {
            return telecare;
          }
        }

        return true;
      })
      .filter((v) => !this.isHideNotice(v));
  }

  isHideNotice(notice: Notice) {
    const today = moment();
    const start = moment(notice.startDate);

    if (today.diff(start) < 0) {
      return true;
    } else if (notice.endDate && today.diff(moment(notice.endDate)) > 0) {
      return true;
    } else if (!!sessionStorage.getItem(`${NOTICE_STORAGE_KEY_PREFIX}${notice.timestampId}`)) {
      return true;
    }

    const date = localStorage.getItem(`${NOTICE_STORAGE_KEY_PREFIX}${notice.timestampId}`);
    return date && moment().diff(moment(date)) <= 0;
  }

  setHideNotice(notice: Notice, optionChecked: boolean) {
    if (optionChecked) {
      if (notice.enableCheckboxToDisableForever) {
        localStorage.setItem(`${NOTICE_STORAGE_KEY_PREFIX}${notice.timestampId}`, moment().add(100, 'year').format());
      } else {
        localStorage.setItem(`${NOTICE_STORAGE_KEY_PREFIX}${notice.timestampId}`, moment().add(1, 'day').format());
      }
    } else {
      sessionStorage.setItem(`${NOTICE_STORAGE_KEY_PREFIX}${notice.timestampId}`, 'true');
    }
  }

  cleanupNoticeInStorage() {
    if (this.notice.length > 0) {
      const timestampIds = this.notice.map((n) => n.timestampId);
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith(NOTICE_STORAGE_KEY_PREFIX)) {
          const timestampId = key.replace(NOTICE_STORAGE_KEY_PREFIX, '');
          if (!timestampIds.includes(Number(timestampId))) {
            localStorage.removeItem(key);
          }
        }
      }
    }
  }
}

const store = new GlobalUIStore();

export const useGlobalStore = () => store;
