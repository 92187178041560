/* tslint:disable */
/* eslint-disable */
/**
 * MINDD Survey API Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { PaidRecordStatus } from '../model';
// @ts-ignore
import type { PaidSessionRecord } from '../model';
/**
 * PaidSessionRecordControllerApi - axios parameter creator
 * @export
 */
export const PaidSessionRecordControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 기간별 이용 내역 전달
         * @param {string} from 
         * @param {string} to 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredByRange: async (from: string, to: string, statusList?: Array<PaidRecordStatus>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('filteredByRange', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('filteredByRange', 'to', to)
            const localVarPath = `/api/v1/paid-sessions/range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (statusList) {
                localVarQueryParameter['statusList'] = statusList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 월별 이용 내역 전달
         * @param {string} yearMonth 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyRecords1: async (yearMonth: string, statusList?: Array<PaidRecordStatus>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('getMonthlyRecords1', 'yearMonth', yearMonth)
            const localVarPath = `/api/v1/paid-sessions/monthly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }

            if (statusList) {
                localVarQueryParameter['statusList'] = statusList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 청구서별 이용 내역 전달
         * @param {string} invoiceId 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordByInvoice1: async (invoiceId: string, statusList?: Array<PaidRecordStatus>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getRecordByInvoice1', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/paid-sessions/invoice/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (statusList) {
                localVarQueryParameter['statusList'] = statusList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaidSessionRecordControllerApi - functional programming interface
 * @export
 */
export const PaidSessionRecordControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaidSessionRecordControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 기간별 이용 내역 전달
         * @param {string} from 
         * @param {string} to 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filteredByRange(from: string, to: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<PaidSessionRecord>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filteredByRange(from, to, statusList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaidSessionRecordControllerApi.filteredByRange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 월별 이용 내역 전달
         * @param {string} yearMonth 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyRecords1(yearMonth: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<PaidSessionRecord>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyRecords1(yearMonth, statusList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaidSessionRecordControllerApi.getMonthlyRecords1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 청구서별 이용 내역 전달
         * @param {string} invoiceId 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordByInvoice1(invoiceId: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<PaidSessionRecord>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordByInvoice1(invoiceId, statusList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaidSessionRecordControllerApi.getRecordByInvoice1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaidSessionRecordControllerApi - factory interface
 * @export
 */
export const PaidSessionRecordControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaidSessionRecordControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 기간별 이용 내역 전달
         * @param {string} from 
         * @param {string} to 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredByRange(from: string, to: string, statusList?: Array<PaidRecordStatus>, options?: any): AxiosPromise<{ [key: string]: Array<PaidSessionRecord>; }> {
            return localVarFp.filteredByRange(from, to, statusList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 월별 이용 내역 전달
         * @param {string} yearMonth 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyRecords1(yearMonth: string, statusList?: Array<PaidRecordStatus>, options?: any): AxiosPromise<{ [key: string]: Array<PaidSessionRecord>; }> {
            return localVarFp.getMonthlyRecords1(yearMonth, statusList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 청구서별 이용 내역 전달
         * @param {string} invoiceId 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordByInvoice1(invoiceId: string, statusList?: Array<PaidRecordStatus>, options?: any): AxiosPromise<{ [key: string]: Array<PaidSessionRecord>; }> {
            return localVarFp.getRecordByInvoice1(invoiceId, statusList, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaidSessionRecordControllerApi - object-oriented interface
 * @export
 * @class PaidSessionRecordControllerApi
 * @extends {BaseAPI}
 */
export class PaidSessionRecordControllerApi extends BaseAPI {
    /**
     * 
     * @summary 기간별 이용 내역 전달
     * @param {string} from 
     * @param {string} to 
     * @param {Array<PaidRecordStatus>} [statusList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaidSessionRecordControllerApi
     */
    public filteredByRange(from: string, to: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig) {
        return PaidSessionRecordControllerApiFp(this.configuration).filteredByRange(from, to, statusList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 월별 이용 내역 전달
     * @param {string} yearMonth 
     * @param {Array<PaidRecordStatus>} [statusList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaidSessionRecordControllerApi
     */
    public getMonthlyRecords1(yearMonth: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig) {
        return PaidSessionRecordControllerApiFp(this.configuration).getMonthlyRecords1(yearMonth, statusList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 청구서별 이용 내역 전달
     * @param {string} invoiceId 
     * @param {Array<PaidRecordStatus>} [statusList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaidSessionRecordControllerApi
     */
    public getRecordByInvoice1(invoiceId: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig) {
        return PaidSessionRecordControllerApiFp(this.configuration).getRecordByInvoice1(invoiceId, statusList, options).then((request) => request(this.axios, this.basePath));
    }
}

