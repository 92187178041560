import WarningIcon from '@MP/icon/WarningIcon';
import { Button, Modal, ModalDialog, Typography } from '@mui/joy';
import React, { FC, ReactNode } from 'react';
import { CiCircleCheck } from 'react-icons/ci';
import { AlertActions, AlertContent, AlertTitle, MobileAlertContent } from '.';

interface Props {
  open: boolean;
  warning?: boolean;
  title: string;
  message: ReactNode;
  btnText?: string;
  disabledIcon?: boolean;
  icon?: ReactNode;
  mobile?: boolean;
  onClose: () => void;
}
const AlertDialog: FC<Props> = ({
  open,
  warning,
  mobile,
  disabledIcon,
  icon,
  title,
  message,
  btnText = '확인',
  onClose,
}) => {
  const renderMessage = React.useMemo(() => {
    if (typeof message === 'string') {
      return <Typography sx={{ fontSize: 14 }}>{message}</Typography>;
    } else {
      return message;
    }
  }, [message]);

  return (
    <Modal open={open}>
      <ModalDialog>
        {!mobile && (
          <AlertContent>
            {!disabledIcon && icon && icon}
            {!disabledIcon && !icon && warning && <WarningIcon />}
            {!disabledIcon && !icon && !warning && <CiCircleCheck fontSize={66} color="#26A7E3" />}
            <AlertTitle>{title}</AlertTitle>
            {renderMessage}
          </AlertContent>
        )}
        {mobile && (
          <MobileAlertContent>
            {!disabledIcon && icon && icon}
            {!disabledIcon && !icon && warning && <WarningIcon />}
            {!disabledIcon && !icon && !warning && <CiCircleCheck fontSize={66} color="#26A7E3" />}
            <AlertTitle>{title}</AlertTitle>
            {renderMessage}
          </MobileAlertContent>
        )}
        <AlertActions>
          <Button sx={{ height: 52 }} onClick={onClose}>
            <Typography sx={{ fontSize: 16, color: 'inherit', fontWeight: 500 }}>{btnText}</Typography>
          </Button>
        </AlertActions>
      </ModalDialog>
    </Modal>
  );
};
export default AlertDialog;
