import { makeAutoObservable } from 'mobx';

import { CreateOrganizationDto, DepartmentDto, OrganizationSummaryDto } from '@MP/api/auth/public1.0';
import { Address } from '@MP/component/AddressInput';

export interface OrgItem {
  id: number | undefined;
  hpid: string | undefined;
  name: string;
  address: string;
  setDepartment: (department: string) => void;
  isNew: () => boolean;
  getRequest: () => CreateOrganizationDto;
  equals: (other: OrgItem) => boolean;
  valid: () => boolean;
}

export class NewOrganizationItem implements OrgItem {
  organization: CreateOrganizationDto = {};
  addressModel: Address | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get id() {
    return undefined;
  }

  get hpid() {
    return undefined;
  }

  get name() {
    return this.organization.name || '';
  }

  get address() {
    return this.organization.address || '';
  }

  get addressDetail() {
    return this.organization.addressDetail || '';
  }

  get tel() {
    return this.organization.tel || '';
  }

  setName(name: string) {
    this.organization.name = name;
  }

  setTel(tel: string) {
    this.organization.tel = tel;
  }

  setAddressModel(address: Address | undefined) {
    this.addressModel = address;
    this.organization.address = address?.address;
    this.organization.addressZipCode = address?.addressZipCode;
    this.organization.addressDetail = '';
  }

  setAddressDetail(addressDetail: string) {
    this.organization.addressDetail = addressDetail;
  }

  setDepartment(department: string) {
    this.organization.department = department;
  }

  equals(other: OrgItem) {
    return this.id === other.id && this.hpid === other.hpid;
  }

  getRequest(): CreateOrganizationDto {
    return this.organization;
  }

  isNew() {
    return true;
  }

  valid() {
    return this.name.length > 2 && this.address.length > 0 && this.addressDetail.length > 0;
  }
}

export class OrganizationItem implements OrgItem {
  organization: OrganizationSummaryDto;
  department: string | undefined;
  constructor(organization: OrganizationSummaryDto) {
    this.organization = organization;
  }

  get name() {
    return this.organization.name || '';
  }

  get address() {
    return this.organization.address || '';
  }

  get id() {
    if (this.department) {
      return this.organization.department !== this.department ? undefined : this.organization.id;
    } else {
      return this.organization.id;
    }
  }

  get hpid() {
    return this.organization.hpid;
  }

  setDepartment(department: string) {
    this.department = department;
  }

  equals(other: OrgItem) {
    return this.id === other.id && this.hpid === other.hpid;
  }

  isNew() {
    return false;
  }

  getRequest(): CreateOrganizationDto {
    return {
      name: this.organization.name,
      department: this.department,
      address: this.organization.address,
      addressDetail: this.organization.addressDetail,
      addressZipCode: this.organization.addressZipCode,
      tel: this.organization.tel,
      hpid: this.organization.hpid,
      latitude: this.organization.latitude,
      longitude: this.organization.longitude,
    };
  }

  valid() {
    return true;
  }
}

export interface AutoDepartment extends DepartmentDto {
  add?: boolean;
  addLabel?: string;
}
