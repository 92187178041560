import Loading from '@MP/component/Loading';
import { Stack, Typography } from '@mui/joy';
import { QueryClientProvider } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FcExpired } from 'react-icons/fc';
import { Outlet } from 'react-router';
import { useMobileQueryClient } from './useMobileQueryClient';

const InvalidAccess = React.memo(() => {
  return (
    <Stack justifyContent="center" alignItems="center" flexGrow={1} rowGap={5}>
      <FcExpired size={140} />
      <Typography level="body-md" fontWeight={600}>
        유효하지 않은 접근입니다.
      </Typography>
    </Stack>
  );
});

const ExpiredToken = React.memo(() => {
  return (
    <Stack justifyContent="center" alignItems="center" flexGrow={1} rowGap={5}>
      <FcExpired size={140} />
      <Stack sx={{ textAlign: 'center', px: '16px' }} spacing={'8px'}>
        <Typography level="body-md" fontWeight={600}>
          기한이 만료되었습니다.
        </Typography>
        <Typography level="body-md" fontWeight={600}>
          다시 요청해주세요.
        </Typography>
      </Stack>
    </Stack>
  );
});

const DeletedSession = React.memo(() => {
  return (
    <Stack justifyContent="center" alignItems="center" flexGrow={1} rowGap={5}>
      <FcExpired size={140} />
      <Stack sx={{ textAlign: 'center', px: '16px' }} spacing={'8px'}>
        <Typography level="body-md" fontWeight={600}>
          병원에서 삭제한 검사 입니다.
        </Typography>
        <Typography level="body-md" fontWeight={600}>
          병원에 문의해주세요.
        </Typography>
      </Stack>
    </Stack>
  );
});

const MRootContainer = observer(() => {
  const [loading, setLoading] = React.useState(true);
  const [expiredToken, setExpiredToken] = React.useState<boolean>(false);
  const [deletedSession, setDeletedSession] = React.useState<boolean>(false);
  const [invalidToken, setInvalidToken] = React.useState<boolean>(false);

  const valid = !deletedSession && !expiredToken && !invalidToken;

  React.useEffect(() => {
    const params = new URL(document.location.href).searchParams;
    const tokenStr = params.get('token');
    if (tokenStr) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenStr;
    } else {
      setInvalidToken(true);
    }
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const errFn = React.useCallback((error: AxiosError) => {
    const res = error.response?.data as any;
    if (
      res?.code === 'EXPIRED_JWT' ||
      res?.code === 'NOT_FOUND_SUBJECT_META' ||
      res?.code === 'NOT_FOUND_SURVEY_SESSION' ||
      res?.code === 'ALREADY_ANSWERED_SESSION_WHEN_UNREGISTERED_USER'
    ) {
      setExpiredToken(true);
    } else if (res?.code === 'DELETED_SURVEY_SESSION') {
      setDeletedSession(true);
    } else if (res?.code === 'UNAUTHORIZED') {
      setInvalidToken(true);
    }
  }, []);

  const queryClient = useMobileQueryClient(errFn);

  if (loading) {
    return <Loading />;
  }

  return (
    <Stack height={'100vh'} bgcolor={'#FFF'} overflow={'auto'}>
      <QueryClientProvider client={queryClient}>{valid && <Outlet />}</QueryClientProvider>

      {expiredToken && <ExpiredToken />}
      {deletedSession && <DeletedSession />}
      {invalidToken && <InvalidAccess />}
    </Stack>
  );
});

export default MRootContainer;
