import { makeAutoObservable } from 'mobx';
import React from 'react';

class PatientPageStore {
  expanded: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setExpanded(flag: boolean) {
    this.expanded = flag;
  }
}

export const PatientPageStoreContext: React.Context<PatientPageStore> = React.createContext<PatientPageStore>(
  {} as PatientPageStore
);

export const usePatientPageStore = () => React.useContext(PatientPageStoreContext);

export default PatientPageStore;
