import { times } from 'lodash';
import SignalReadableContents from './SignalReadableContents';
import {
  ALL_HEADER_SIZE_BYTE,
  CHANNEL_COUNT_BYTE,
  CHANNEL_HEADER_FIELDS,
  CHANNEL_LABEL_NAME,
  DIGITAL_MAX_NAME,
  DIGITAL_MIN_NAME,
  PHYSICAL_DIMENSION_NAME,
  PHYSICAL_MAX_NAME,
  PHYSICAL_MIN_NAME,
  PREFILTERING_NAME,
  RECORDING_ID_BYTE,
  RECORD_COUNT_BYTE,
  RECORD_INTERVAL_SECOND_BYTE,
  RESERVED_NAME,
  SAMPLE_COUNT_NAME,
  START_DATE_BYTE,
  START_TIME_BYTE,
  SUBJECT_ID_BYTE,
  TRANSDUCER_TYPE_NAME,
  VERSION_FORMAT_BYTE,
} from './const';
import { ChannelHeader, FileHeader, SignalFile } from './type';

const createEmptyHeaders = (channelCnt: number): ChannelHeader[] => {
  return times(channelCnt, () => new ChannelHeader());
};

export default class SignalFileReader {
  private contents: SignalReadableContents;

  constructor(file: ArrayBuffer) {
    this.contents = new SignalReadableContents(file);
  }

  private parseStartDateTime(sDate: string, sTime: string): Date {
    const date_parts = sDate.split('.');
    const time_parts = sTime.split('.');

    if (date_parts.length !== 3 || time_parts.length !== 3) {
      throw new Error(`Invalid start date/time field (date = ${sDate} / time = ${sTime})`);
    }

    var year = parseInt(date_parts[2]);
    const clipping = year >= 85 && year <= 99 ? 1900 : 2000;

    return new Date(
      year + clipping,
      Number(date_parts[1]) - 1,
      Number(date_parts[0]),
      Number(time_parts[0]),
      Number(time_parts[1]),
      Number(time_parts[2])
    );
  }

  private readFileHeader(): FileHeader {
    const id = this.contents.readId();
    const patient = this.contents.readText(SUBJECT_ID_BYTE);
    const recordId = this.contents.readText(RECORDING_ID_BYTE);
    const sDate = this.contents.readText(START_DATE_BYTE);
    const sTime = this.contents.readText(START_TIME_BYTE);
    const startDate = this.parseStartDateTime(sDate, sTime);
    const headerLength = this.contents.readText(ALL_HEADER_SIZE_BYTE);
    const versionFormat = this.contents.readText(VERSION_FORMAT_BYTE);
    const dataRecords = this.contents.readText(RECORD_COUNT_BYTE);
    const duration = this.contents.readText(RECORD_INTERVAL_SECOND_BYTE);
    const channelCnt = this.contents.readText(CHANNEL_COUNT_BYTE);

    return {
      id: id,
      subjectId: patient,
      recordId: recordId,
      startDate: startDate,
      headerLength: Number(headerLength),
      versionFormat: versionFormat,
      dataRecordCnt: Number(dataRecords),
      intervalSec: Number(duration),
      channelCnt: Number(channelCnt),
    };
  }

  private readChannelHeaders = (channelCnt: number): ChannelHeader[] => {
    const channelHeaders: ChannelHeader[] = createEmptyHeaders(channelCnt);

    for (let fieldIdx = 0; fieldIdx < CHANNEL_HEADER_FIELDS.length; fieldIdx++) {
      const field = CHANNEL_HEADER_FIELDS[fieldIdx];

      for (let chIdx = 0; chIdx < channelCnt; chIdx++) {
        const data = this.contents.readText(field.length);

        if (field.name === CHANNEL_LABEL_NAME) {
          channelHeaders[chIdx].label = data;
        } else if (field.name === TRANSDUCER_TYPE_NAME) {
          channelHeaders[chIdx].transducer = data;
        } else if (field.name === PHYSICAL_DIMENSION_NAME) {
          channelHeaders[chIdx].physicalDimensions = data;
        } else if (field.name === PHYSICAL_MIN_NAME) {
          channelHeaders[chIdx].physicalMin = parseFloat(data);
        } else if (field.name === PHYSICAL_MAX_NAME) {
          channelHeaders[chIdx].physicalMax = parseFloat(data);
        } else if (field.name === DIGITAL_MIN_NAME) {
          channelHeaders[chIdx].digitalMin = parseInt(data);
        } else if (field.name === DIGITAL_MAX_NAME) {
          channelHeaders[chIdx].digitalMax = parseInt(data);
        } else if (field.name === PREFILTERING_NAME) {
          channelHeaders[chIdx].preFiltering = data;
        } else if (field.name === SAMPLE_COUNT_NAME) {
          channelHeaders[chIdx].samples = parseInt(data);
        } else if (field.name === RESERVED_NAME) {
          channelHeaders[chIdx].reserved = data;
        }
      }
    }

    return channelHeaders;
  };

  private readSignalRecords(channelHeaders: ChannelHeader[], dataRecordLength: number) {
    const signalRecords: number[][] = times(channelHeaders.length, () => []);

    for (let drl = 0; drl < dataRecordLength; drl++) {
      for (let ch = 0; ch < channelHeaders.length; ch++) {
        const { samples } = channelHeaders[ch];
        for (let smp = 0; smp < samples; smp++) {
          let signal: number;

          if (this.contents.isBdf()) {
            signal = this.contents.readInt24LE();
          } else {
            signal = this.contents.readInt16LE();
          }

          signalRecords[ch].push(signal);
        }
      }
    }
    return signalRecords;
  }

  read() {
    const fileHeader = this.readFileHeader();
    const channelHeaders = this.readChannelHeaders(fileHeader.channelCnt);

    console.log(fileHeader);
    console.log(channelHeaders);
    const signalFile = new SignalFile(fileHeader, channelHeaders);

    const signalRecords = this.readSignalRecords(channelHeaders, fileHeader.dataRecordCnt);
    console.log(signalRecords);

    signalFile.setSignalRecords(signalRecords);
    return signalFile;
  }
}
