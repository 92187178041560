/* tslint:disable */
/* eslint-disable */
/**
 * MINDD Survey API Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AnswerSheetCreateRequest } from '../model';
// @ts-ignore
import type { CancellationRequest } from '../model';
// @ts-ignore
import type { SleepDiary } from '../model';
// @ts-ignore
import type { SleepSession } from '../model';
// @ts-ignore
import type { SleepSessionCreateRequest } from '../model';
/**
 * SleepSessionControllerApi - axios parameter creator
 * @export
 */
export const SleepSessionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AnswerSheetCreateRequest} answerSheetCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answer1: async (answerSheetCreateRequest: AnswerSheetCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'answerSheetCreateRequest' is not null or undefined
            assertParamExists('answer1', 'answerSheetCreateRequest', answerSheetCreateRequest)
            const localVarPath = `/api/v1/sleep-sessions/answer-sheets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answerSheetCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {CancellationRequest} cancellationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSleepSession: async (sessionId: string, cancellationRequest: CancellationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('cancelSleepSession', 'sessionId', sessionId)
            // verify required parameter 'cancellationRequest' is not null or undefined
            assertParamExists('cancelSleepSession', 'cancellationRequest', cancellationRequest)
            const localVarPath = `/api/v1/sleep-sessions/{sessionId}/cancel`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancellationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SleepSessionCreateRequest} sleepSessionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSleepSession: async (sleepSessionCreateRequest: SleepSessionCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sleepSessionCreateRequest' is not null or undefined
            assertParamExists('createSleepSession', 'sleepSessionCreateRequest', sleepSessionCreateRequest)
            const localVarPath = `/api/v1/sleep-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sleepSessionCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSleepDiaries: async (sessionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('getSleepDiaries', 'sessionId', sessionId)
            const localVarPath = `/api/v1/sleep-sessions/{sessionId}/sleep-diary`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSleepDiary: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sleep-sessions/sleep-diary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSleepSessions: async (subjectMetaId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectMetaId' is not null or undefined
            assertParamExists('getSleepSessions', 'subjectMetaId', subjectMetaId)
            const localVarPath = `/api/v1/sleep-sessions/subject-meta/{subjectMetaId}`
                .replace(`{${"subjectMetaId"}}`, encodeURIComponent(String(subjectMetaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SleepSessionControllerApi - functional programming interface
 * @export
 */
export const SleepSessionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SleepSessionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AnswerSheetCreateRequest} answerSheetCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answer1(answerSheetCreateRequest: AnswerSheetCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.answer1(answerSheetCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SleepSessionControllerApi.answer1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {CancellationRequest} cancellationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSleepSession(sessionId: string, cancellationRequest: CancellationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSleepSession(sessionId, cancellationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SleepSessionControllerApi.cancelSleepSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SleepSessionCreateRequest} sleepSessionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSleepSession(sleepSessionCreateRequest: SleepSessionCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SleepSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSleepSession(sleepSessionCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SleepSessionControllerApi.createSleepSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSleepDiaries(sessionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SleepDiary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSleepDiaries(sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SleepSessionControllerApi.getSleepDiaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSleepDiary(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SleepDiary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSleepDiary(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SleepSessionControllerApi.getSleepDiary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSleepSessions(subjectMetaId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SleepSession>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSleepSessions(subjectMetaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SleepSessionControllerApi.getSleepSessions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SleepSessionControllerApi - factory interface
 * @export
 */
export const SleepSessionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SleepSessionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AnswerSheetCreateRequest} answerSheetCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answer1(answerSheetCreateRequest: AnswerSheetCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.answer1(answerSheetCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {CancellationRequest} cancellationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSleepSession(sessionId: string, cancellationRequest: CancellationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.cancelSleepSession(sessionId, cancellationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SleepSessionCreateRequest} sleepSessionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSleepSession(sleepSessionCreateRequest: SleepSessionCreateRequest, options?: any): AxiosPromise<SleepSession> {
            return localVarFp.createSleepSession(sleepSessionCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSleepDiaries(sessionId: string, options?: any): AxiosPromise<Array<SleepDiary>> {
            return localVarFp.getSleepDiaries(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSleepDiary(options?: any): AxiosPromise<SleepDiary> {
            return localVarFp.getSleepDiary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSleepSessions(subjectMetaId: string, options?: any): AxiosPromise<Array<SleepSession>> {
            return localVarFp.getSleepSessions(subjectMetaId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SleepSessionControllerApi - object-oriented interface
 * @export
 * @class SleepSessionControllerApi
 * @extends {BaseAPI}
 */
export class SleepSessionControllerApi extends BaseAPI {
    /**
     * 
     * @param {AnswerSheetCreateRequest} answerSheetCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SleepSessionControllerApi
     */
    public answer1(answerSheetCreateRequest: AnswerSheetCreateRequest, options?: RawAxiosRequestConfig) {
        return SleepSessionControllerApiFp(this.configuration).answer1(answerSheetCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {CancellationRequest} cancellationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SleepSessionControllerApi
     */
    public cancelSleepSession(sessionId: string, cancellationRequest: CancellationRequest, options?: RawAxiosRequestConfig) {
        return SleepSessionControllerApiFp(this.configuration).cancelSleepSession(sessionId, cancellationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SleepSessionCreateRequest} sleepSessionCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SleepSessionControllerApi
     */
    public createSleepSession(sleepSessionCreateRequest: SleepSessionCreateRequest, options?: RawAxiosRequestConfig) {
        return SleepSessionControllerApiFp(this.configuration).createSleepSession(sleepSessionCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SleepSessionControllerApi
     */
    public getSleepDiaries(sessionId: string, options?: RawAxiosRequestConfig) {
        return SleepSessionControllerApiFp(this.configuration).getSleepDiaries(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SleepSessionControllerApi
     */
    public getSleepDiary(options?: RawAxiosRequestConfig) {
        return SleepSessionControllerApiFp(this.configuration).getSleepDiary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subjectMetaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SleepSessionControllerApi
     */
    public getSleepSessions(subjectMetaId: string, options?: RawAxiosRequestConfig) {
        return SleepSessionControllerApiFp(this.configuration).getSleepSessions(subjectMetaId, options).then((request) => request(this.axios, this.basePath));
    }
}

