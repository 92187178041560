import { Stack, StackProps } from '@mui/joy';
import React from 'react';
import Loading from './Loading';

const Section = React.forwardRef<HTMLDivElement, StackProps & { loading?: boolean }>(
  ({ children, loading, sx, ...props }, ref) => {
    return (
      <Stack
        {...props}
        ref={ref}
        position={'relative'}
        sx={{ backgroundColor: '#F8F8F8', borderRadius: '8px', overflow: loading ? 'hidden' : 'auto', ...sx }}>
        {children}
        {loading && <Loading />}
      </Stack>
    );
  }
);

export default Section;
