// SCAN
export class ModuleStateChangeError extends Error {}

// SURVEY, QUESTIONNAIRE
export interface SessionType {
  key: string;
  name: string;
}
export const SleepSessionType: SessionType = { key: 'SLEEP_DIARY', name: '수면' };

export const SessionTypes = [SleepSessionType];

//NOTICE
export interface LinkInfo {
  x: number;
  y: number;
  width: number;
  height: number;
  url: string;
}

export interface Notice {
  createdTime: string;
  enableCheckboxToDisableForever: boolean;
  enableCheckboxToDisableToday: boolean;
  startDate: string;
  endDate: string;
  image: string;
  link: LinkInfo[];
  needLogin: boolean;
  publishCondition: string;
  serviceType: string;
  timestampId: number;
}
