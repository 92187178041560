import { FC } from 'react';
import { ic_check_list, ic_check_list_active } from '.';

const SurveyMenuIcon: FC<{ width?: number; primary?: boolean }> = ({ width = 24, primary }) => {
  if (primary) {
    return <img src={ic_check_list_active} width={width} alt="" />;
  }
  return <img src={ic_check_list} width={width} alt="" />;
};

export default SurveyMenuIcon;
