import { ICloseEvent, IMessageEvent, w3cwebsocket as W3CWebSocket } from 'websocket';
import { SessionInfo } from '../scan';
import { ScanSensorType } from './../scan/model/scan-sensor-type';
import { ModuleState, SubscribeId } from './type';

enum MessageKind {
  ERASE_RECORDED_DATA_PROCESS = 'ERASE_RECORDED_DATA_PROCESS',
  POST_PROCESS = 'POST_PROCESS',
  REC_FILE_DELETED = 'REC_FILE_DELETED',
  CLEAN_TIME_MILLISECONDS = 'CLEAN_TIME_MILLISECONDS',
  SESSION_STATE_TRANSITION = 'SESSION_STATE_TRANSITION',
  BATTERY_STATUS = 'BATTERY_STATUS',
  EEG_QUALITY_TRANSMISSION_CHANGED = 'EEG_QUALITY_TRANSMISSION_CHANGED',
  SENSOR_TRANSMISSION_CHANGED = 'SENSOR_TRANSMISSION_CHANGED',
  SESSION_INFO_CHANGED = 'SESSION_INFO_CHANGED',
  MODULE_REGISTRATION_CHANGED = 'MODULE_REGISTRATION_CHANGED',
  DISCONNECTED = 'DISCONNECTED',
}

interface MessageListener {
  id: number;
  condition: (kind: MessageKind) => boolean;
  callback: (message: IMessageEvent) => void;
}

const NORMAL_CLOSURE_CODE = 1000;
const INVALID_TOKEN_CODE = 1011;

export class OrgSocketImpl implements OrgSocketClient {
  private _client: W3CWebSocket;
  private hbIntervalId;
  listeners: MessageListener[] = [];
  currId: number = 0;
  constructor(client: W3CWebSocket) {
    this._client = client;
    this.hbIntervalId = this.sendHeartBeat();
    this.init();
  }

  private sendHeartBeat() {
    return setInterval(() => {
      if (this._client.readyState === W3CWebSocket.OPEN) {
        this._client.send('');
      }
    }, 60000);
  }

  private init() {
    this._client.onclose = (e: ICloseEvent) => {
      if (e.code !== NORMAL_CLOSURE_CODE && e.code !== INVALID_TOKEN_CODE) {
        setTimeout(() => {
          this._client = new W3CWebSocket(this._client.url);
          this.init();
        }, 5000);
      }
    };
    this._client.onerror = (error: Error) => {
      console.log(error);
    };
    this._client.onmessage = (message: IMessageEvent) => {
      try {
        const event: ScanMessageEvent<any> = JSON.parse(message.data as string);
        this.listeners.forEach((listener) => {
          if (listener.condition(event.type)) {
            listener.callback(message);
          }
        });
      } catch {
        // ignored: ping data
      }
    };
  }

  unsubscribe(id: SubscribeId) {
    this.listeners = this.listeners.filter((listen) => listen.id !== id);
  }

  subscribeOnBattery(callback: (deviceSerial: string, percentage: number, isCharging: boolean) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.BATTERY_STATUS,
      callback: (message: IMessageEvent) => {
        // TODO: 수정 필요
        // const data = JSON.parse(message.data as string) as BatteryStatusReceived;
        // callback(data.deviceSerial, data.status.percentage, data.status.isCharging);
      },
    });

    return id;
  }
  subscribeOnState(callback: (deviceSerial: string, prev: ModuleState, state: ModuleState) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.SESSION_STATE_TRANSITION,
      callback: (message: IMessageEvent) => {
        const data = JSON.parse(message.data as string) as ScanMessageEvent<SessionState>;

        callback(data.deviceSerial, data.payload.prevState, data.payload.curState);
      },
    });
    return id;
  }

  subscribeOnCreatedRec(callback: (subjectMetaIds: string[]) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.POST_PROCESS,
      callback: (message: IMessageEvent) => {
        const data = JSON.parse(message.data as string) as ScanMessageEvent<PostProcess>;
        callback([data.payload.subjectId]);
      },
    });

    return id;
  }
  subscribeOnDeletedRec(callback: (subjectMetaIds: string[]) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.REC_FILE_DELETED,
      callback: (message: IMessageEvent) => {
        const data = JSON.parse(message.data as string) as ScanMessageEvent<RecFileDeleted>;
        callback(data.payload.subjectIds);
      },
    });

    return id;
  }

  subscribeOnDisposeProgress(callback: (deviceSerial: string, progress: number) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.ERASE_RECORDED_DATA_PROCESS,
      callback: (message: IMessageEvent) => {
        const data = JSON.parse(message.data as string) as ScanMessageEvent<RecordedDataProcess>;
        callback(data.deviceSerial, data.payload.value);
      },
    });

    return id;
  }
  subscribeOnDisconnected(callback: (deviceSerial: string) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.DISCONNECTED,
      callback: (message: IMessageEvent) => {
        const data = JSON.parse(message.data as string) as ScanMessageEvent<any>;
        callback(data.deviceSerial);
      },
    });

    return id;
  }

  subscribeOnRegistration(callback: (deviceSerial: string) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.MODULE_REGISTRATION_CHANGED,
      callback: (message: IMessageEvent) => {
        const data = JSON.parse(message.data as string) as ScanMessageEvent<any>;
        callback(data.deviceSerial);
      },
    });

    return id;
  }

  subscribeOnQualityTransmission(callback: (deviceSerial: string, result: boolean) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.EEG_QUALITY_TRANSMISSION_CHANGED,
      callback: (message: IMessageEvent) => {
        const data = JSON.parse(message.data as string) as ScanMessageEvent<QualityTransmission>;
        callback(data.deviceSerial, data.payload.transmission);
      },
    });

    return id;
  }

  subscribeOnSensorTransmission(callback: (deviceSerial: string, eeg: boolean, hrv: boolean) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.SENSOR_TRANSMISSION_CHANGED,
      callback: (message: IMessageEvent) => {
        const data = JSON.parse(message.data as string) as ScanMessageEvent<SensorTransmission>;
        callback(data.deviceSerial, data.payload.eeg, data.payload.hrv);
      },
    });

    return id;
  }

  subscribeOnCleanTime(callback: (deviceSerial: string, ms: number) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.CLEAN_TIME_MILLISECONDS,
      callback: (message: IMessageEvent) => {
        const data = JSON.parse(message.data as string) as ScanMessageEvent<CleanTime>;
        callback(data.deviceSerial, data.payload.value);
      },
    });

    return id;
  }
  subscribeOnSession(callback: (deviceSerial: string, info: SessionInfo) => void) {
    const id = this.currId++;
    this.listeners.push({
      id: id,
      condition: (kind: MessageKind) => kind === MessageKind.SESSION_INFO_CHANGED,
      callback: (message: IMessageEvent) => {
        const data = JSON.parse(message.data as string) as ScanMessageEvent<SessionInfo>;
        callback(data.deviceSerial, data.payload);
      },
    });

    return id;
  }

  disconnect() {
    this.listeners = [];
    clearInterval(this.hbIntervalId);
    const intervalId = setInterval(() => {
      if (this._client.readyState !== W3CWebSocket.CONNECTING) {
        if (this._client.readyState === W3CWebSocket.OPEN) {
          this._client.close(NORMAL_CLOSURE_CODE);
        }
        clearInterval(intervalId);
      }
    }, 100);
  }

  get connected() {
    return this._client.readyState === W3CWebSocket.OPEN;
  }
}

interface ScanMessageEvent<T> {
  deviceSerial: string;
  type: MessageKind;
  payload: T;
}

interface SessionState {
  prevState: ModuleState;
  curState: ModuleState;
  reason: string;
}
interface RecordedDataProcess {
  value: number;
}

interface PostProcess {
  subjectId: string;
  sensorType: ScanSensorType;
}

interface RecFileDeleted {
  subjectIds: string[];
}

interface CleanTime {
  value: number;
}

interface QualityTransmission {
  transmission: boolean;
}

interface SensorTransmission {
  eeg: boolean;
  hrv: boolean;
}

export interface OrgSocketClient {
  subscribeOnBattery: (
    callback: (deviceSerial: string, percentage: number, isCharging: boolean) => void
  ) => SubscribeId;
  subscribeOnState: (callback: (deviceSerial: string, prev: ModuleState, state: ModuleState) => void) => SubscribeId;
  subscribeOnCreatedRec: (callback: (subjectMetaIds: string[]) => void) => SubscribeId;
  subscribeOnDeletedRec: (callback: (subjectMetaIds: string[]) => void) => SubscribeId;
  subscribeOnDisposeProgress: (callback: (deviceSerial: string, progress: number) => void) => SubscribeId;
  subscribeOnCleanTime: (callback: (deviceSerial: string, ms: number) => void) => SubscribeId;
  subscribeOnSensorTransmission: (callback: (deviceSerial: string, eeg: boolean, hrv: boolean) => void) => SubscribeId;
  subscribeOnQualityTransmission: (callback: (deviceSerial: string, result: boolean) => void) => SubscribeId;
  subscribeOnRegistration: (callback: (deviceSerial: string) => void) => SubscribeId;
  subscribeOnDisconnected: (callback: (deviceSerial: string) => void) => SubscribeId;
  subscribeOnSession: (callback: (deviceSerial: string, info: SessionInfo) => void) => SubscribeId;
  unsubscribe: (id: SubscribeId) => void;
}
