import { Gender } from '@MP/api/survey';

const GenderUtils = {
  displayName: (gender?: Gender | null) => {
    switch (gender) {
      case Gender.Female:
        return '여';
      case Gender.Male:
        return '남';
      case Gender.Other:
        return '기타';
      default:
        return '';
    }
  },
};

export default GenderUtils;
