/* tslint:disable */
/* eslint-disable */
/**
 * Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AuthClientRequest } from '../model';
// @ts-ignore
import type { EmployeeAgreementUpdateDto } from '../model';
// @ts-ignore
import type { EmployeeRequestDto } from '../model';
// @ts-ignore
import type { EmployeeResponseDto } from '../model';
// @ts-ignore
import type { OAuth2AccessToken } from '../model';
// @ts-ignore
import type { RestErrorResponse } from '../model';
// @ts-ignore
import type { UpdatePasswordDto } from '../model';
/**
 * EmployeeControllerApi - axios parameter creator
 * @export
 */
export const EmployeeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EmployeeRequestDto} employeeRequestDto 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee: async (employeeRequestDto: EmployeeRequestDto, lang?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeRequestDto' is not null or undefined
            assertParamExists('createEmployee', 'employeeRequestDto', employeeRequestDto)
            const localVarPath = `/1.4/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthClientRequest} authClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOnlyToken: async (authClientRequest: AuthClientRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authClientRequest' is not null or undefined
            assertParamExists('createOnlyToken', 'authClientRequest', authClientRequest)
            const localVarPath = `/1.4/employees/auth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthClientRequest} authClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken: async (authClientRequest: AuthClientRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authClientRequest' is not null or undefined
            assertParamExists('createToken', 'authClientRequest', authClientRequest)
            const localVarPath = `/1.4/employees/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEmployee', 'id', id)
            const localVarPath = `/1.4/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeBy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmployeeBy', 'id', id)
            const localVarPath = `/1.4/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/1.4/employees/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesperson: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/1.4/employees/salesperson`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetPassword', 'id', id)
            const localVarPath = `/1.4/employees/{id}/password/reset`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} verificationId 
         * @param {string} code 
         * @param {EmployeeAgreementUpdateDto} employeeAgreementUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgreement: async (id: number, verificationId: number, code: string, employeeAgreementUpdateDto: EmployeeAgreementUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgreement', 'id', id)
            // verify required parameter 'verificationId' is not null or undefined
            assertParamExists('updateAgreement', 'verificationId', verificationId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('updateAgreement', 'code', code)
            // verify required parameter 'employeeAgreementUpdateDto' is not null or undefined
            assertParamExists('updateAgreement', 'employeeAgreementUpdateDto', employeeAgreementUpdateDto)
            const localVarPath = `/1.4/employees/{id}/agreement`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (verificationId !== undefined) {
                localVarQueryParameter['verificationId'] = verificationId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeAgreementUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmployeeRequestDto} employeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee: async (employeeRequestDto: EmployeeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeRequestDto' is not null or undefined
            assertParamExists('updateEmployee', 'employeeRequestDto', employeeRequestDto)
            const localVarPath = `/1.4/employees/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (updatePasswordDto: UpdatePasswordDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePasswordDto' is not null or undefined
            assertParamExists('updatePassword', 'updatePasswordDto', updatePasswordDto)
            const localVarPath = `/1.4/employees/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRolesRoleEnum} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoles: async (id: number, role: UpdateRolesRoleEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRoles', 'id', id)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('updateRoles', 'role', role)
            const localVarPath = `/1.4/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeControllerApi - functional programming interface
 * @export
 */
export const EmployeeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EmployeeRequestDto} employeeRequestDto 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmployee(employeeRequestDto: EmployeeRequestDto, lang?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmployee(employeeRequestDto, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.createEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AuthClientRequest} authClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOnlyToken(authClientRequest: AuthClientRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOnlyToken(authClientRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.createOnlyToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AuthClientRequest} authClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createToken(authClientRequest: AuthClientRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuth2AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createToken(authClientRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.createToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmployee(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmployee(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.deleteEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeBy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeBy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.getEmployeeBy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.getEmployeeMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesperson(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesperson(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.getSalesperson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} verificationId 
         * @param {string} code 
         * @param {EmployeeAgreementUpdateDto} employeeAgreementUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgreement(id: number, verificationId: number, code: string, employeeAgreementUpdateDto: EmployeeAgreementUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgreement(id, verificationId, code, employeeAgreementUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.updateAgreement']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EmployeeRequestDto} employeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployee(employeeRequestDto: EmployeeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployee(employeeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.updateEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(updatePasswordDto: UpdatePasswordDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(updatePasswordDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.updatePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRolesRoleEnum} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoles(id: number, role: UpdateRolesRoleEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoles(id, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.updateRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeControllerApi - factory interface
 * @export
 */
export const EmployeeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {EmployeeRequestDto} employeeRequestDto 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee(employeeRequestDto: EmployeeRequestDto, lang?: string, options?: any): AxiosPromise<number> {
            return localVarFp.createEmployee(employeeRequestDto, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthClientRequest} authClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOnlyToken(authClientRequest: AuthClientRequest, options?: any): AxiosPromise<string> {
            return localVarFp.createOnlyToken(authClientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthClientRequest} authClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken(authClientRequest: AuthClientRequest, options?: any): AxiosPromise<OAuth2AccessToken> {
            return localVarFp.createToken(authClientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEmployee(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeBy(id: number, options?: any): AxiosPromise<EmployeeResponseDto> {
            return localVarFp.getEmployeeBy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeMe(options?: any): AxiosPromise<EmployeeResponseDto> {
            return localVarFp.getEmployeeMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesperson(options?: any): AxiosPromise<Array<EmployeeResponseDto>> {
            return localVarFp.getSalesperson(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} verificationId 
         * @param {string} code 
         * @param {EmployeeAgreementUpdateDto} employeeAgreementUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgreement(id: number, verificationId: number, code: string, employeeAgreementUpdateDto: EmployeeAgreementUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateAgreement(id, verificationId, code, employeeAgreementUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmployeeRequestDto} employeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee(employeeRequestDto: EmployeeRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmployee(employeeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(updatePasswordDto: UpdatePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.updatePassword(updatePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRolesRoleEnum} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoles(id: number, role: UpdateRolesRoleEnum, options?: any): AxiosPromise<void> {
            return localVarFp.updateRoles(id, role, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeControllerApi - object-oriented interface
 * @export
 * @class EmployeeControllerApi
 * @extends {BaseAPI}
 */
export class EmployeeControllerApi extends BaseAPI {
    /**
     * 
     * @param {EmployeeRequestDto} employeeRequestDto 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public createEmployee(employeeRequestDto: EmployeeRequestDto, lang?: string, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).createEmployee(employeeRequestDto, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthClientRequest} authClientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public createOnlyToken(authClientRequest: AuthClientRequest, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).createOnlyToken(authClientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthClientRequest} authClientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public createToken(authClientRequest: AuthClientRequest, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).createToken(authClientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public deleteEmployee(id: number, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).deleteEmployee(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getEmployeeBy(id: number, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getEmployeeBy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getEmployeeMe(options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getEmployeeMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getSalesperson(options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getSalesperson(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public resetPassword(id: number, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).resetPassword(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} verificationId 
     * @param {string} code 
     * @param {EmployeeAgreementUpdateDto} employeeAgreementUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public updateAgreement(id: number, verificationId: number, code: string, employeeAgreementUpdateDto: EmployeeAgreementUpdateDto, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).updateAgreement(id, verificationId, code, employeeAgreementUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmployeeRequestDto} employeeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public updateEmployee(employeeRequestDto: EmployeeRequestDto, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).updateEmployee(employeeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePasswordDto} updatePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public updatePassword(updatePasswordDto: UpdatePasswordDto, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).updatePassword(updatePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateRolesRoleEnum} role 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public updateRoles(id: number, role: UpdateRolesRoleEnum, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).updateRoles(id, role, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const UpdateRolesRoleEnum = {
    UNormal: 'U_NORMAL',
    UAdmin: 'U_ADMIN',
    USubject: 'U_SUBJECT',
    YAdmin: 'Y_ADMIN',
    YManager: 'Y_MANAGER',
    YOperator: 'Y_OPERATOR',
    YDistributor: 'Y_DISTRIBUTOR',
    PAdmin: 'P_ADMIN',
    PNormal: 'P_NORMAL'
} as const;
export type UpdateRolesRoleEnum = typeof UpdateRolesRoleEnum[keyof typeof UpdateRolesRoleEnum];
