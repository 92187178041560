/* tslint:disable */
/* eslint-disable */
/**
 * MINDD Survey API Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Invoice } from '../model';
// @ts-ignore
import type { PaymentCard } from '../model';
// @ts-ignore
import type { RegisteredOrgIds } from '../model';
/**
 * PaymentCardControllerApi - axios parameter creator
 * @export
 */
export const PaymentCardControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 기존 카드를 제거하고 새로 발급 받은 receiptId로 결제 카드를 서버에 등록기존 카드는 Bootpay에서 삭제
         * @summary 결제 카드 변경
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCard: async (receiptId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('changeCard', 'receiptId', receiptId)
            const localVarPath = `/api/v1/cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (receiptId !== undefined) {
                localVarQueryParameter['receiptId'] = receiptId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Bootpay에서 결제 카드 삭제 후 서버에 등록된 결제 카드 삭제. 아직 응답하지 않은 유료 설문지가 있으면 설문지 삭제미결제 설문지가 있는 경우 청구서 생성 및 결제
         * @summary 결제 카드 삭제
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 결제 카드 정보 조회, 없으면 404
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ybrain Admin, Manager, Operator, Distributor 권한을 가진 사용자만 접근 가능
         * @summary 카드 등록된 병원 ID 리스트 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredCardOrgIds: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cards/orgIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Bootpay에서 발급받은 receiptId를 통해 결제 카드를 서버에 등록 요청한 병원에 이미 등록된 결제 카드가 있으면 BootPay에서 삭제 후 409를 반환
         * @summary 결제 카드 등록
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCard: async (receiptId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('registerCard', 'receiptId', receiptId)
            const localVarPath = `/api/v1/cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (receiptId !== undefined) {
                localVarQueryParameter['receiptId'] = receiptId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentCardControllerApi - functional programming interface
 * @export
 */
export const PaymentCardControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentCardControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 기존 카드를 제거하고 새로 발급 받은 receiptId로 결제 카드를 서버에 등록기존 카드는 Bootpay에서 삭제
         * @summary 결제 카드 변경
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeCard(receiptId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeCard(receiptId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentCardControllerApi.changeCard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Bootpay에서 결제 카드 삭제 후 서버에 등록된 결제 카드 삭제. 아직 응답하지 않은 유료 설문지가 있으면 설문지 삭제미결제 설문지가 있는 경우 청구서 생성 및 결제
         * @summary 결제 카드 삭제
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCard(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentCardControllerApi.deleteCard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 결제 카드 정보 조회, 없으면 404
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentCardControllerApi.getCardInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Ybrain Admin, Manager, Operator, Distributor 권한을 가진 사용자만 접근 가능
         * @summary 카드 등록된 병원 ID 리스트 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegisteredCardOrgIds(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisteredOrgIds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegisteredCardOrgIds(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentCardControllerApi.getRegisteredCardOrgIds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Bootpay에서 발급받은 receiptId를 통해 결제 카드를 서버에 등록 요청한 병원에 이미 등록된 결제 카드가 있으면 BootPay에서 삭제 후 409를 반환
         * @summary 결제 카드 등록
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerCard(receiptId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerCard(receiptId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentCardControllerApi.registerCard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentCardControllerApi - factory interface
 * @export
 */
export const PaymentCardControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentCardControllerApiFp(configuration)
    return {
        /**
         * 기존 카드를 제거하고 새로 발급 받은 receiptId로 결제 카드를 서버에 등록기존 카드는 Bootpay에서 삭제
         * @summary 결제 카드 변경
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCard(receiptId: string, options?: any): AxiosPromise<PaymentCard> {
            return localVarFp.changeCard(receiptId, options).then((request) => request(axios, basePath));
        },
        /**
         * Bootpay에서 결제 카드 삭제 후 서버에 등록된 결제 카드 삭제. 아직 응답하지 않은 유료 설문지가 있으면 설문지 삭제미결제 설문지가 있는 경우 청구서 생성 및 결제
         * @summary 결제 카드 삭제
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard(options?: any): AxiosPromise<Array<Invoice>> {
            return localVarFp.deleteCard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 결제 카드 정보 조회, 없으면 404
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardInfo(options?: any): AxiosPromise<PaymentCard> {
            return localVarFp.getCardInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Ybrain Admin, Manager, Operator, Distributor 권한을 가진 사용자만 접근 가능
         * @summary 카드 등록된 병원 ID 리스트 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredCardOrgIds(options?: any): AxiosPromise<RegisteredOrgIds> {
            return localVarFp.getRegisteredCardOrgIds(options).then((request) => request(axios, basePath));
        },
        /**
         * Bootpay에서 발급받은 receiptId를 통해 결제 카드를 서버에 등록 요청한 병원에 이미 등록된 결제 카드가 있으면 BootPay에서 삭제 후 409를 반환
         * @summary 결제 카드 등록
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCard(receiptId: string, options?: any): AxiosPromise<PaymentCard> {
            return localVarFp.registerCard(receiptId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentCardControllerApi - object-oriented interface
 * @export
 * @class PaymentCardControllerApi
 * @extends {BaseAPI}
 */
export class PaymentCardControllerApi extends BaseAPI {
    /**
     * 기존 카드를 제거하고 새로 발급 받은 receiptId로 결제 카드를 서버에 등록기존 카드는 Bootpay에서 삭제
     * @summary 결제 카드 변경
     * @param {string} receiptId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentCardControllerApi
     */
    public changeCard(receiptId: string, options?: RawAxiosRequestConfig) {
        return PaymentCardControllerApiFp(this.configuration).changeCard(receiptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Bootpay에서 결제 카드 삭제 후 서버에 등록된 결제 카드 삭제. 아직 응답하지 않은 유료 설문지가 있으면 설문지 삭제미결제 설문지가 있는 경우 청구서 생성 및 결제
     * @summary 결제 카드 삭제
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentCardControllerApi
     */
    public deleteCard(options?: RawAxiosRequestConfig) {
        return PaymentCardControllerApiFp(this.configuration).deleteCard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 결제 카드 정보 조회, 없으면 404
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentCardControllerApi
     */
    public getCardInfo(options?: RawAxiosRequestConfig) {
        return PaymentCardControllerApiFp(this.configuration).getCardInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ybrain Admin, Manager, Operator, Distributor 권한을 가진 사용자만 접근 가능
     * @summary 카드 등록된 병원 ID 리스트 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentCardControllerApi
     */
    public getRegisteredCardOrgIds(options?: RawAxiosRequestConfig) {
        return PaymentCardControllerApiFp(this.configuration).getRegisteredCardOrgIds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Bootpay에서 발급받은 receiptId를 통해 결제 카드를 서버에 등록 요청한 병원에 이미 등록된 결제 카드가 있으면 BootPay에서 삭제 후 409를 반환
     * @summary 결제 카드 등록
     * @param {string} receiptId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentCardControllerApi
     */
    public registerCard(receiptId: string, options?: RawAxiosRequestConfig) {
        return PaymentCardControllerApiFp(this.configuration).registerCard(receiptId, options).then((request) => request(this.axios, this.basePath));
    }
}

