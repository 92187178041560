import { SignalDataView } from '@MP/util/signal/type';
import { ChannelNumber, SignalInfo } from './type';

/**
 * ByteArray 포멧
 * https://ybrain.atlassian.net/wiki/x/BID5yw
 */
const SignalDataParser = {
  parse: async (blob: Blob): Promise<SignalInfo> => {
    const buffer = await blob.arrayBuffer();
    const dataView = new SignalDataView(buffer);
    const elapseTimeMs = dataView.getUint32(4);
    const scaleFactor = dataView.getFloat32(8);
    const chCnt = dataView.getUint32(12);

    let chNumber: ChannelNumber[] = [];
    for (let chIdx = 0; chIdx < chCnt; chIdx++) {
      chNumber.push(dataView.getUint8(16 + chIdx));
    }

    const sampleSize = dataView.getUint32(16 + chCnt);
    const sampleCnt = dataView.getUint32(16 + chCnt + 4);

    const data: Record<ChannelNumber, number[]> = {} as Record<ChannelNumber, number[]>;

    let offset = 16 + chCnt + 8;
    for (let chIdx = 0; chIdx < chCnt; chIdx++) {
      const signals: number[] = [];

      for (let sampleIdx = 0; sampleIdx < sampleCnt; sampleIdx++) {
        const signal = dataView.getInt24LE(offset);
        if (chNumber[chIdx] !== ChannelNumber.ANNOTATION) {
          signals.push(signal * scaleFactor);
        } else {
          signals.push(signal);
        }

        offset += sampleSize;
      }
      data[chNumber[chIdx]] = signals;
    }

    return {
      elapseTimeMs: elapseTimeMs,
      signals: data,
    };
  },
};

export default SignalDataParser;
