import { DialogActions, DialogContent, Typography, styled } from '@mui/joy';

export const AlertTitle = styled(Typography)({
  fontSize: 20,
  fontWeight: 600,
  color: '#333',
});

export const AlertContent = styled(DialogContent)({
  width: 440,
  alignItems: 'center',
  paddingTop: '24px',
  rowGap: '16px',
});

export const MobileAlertContent = styled(DialogContent)({
  alignItems: 'center',
  paddingTop: '24px',
  rowGap: '16px',
});

export const AlertActions = styled(DialogActions)({
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  marginTop: '16px',
});
