import { useExistsPhoneNumber, useSendCreateUserLink } from '@MP/api/surveyApi';
import { RectangleButton } from '@MP/component/Button';
import ConfirmDialog from '@MP/component/dialog/ConfirmDialog';
import { useMinddSnackbar } from '@MP/component/MinddSnackbarProvider';
import { Box, DialogActions, DialogContent, Divider, Modal, ModalDialog, Typography } from '@mui/joy';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent } from 'react';
import { TbSend } from 'react-icons/tb';
import PatientAddForm from './PatientAddForm';
import { PatientAddFormData } from './type';

const PatientAddDialog: FC<{ open: boolean; orgId: number; onClose: () => void }> = observer(
  ({ open, orgId, onClose }) => {
    const [openWarning, setOpenWarning] = React.useState(false);
    const [formData, setUser] = React.useState<PatientAddFormData>(new PatientAddFormData(orgId, true));

    const snackbar = useMinddSnackbar();
    const { mutateAsync: sendCreateUserLink, isPending: isSending } = useSendCreateUserLink();
    const { mutateAsync: existsPhoneNumber, isPending: isChecking } = useExistsPhoneNumber();

    React.useEffect(() => {
      if (!open) {
        setUser(new PatientAddFormData(orgId, true));
      }
    }, [open, orgId]);

    const handleSubmit = async (e: FormEvent) => {
      e.preventDefault();
      if (formData.isValid) {
        const result = await existsPhoneNumber(formData.phoneNumber);
        if (result) {
          setOpenWarning(true);
          return;
        }
        handleSend();
      }
    };

    const handleSend = async () => {
      if (formData.isValid) {
        await sendCreateUserLink({
          orgId: orgId,
          code: formData.code,
          name: formData.name,
          phoneNumber: formData.phoneNumber,
          birthYear: parseInt(formData.birth.substring(0, 4), 10),
          birthMonth: parseInt(formData.birth.substring(4, 6), 10),
          birthDay: parseInt(formData.birth.substring(6, 8), 10),
          agreements: ['SENSITIVE_INFO', 'THIRD_PARTY', 'POLICY'],
        });

        snackbar.add('환자 추가 동의서 전송이 완료되었습니다.');
        onClose();
      }
    };

    const handleDuplicatePhoneNumberResult = async (result: boolean) => {
      setOpenWarning(false);
      if (result) {
        handleSend();
      }
    };

    return (
      <>
        <Modal open={open}>
          <ModalDialog sx={{ gap: '16px' }} component={'form'} onSubmit={handleSubmit}>
            <Box>
              <Typography level="h4" fontWeight={600}>
                환자 추가
              </Typography>
              <Typography level="body-sm" fontSize={15}>
                환자의 휴대전화번호로 발송되며, <b>환자의 동의가 완료되면</b> 환자 목록에 추가됩니다.
              </Typography>
            </Box>
            <DialogContent sx={{ width: '500px' }}>
              <PatientAddForm value={formData} requiredPhoneNumber />
            </DialogContent>
            <Divider />
            <DialogActions sx={{ pt: 0 }}>
              <RectangleButton type="submit" startDecorator={<TbSend />} loading={isSending || isChecking}>
                발송
              </RectangleButton>
              <RectangleButton color="neutral" variant="outlined" onClick={onClose}>
                취소
              </RectangleButton>
            </DialogActions>
          </ModalDialog>
        </Modal>
        <ConfirmDialog
          open={openWarning}
          warning
          okText="예"
          cancelText="아니오"
          title="동일한 전화번호를 가진 환자가 있습니다"
          message={
            <Box textAlign={'center'}>
              <Typography sx={{ fontSize: 14, color: '#333' }}>환자를 새로 등록하시겠습니까?</Typography>
            </Box>
          }
          onResult={handleDuplicatePhoneNumberResult}
        />
      </>
    );
  }
);

export default PatientAddDialog;
