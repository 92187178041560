import { Snackbar } from '@mui/joy';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode } from 'react';

class MinddSnackbarStore {
  message: string = '';
  constructor() {
    makeAutoObservable(this);
  }

  add(message: string) {
    this.message = message;
  }

  clear() {
    this.message = '';
  }
}

const MinddSnackbarContext: React.Context<MinddSnackbarStore> = React.createContext<MinddSnackbarStore>(
  {} as MinddSnackbarStore
);

export const useMinddSnackbar = () => React.useContext(MinddSnackbarContext);

const MinddSnackbarProvider: FC<{ children: ReactNode }> = observer(({ children }) => {
  const store = React.useMemo(() => new MinddSnackbarStore(), []);

  React.useEffect(() => {}, []);

  return (
    <MinddSnackbarContext.Provider value={store}>
      {children}
      {store.message && (
        <Snackbar
          autoHideDuration={2000}
          open={true}
          variant={'solid'}
          sx={{ background: '#000000CC' }}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            store.clear();
          }}>
          {store.message}
        </Snackbar>
      )}
    </MinddSnackbarContext.Provider>
  );
});

export default MinddSnackbarProvider;
