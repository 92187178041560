import { Box, FormControl, FormLabel, Input, InputProps, Typography } from '@mui/joy';
import { FC } from 'react';

export interface PasswordInputProps extends InputProps {
  label: string;
  helperText?: string;
}

const PasswordInput: FC<PasswordInputProps> = ({ label, helperText, ...inputProps }) => {
  return (
    <Box>
      <FormControl orientation="horizontal" error={inputProps.error}>
        <FormLabel sx={{ minWidth: 100 }}>{label}: </FormLabel>
        <Input {...inputProps} />
      </FormControl>
      <Box pl={'120px'}>
        <Typography level="body-sm" color={inputProps.error ? 'danger' : 'neutral'}>
          {helperText}
        </Typography>
      </Box>
    </Box>
  );
};

export default PasswordInput;
