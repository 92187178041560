import Cookies from 'js-cookie';
import React from 'react';
import CookieUtils from '@MP/util/CookieUtils';
import CardStatusUtils, { CardStatus } from './CardStatusUtils';
import { useFetchCardInfo } from '@MP/api/surveyApi';
import { Alert, Box, IconButton } from '@mui/joy';
import { MdOutlineClose, MdWarning } from 'react-icons/md';

const COOKIE_CARD_STATUS_ALARM = 'card_status_snack_alarm';
const PaymentAlarmProvider = () => {
  const [alert, setAlert] = React.useState<'EXPIRED' | 'EXPIRED_SOON'>();
  const { data: cardInfo } = useFetchCardInfo();

  const remainingDay: number | undefined = React.useMemo(() => {
    if (cardInfo) {
      return CardStatusUtils.toRemainingDay(cardInfo);
    }
    return undefined;
  }, [cardInfo]);

  const cardStatus = React.useMemo(() => {
    if (cardInfo) {
      return CardStatusUtils.toStatus(cardInfo);
    }
    return undefined;
  }, [cardInfo]);

  React.useEffect(() => {
    if (!Cookies.get(COOKIE_CARD_STATUS_ALARM)) {
      if (cardStatus === CardStatus.EXPIRED) {
        CookieUtils.setOneDay(COOKIE_CARD_STATUS_ALARM);
        setAlert('EXPIRED');
      } else if (
        cardStatus === CardStatus.EXPIRING_SOON &&
        (remainingDay === 30 || remainingDay === 7 || remainingDay === 0)
      ) {
        CookieUtils.setOneDay(COOKIE_CARD_STATUS_ALARM);
        setAlert('EXPIRED_SOON');
      }
    }
  }, [remainingDay, cardStatus]);

  if (alert === 'EXPIRED') {
    return (
      <Box position={'fixed'} top={16} right={16} zIndex={5}>
        <Alert
          variant={'solid'}
          color="danger"
          startDecorator={<MdWarning fontSize={20} />}
          endDecorator={
            <IconButton onClick={() => setAlert(undefined)}>
              <MdOutlineClose />
            </IconButton>
          }>
          등록하신 결제 카드 사용 기한이 만료되었으니 재등록해주세요.
        </Alert>
      </Box>
    );
  }

  if (alert === 'EXPIRED_SOON') {
    return (
      <Box position={'fixed'} top={16} right={16} zIndex={5}>
        <Alert
          variant={'solid'}
          color="danger"
          startDecorator={<MdWarning fontSize={20} />}
          endDecorator={
            <IconButton onClick={() => setAlert(undefined)}>
              <MdOutlineClose />
            </IconButton>
          }>
          등록하신 결제 카드 만료일이 임박하였으니 확인해주세요!
        </Alert>
      </Box>
    );
  }
  return <></>;
};

export default PaymentAlarmProvider;
