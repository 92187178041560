import { ModuleState } from '@MP/api/socket/type';
import { ScanModule } from '../type';

export enum UsingKind {
  EEG = 'EEG',
  HRV = 'HRV',
  QUALITY = 'QUALITY',
}

const ModuleUtils = {
  isOffline: (module: ScanModule) => {
    return module.state.curr === ModuleState.OFFLINE;
  },
  isReady: (module: ScanModule) => {
    return module.state.curr === ModuleState.READY;
  },
  isRecording: (module: ScanModule) => {
    return module.state.curr === ModuleState.RECORDING;
  },
  isDisposing: (module: ScanModule) => {
    return module.state.curr === ModuleState.DISPOSING;
  },
  isIdle: (module: ScanModule) => {
    return module.state.curr === ModuleState.IDLE;
  },
  isSaving: (module: ScanModule) => {
    return module.state.curr === ModuleState.DATA_RETENTION;
  },
  isUsingKind: (module: ScanModule) => {
    if (module.transmission.hrv) {
      return UsingKind.HRV;
    } else if (module.transmission.eeg) {
      return UsingKind.EEG;
    } else if (module.transmission.quality) {
      return UsingKind.QUALITY;
    }
    return undefined;
  },
  isUsingHRV: (module: ScanModule) => {
    return ModuleUtils.isUsingKind(module) === UsingKind.HRV;
  },
  isUsingEEG: (module: ScanModule) => {
    return ModuleUtils.isUsingKind(module) === UsingKind.EEG;
  },
  isUsingQuality: (module: ScanModule) => {
    return ModuleUtils.isUsingKind(module) === UsingKind.QUALITY;
  },
  isUsing: (module: ScanModule) => {
    return !!module.userId;
  },
  isCanceledReady: (module: ScanModule) => {
    return module.state.prev === ModuleState.READY && module.state.curr === ModuleState.DISPOSING;
  },
  isFinishedRecording: (module: ScanModule) => {
    return module.state.prev === ModuleState.DATA_RETENTION;
  },
  isCanceledRecording: (module: ScanModule) => {
    return module.state.prev === ModuleState.RECORDING && module.state.curr === ModuleState.DISPOSING;
  },

  toStatusText(module: ScanModule) {
    if (ModuleUtils.isIdle(module)) {
      return '측정 대기 중';
    } else if (ModuleUtils.isReady(module)) {
      return `측정 준비 중(${ModuleUtils.isUsingHRV(module) ? 'HRV' : 'EEG'})`;
    } else if (ModuleUtils.isRecording(module)) {
      return `측정 진행 중(${ModuleUtils.isUsingHRV(module) ? 'HRV' : 'EEG'})`;
    } else if (ModuleUtils.isSaving(module)) {
      return `측정 데이터 저장 중(${ModuleUtils.isUsingHRV(module) ? 'HRV' : 'EEG'})`;
    } else if (ModuleUtils.isDisposing(module)) {
      return `데이터 정리 중(${module.disposingPercentage}%)`;
    } else {
      return '';
    }
  },
  toSensorText(module: ScanModule) {
    if (ModuleUtils.isUsingHRV(module)) {
      return 'HRV';
    } else if (ModuleUtils.isUsingEEG(module) || ModuleUtils.isUsingQuality(module)) {
      return `EEG`;
    } else {
      return '';
    }
  },
  toWifiSignalText: (value: any | undefined) => {
    // if (value === WifiSignalStrength.Excellent) {
    //   return '매우 좋음';
    // } else if (value === WifiSignalStrength.Good) {
    //   return '좋음';
    // } else if (value === WifiSignalStrength.Fair) {
    //   return '보통';
    // } else if (value === WifiSignalStrength.Weak) {
    //   return '약함';
    // } else if (value === WifiSignalStrength.Poor) {
    //   return '매우 약함';
    // }
    return '-';
  },
};

export default ModuleUtils;
