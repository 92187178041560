import { SubjectMeta } from '@MP/api/survey';
import { useDeletePatient, useFetchSleepSessions } from '@MP/api/surveyApi';
import AlertDialog from '@MP/component/dialog/AlertDialog';
import ConfirmDialog from '@MP/component/dialog/ConfirmDialog';
import Loading from '@MP/component/Loading';
import { useMinddSnackbar } from '@MP/component/MinddSnackbarProvider';
import MemberDeleteIcon from '@MP/icon/MemberDeleteIcon';
import SleepUtils from '@MP/util/SleepUtils';
import { Box, Typography } from '@mui/joy';
import { FC } from 'react';

const PatientDeleteConfirmDialog: FC<{
  user: SubjectMeta;
  onResult: (user: SubjectMeta, result: boolean) => void;
}> = ({ user, onResult }) => {
  const { mutateAsync: deletePatient, isPending } = useDeletePatient();
  const { data: session } = useFetchSleepSessions(user.id);
  const snackbar = useMinddSnackbar();

  const notEndSession = session?.filter((v) => !SleepUtils.isDone(v) && !SleepUtils.isCanceled(v));

  const handleDelete = async (result: boolean) => {
    if (result) {
      await deletePatient(user.id);
      snackbar.add(`'${user.name}' 환자 정보가 삭제되었습니다.`);
    }
    onResult(user, result);
  };

  if (!session) {
    return <Loading />;
  }

  if (notEndSession && notEndSession?.length > 0) {
    return (
      <AlertDialog
        open
        warning
        title="환자 정보 삭제"
        message={
          <Box textAlign={'center'}>
            <Typography sx={{ color: '#333' }}>
              {user.name}님의 진행 중인 <b>수면 리듬 양상 검사</b>가 있습니다. <br />
              진행 중인 검사를 중단하신 후, 환자 삭제 재시도 바랍니다.
            </Typography>
          </Box>
        }
        onClose={() => onResult(user, false)}
      />
    );
  }

  return (
    <ConfirmDialog
      open
      okColor="danger"
      loading={isPending}
      okText="삭제"
      title="환자 정보 삭제"
      icon={<MemberDeleteIcon width={48} />}
      message={
        <Box textAlign={'center'}>
          <Typography sx={{ color: '#333' }}>
            {user.name}님 환자 정보를 삭제하시겠습니까?
            <br />
            환자 삭제 시 측정 기록이 모두 삭제되며
            <br />
            복구가 불가능합니다.
          </Typography>
        </Box>
      }
      onResult={handleDelete}
    />
  );
};

export default PatientDeleteConfirmDialog;
