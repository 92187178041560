/* tslint:disable */
/* eslint-disable */
/**
 * MINDD Survey API Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Handedness = {
    None: 'NONE',
    Right: 'RIGHT',
    Left: 'LEFT',
    Ambidextrous: 'AMBIDEXTROUS'
} as const;

export type Handedness = typeof Handedness[keyof typeof Handedness];



