import React from 'react';
import FindPasswordStore, { FindPasswordStoreContext } from './FindPasswordStore';
import { Outlet } from 'react-router';

const FindPasswordPage = () => {
  const store = React.useMemo(() => new FindPasswordStore(), []);
  return (
    <FindPasswordStoreContext.Provider value={store}>
      <Outlet />
    </FindPasswordStoreContext.Provider>
  );
};

export default FindPasswordPage;
