import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode } from 'react';

import { useFetchCurrentUser } from '@MP/api/authApi';
import { useFetchAgreements, useFetchPatient, useFetchUsers } from '@MP/api/surveyApi';
import PatientStore from './PatientStore';
import usePatientSocket from './usePatientSocket';

export const PatientStoreContext: React.Context<PatientStore> = React.createContext<PatientStore>({} as PatientStore);

export const usePatientStore = () => React.useContext(PatientStoreContext);

const PatientProvider: FC<{ children: ReactNode }> = observer(({ children }) => {
  const store = React.useMemo(() => new PatientStore(), []);

  const { data: currentUser } = useFetchCurrentUser();
  const { mutateAsync: fetchPatient } = useFetchPatient();
  const { data: agreements, refetch } = useFetchAgreements(store.user?.id);

  const data = usePatientSocket(currentUser);
  const { data: usersData, hasNextPage, fetchNextPage } = useFetchUsers('');
  React.useEffect(() => {
    if (store && data.subjectMetaId) {
      if (data.deleted) {
        store.deleteUser(data.subjectMetaId);
      } else {
        const update = async () => {
          if (data.subjectMetaId === store.user?.id) {
            refetch();
          }

          const user = await fetchPatient(data.subjectMetaId!!);
          store.updateUser(user, data.editInfo);
        };

        update();
      }
    }
  }, [data, store, fetchPatient, refetch]);

  React.useEffect(() => {
    if (agreements) {
      store.setUserAgreements(agreements.subjectMetaId, agreements?.agreements);
    }
  }, [agreements, store]);

  React.useEffect(() => {
    const users = usersData?.pages || [];
    store.setUsers(users);
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [usersData, store, hasNextPage, fetchNextPage]);

  return <PatientStoreContext.Provider value={store}>{children}</PatientStoreContext.Provider>;
});

export default PatientProvider;
