import SignalFileReader from '@MP/util/signal/SignalFileReader';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import moment from 'moment';
import {
  ConfigControllerApi,
  EegQualityControllerApi,
  ModuleControllerApi,
  RecFileControllerApi,
  SensorControllerApi,
} from './scan/api';
import { SessionControllerApi } from './scan/api/session-controller-api';
import { RecFile, Type } from './scan/model';
import { ModuleStateChangeError } from './typs';

const sessionControllerApi = new SessionControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');
const qualityControllerApi = new EegQualityControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');
const sensorControllerApi = new SensorControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');
const configControllerApi = new ConfigControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');
const moduleControllerApi = new ModuleControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');
const recFileControllerApi = new RecFileControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');

const REC_HISTORY_KEY = 'rec_history';
const SIGNAL_FILE_KEY = 'signal_file';

export const useFetchModuleSerials = () => {
  return useQuery({
    queryKey: ['modules'],
    queryFn: () => moduleControllerApi.list().then((res) => res.data.map((v) => v.deviceSerial)),
  });
};

export const useLockSession = (type: Type) => {
  return useMutation<void, ModuleStateChangeError, { userId: string; deviceSerial: string }>({
    mutationFn: async ({ userId, deviceSerial }) => {
      try {
        await sessionControllerApi.createLock({ sensorType: type, deviceSerial: deviceSerial, subjectId: userId });
      } catch (e) {
        throw new ModuleStateChangeError(`Session Lock  실패`);
      }
    },
  });
};

export const useUnLockSession = () => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await sessionControllerApi.deleteLock(deviceSerial);
      } catch (e) {
        throw new ModuleStateChangeError(`Session UnLock  실패`);
      }
    },
  });
};

export const useStartEEG_SQI = () => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await qualityControllerApi.startSqi(deviceSerial);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 Start SQI 실패`);
      }
    },
  });
};

export const useStopEEG_SQI = () => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await qualityControllerApi.stopSqi(deviceSerial);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 STOP SQI 실패`);
      }
    },
  });
};

export const useStartSignal = (type: Type) => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await sensorControllerApi.startTransmission(deviceSerial, type);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 Start 실패`);
      }
    },
  });
};

export const useStopSignal = (type: Type) => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await sensorControllerApi.stopTransmission(deviceSerial, type);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 STOP 실패`);
      }
    },
  });
};

export const useStartRecording = () => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await sensorControllerApi.startRecording(deviceSerial);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 START RECORDING 실패`);
      }
    },
  });
};

export const useStopRecording = (type: Type) => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string; save: boolean }>({
    mutationFn: async ({ deviceSerial, save }) => {
      try {
        await sensorControllerApi.stopRecording(deviceSerial, type, save);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 STOP RECORDING 실패`);
      }
    },
  });
};

export const useFetchSignalFile = (id: string = '') => {
  return useQuery({
    queryKey: [SIGNAL_FILE_KEY, id],
    queryFn: async () => {
      if (id) {
        const res = await recFileControllerApi.downloadFile(id!!, { responseType: 'blob' });
        const blob = res.data as unknown as Blob;
        const buffer = await blob.arrayBuffer();
        return new SignalFileReader(buffer).read();
      }
      return null;
    },
    gcTime: 0,
    placeholderData: () => undefined,
  });
};

export const useUploadSignalFile = () => {
  return useMutation<any, AxiosError, { id: string; fileName: string }>({
    mutationFn: async ({ id }) => {
      // return recFileControllerApi.uploadFile()
    },
  });
};

export const useDownSignalFile = () => {
  return useMutation<any, AxiosError, { id: string; fileName: string }>({
    mutationFn: async ({ id, fileName }) => {
      const res = await recFileControllerApi.downloadFile(id, { responseType: 'blob' });
      const url = window.URL.createObjectURL(res.data as unknown as Blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${fileName}.edf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
  });
};

export const useFetchEdfFiles = (subjectId: string = '', type: Type, enabled: boolean = true) => {
  return useQuery<RecFile[]>({
    queryKey: [REC_HISTORY_KEY, subjectId, type],
    queryFn: () => {
      return recFileControllerApi
        .searchFiles(subjectId, type)
        .then((res) => res.data.sort((a, b) => moment(b.recordingEndTime).diff(a.recordingEndTime)));
    },
    enabled: !!subjectId && enabled,
    gcTime: 0,
    placeholderData: () => undefined,
  });
};

export const useDeleteEdfFile = () => {
  const client = useQueryClient();
  return useMutation<any, AxiosError, { ids: string[]; subjectId: string; type: Type }>({
    mutationFn: ({ ids }) => recFileControllerApi.deleteFiles({ ids }),
    onSuccess: (_, { subjectId, type }) => client.invalidateQueries({ queryKey: [REC_HISTORY_KEY, subjectId, type] }),
  });
};

export const useFetchScanSetting = () => {
  return useQuery({
    queryKey: ['scan_config'],
    queryFn: () => configControllerApi.getConfig().then((res) => res.data),
    gcTime: 0,
  });
};
