/* tslint:disable */
/* eslint-disable */
/**
 * MINDD SCAN2 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { RequestLock } from '../model';
// @ts-ignore
import type { SessionInfo } from '../model';
/**
 * SessionControllerApi - axios parameter creator
 * @export
 */
export const SessionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Session을 생성합니다. Session은 하나의 디바이스에 대한 세션을 의미합니다. Session 생성 시, 디바이스의 센서 설정 및 세션 생성이 이루어집니다.
         * @summary Session 생성
         * @param {RequestLock} requestLock 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLock: async (requestLock: RequestLock, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestLock' is not null or undefined
            assertParamExists('createLock', 'requestLock', requestLock)
            const localVarPath = `/sessions/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestLock, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLock: async (deviceSerial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSerial' is not null or undefined
            assertParamExists('deleteLock', 'deviceSerial', deviceSerial)
            const localVarPath = `/sessions/{deviceSerial}/lock`
                .replace(`{${"deviceSerial"}}`, encodeURIComponent(String(deviceSerial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession: async (deviceSerial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSerial' is not null or undefined
            assertParamExists('getSession', 'deviceSerial', deviceSerial)
            const localVarPath = `/sessions/{deviceSerial}`
                .replace(`{${"deviceSerial"}}`, encodeURIComponent(String(deviceSerial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSessions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *          Session 상태를 요청합니다. 상태는 WebSocket(org)을 통해 전달됩니다.         WebSocket 메시지 형식:                  {             \"deviceSerial\": \"serial\",             \"payload\": {                 \"prevState\": \"IDLE\",                 \"curState\": \"IDLE\",                 \"reason\": \"NA\"             },             \"type\": \"SESSION_STATE_TRANSITION\"         }     
         * @summary Session 상태 요청
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestState: async (deviceSerial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSerial' is not null or undefined
            assertParamExists('requestState', 'deviceSerial', deviceSerial)
            const localVarPath = `/sessions/{deviceSerial}/state/request`
                .replace(`{${"deviceSerial"}}`, encodeURIComponent(String(deviceSerial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionControllerApi - functional programming interface
 * @export
 */
export const SessionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Session을 생성합니다. Session은 하나의 디바이스에 대한 세션을 의미합니다. Session 생성 시, 디바이스의 센서 설정 및 세션 생성이 이루어집니다.
         * @summary Session 생성
         * @param {RequestLock} requestLock 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLock(requestLock: RequestLock, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLock(requestLock, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SessionControllerApi.createLock']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLock(deviceSerial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLock(deviceSerial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SessionControllerApi.deleteLock']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSession(deviceSerial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSession(deviceSerial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SessionControllerApi.getSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSessions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SessionInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSessions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SessionControllerApi.listSessions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *          Session 상태를 요청합니다. 상태는 WebSocket(org)을 통해 전달됩니다.         WebSocket 메시지 형식:                  {             \"deviceSerial\": \"serial\",             \"payload\": {                 \"prevState\": \"IDLE\",                 \"curState\": \"IDLE\",                 \"reason\": \"NA\"             },             \"type\": \"SESSION_STATE_TRANSITION\"         }     
         * @summary Session 상태 요청
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestState(deviceSerial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestState(deviceSerial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SessionControllerApi.requestState']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SessionControllerApi - factory interface
 * @export
 */
export const SessionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionControllerApiFp(configuration)
    return {
        /**
         * Session을 생성합니다. Session은 하나의 디바이스에 대한 세션을 의미합니다. Session 생성 시, 디바이스의 센서 설정 및 세션 생성이 이루어집니다.
         * @summary Session 생성
         * @param {RequestLock} requestLock 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLock(requestLock: RequestLock, options?: any): AxiosPromise<void> {
            return localVarFp.createLock(requestLock, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLock(deviceSerial: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLock(deviceSerial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(deviceSerial: string, options?: any): AxiosPromise<SessionInfo> {
            return localVarFp.getSession(deviceSerial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSessions(options?: any): AxiosPromise<Array<SessionInfo>> {
            return localVarFp.listSessions(options).then((request) => request(axios, basePath));
        },
        /**
         *          Session 상태를 요청합니다. 상태는 WebSocket(org)을 통해 전달됩니다.         WebSocket 메시지 형식:                  {             \"deviceSerial\": \"serial\",             \"payload\": {                 \"prevState\": \"IDLE\",                 \"curState\": \"IDLE\",                 \"reason\": \"NA\"             },             \"type\": \"SESSION_STATE_TRANSITION\"         }     
         * @summary Session 상태 요청
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestState(deviceSerial: string, options?: any): AxiosPromise<void> {
            return localVarFp.requestState(deviceSerial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionControllerApi - object-oriented interface
 * @export
 * @class SessionControllerApi
 * @extends {BaseAPI}
 */
export class SessionControllerApi extends BaseAPI {
    /**
     * Session을 생성합니다. Session은 하나의 디바이스에 대한 세션을 의미합니다. Session 생성 시, 디바이스의 센서 설정 및 세션 생성이 이루어집니다.
     * @summary Session 생성
     * @param {RequestLock} requestLock 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public createLock(requestLock: RequestLock, options?: RawAxiosRequestConfig) {
        return SessionControllerApiFp(this.configuration).createLock(requestLock, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceSerial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public deleteLock(deviceSerial: string, options?: RawAxiosRequestConfig) {
        return SessionControllerApiFp(this.configuration).deleteLock(deviceSerial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceSerial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public getSession(deviceSerial: string, options?: RawAxiosRequestConfig) {
        return SessionControllerApiFp(this.configuration).getSession(deviceSerial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public listSessions(options?: RawAxiosRequestConfig) {
        return SessionControllerApiFp(this.configuration).listSessions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *          Session 상태를 요청합니다. 상태는 WebSocket(org)을 통해 전달됩니다.         WebSocket 메시지 형식:                  {             \"deviceSerial\": \"serial\",             \"payload\": {                 \"prevState\": \"IDLE\",                 \"curState\": \"IDLE\",                 \"reason\": \"NA\"             },             \"type\": \"SESSION_STATE_TRANSITION\"         }     
     * @summary Session 상태 요청
     * @param {string} deviceSerial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public requestState(deviceSerial: string, options?: RawAxiosRequestConfig) {
        return SessionControllerApiFp(this.configuration).requestState(deviceSerial, options).then((request) => request(this.axios, this.basePath));
    }
}

