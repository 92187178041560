import WebTokenManger from '@MP/util/WebTokenManger';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { SessionInfo } from '../scan';
import { ModuleSocketClient, ModuleSocketImpl } from './scanModuleSocket';
import { OrgSocketClient, OrgSocketImpl } from './scanOrgSocket';
import { EEGPrepareInfo, ModuleState, SignalInfo, SubscribeId } from './type';

class MockModuleSocketClient implements ModuleSocketClient {
  get connected() {
    return false;
  }
  subscribeOnSignal(callback: (data: SignalInfo) => void) {
    return 1;
  }
  subscribeOnSQI(callback: (data: EEGPrepareInfo) => void) {
    return 1;
  }
  unsubscribe(id: SubscribeId) {}
  disconnect() {}
}

class MockOrgSocketClient implements OrgSocketClient {
  get connected() {
    return false;
  }
  subscribeOnBattery(callback: (deviceSerial: string, percentage: number, isCharging: boolean) => void) {
    return 1;
  }
  subscribeOnState(callback: (deviceSerial: string, prev: ModuleState, state: ModuleState) => void) {
    return 1;
  }
  subscribeOnCreatedRec(callback: (subjectMetaIds: string[]) => void) {
    return 1;
  }
  subscribeOnDeletedRec(callback: (subjectMetaIds: string[]) => void) {
    return 1;
  }
  subscribeOnDisposeProgress(callback: (deviceSerial: string, progress: number) => void) {
    return 1;
  }
  subscribeOnCleanTime(callback: (deviceSerial: string, ms: number) => void) {
    return 1;
  }
  subscribeOnSensorTransmission(callback: (deviceSerial: string, eeg: boolean, hrv: boolean) => void) {
    return 1;
  }
  subscribeOnQualityTransmission(callback: (deviceSerial: string, result: boolean) => void) {
    return 1;
  }
  subscribeOnRegistration(callback: (deviceSerial: string) => void) {
    return 1;
  }
  subscribeOnDisconnected(callback: (deviceSerial: string) => void) {
    return 1;
  }
  subscribeOnSession(callback: (deviceSerial: string, info: SessionInfo) => void) {
    return 1;
  }
  unsubscribe(id: SubscribeId) {}
  disconnect() {}
}

let orgSocket: OrgSocketImpl | undefined;

// TODO: SCAN 오픈 전까지 임시 MOCK 처리
export const ScanSocket = {
  connectModule: (deviceSerial: string): ModuleSocketClient => {
    return new ModuleSocketImpl(
      new W3CWebSocket(
        `wss://scan2-api.dev.ybrainlab.com/ws/device/${deviceSerial}?Authorization=Bearer ${WebTokenManger.getToken()}`
      )
    );
    // return new MockModuleSocketClient();
  },
  connectOrg: (): Promise<OrgSocketClient> => {
    return new Promise((resolve) => {
      if (!orgSocket) {
        orgSocket = new OrgSocketImpl(
          new W3CWebSocket(
            `wss://scan2-api.dev.ybrainlab.com/ws/org/?Authorization=Bearer ${WebTokenManger.getToken()}`
          )
        );
      }
      setInterval(() => {
        if (orgSocket?.connected) {
          resolve(orgSocket);
        }
      }, 100);
    });

    // return new MockOrgSocketClient();
  },
  disconnectOrg: () => {
    if (orgSocket) {
      orgSocket.disconnect();
      orgSocket = undefined;
    }
  },
};
