import MinddSnackbarProvider from '@MP/component/MinddSnackbarProvider';
import theme from '@MP/theme';
import WebTokenManger from '@MP/util/WebTokenManger';
import { CssBaseline, CssVarsProvider } from '@mui/joy';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ko from 'javascript-time-ago/locale/ko';
import 'moment/locale/ko';
import TimeAgo from 'javascript-time-ago';
import 'pretendard/dist/web/static/pretendard.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import moment from 'moment';

moment.locale('ko');
TimeAgo.addDefaultLocale(ko);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: unknown) => {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401 || axiosError.response?.status === 404) {
          return false;
        } else {
          return failureCount < 2;
        }
      },
      gcTime: 1000 * 60 * 60 * 3,
      staleTime: 1000 * 60 * 60 * 3,
      placeholderData: (previousData: any) => previousData,
    },
  },
  queryCache: new QueryCache({
    onError: (error: unknown) => {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        switch (axiosError.response.status) {
          case 401:
            if (!window.location.pathname.endsWith('/')) {
              // 로그인 페이지로 리다이렉트
              WebTokenManger.clear();
              window.location.reload();
            }
            break;
          default:
            break;
        }
      }
    },
  }),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <MinddSnackbarProvider>
          <App />
        </MinddSnackbarProvider>
      </CssVarsProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
