import { Agreement, AgreementStatus, Gender, Handedness, ServiceType, SubjectMeta } from '@MP/api/survey';
import BirthUtils from '@MP/util/BirthUtils';
import PhoneNumberUtils from '@MP/util/PhoneNumberUtils';
import ReplaceUtils from '@MP/util/ReplaceUtils';
import { makeAutoObservable } from 'mobx';

const toString = (value: number) => {
  return value.toString().padStart(2, '0');
};

export interface AgreementStatusItem {
  type: ServiceType;
  agreedTime?: string;
  status: AgreementStatus;
}

export class PatientEditFormData {
  id: string;
  originCode: string;
  validCode: boolean | undefined;

  name: string;
  code: string;
  phoneNumber: string;
  birth: string = '';
  handedness: Handedness;
  gender?: Gender;
  agreement: ServiceType[] = [];
  agreed: Agreement[] = [];

  constructor(user: SubjectMeta) {
    makeAutoObservable(this);
    this.id = user.id;
    this.name = user.name;
    this.originCode = user.code;
    this.code = user.code;
    this.validCode = true;
    this.phoneNumber = user.phoneNumber || '';
    this.handedness = user.handedness || 'NONE';
    this.gender = user.gender;

    if (user.birthYear) {
      this.birth = `${user?.birthYear}${toString(user?.birthMonth || 0)}${toString(user?.birthDay || 0)}`;
    }
  }

  setAgreementsStatus(agreements?: Agreement[]) {
    this.agreed = agreements || [];
  }

  setExistsCode(exists: boolean) {
    this.validCode = !exists;
  }

  setCode(code: string) {
    this.code = code.trim();
    if (this.code !== this.originCode) {
      this.validCode = undefined;
    } else {
      this.validCode = true;
    }
  }
  setName(name: string) {
    this.name = name.trim();
  }
  setBirth(birth: string) {
    this.birth = ReplaceUtils.replaceNumber(birth);
  }
  setPhoneNumber(phoneNumber: string) {
    this.phoneNumber = ReplaceUtils.replaceNumber(phoneNumber);
  }
  setGender(gender: Gender) {
    this.gender = gender;
  }

  setHandedness(handedness: Handedness) {
    this.handedness = handedness;
  }

  validateBirth() {
    return BirthUtils.isValid(this.birth);
  }

  setAgreement(agreement: ServiceType[]) {
    this.agreement = agreement;
  }

  /**
   * phoneNumber를 입력하지 않은 경우는 true
   * @returns
   */
  validatePhoneNumber() {
    if (this.phoneNumber) {
      return PhoneNumberUtils.isValid(this.phoneNumber);
    }
    return true;
  }

  get agreedTypes() {
    return this.agreed.map((v) => v.service);
  }

  get isValidUser() {
    return this.code && this.name && this.validateBirth() && this.validatePhoneNumber();
  }

  get isValid() {
    return (
      this.validCode &&
      this.code &&
      this.name &&
      this.validateBirth() &&
      this.validatePhoneNumber() &&
      this.agreement.length > 0
    );
  }
}

export class PatientAddFormData {
  orgId: number;
  code: string = '';
  name: string = '';
  phoneNumber: string = '';
  birth: string = '';
  requiredPhoneNumber: boolean;
  validCode: boolean | undefined;
  checkCode: boolean;
  constructor(orgId: number, checkCode: boolean, requiredPhoneNumber?: boolean) {
    makeAutoObservable(this);
    this.orgId = orgId;
    this.checkCode = checkCode || false;
    this.requiredPhoneNumber = requiredPhoneNumber || false;
  }

  setExistsCode(exists: boolean) {
    this.validCode = !exists;
  }
  setCode(code: string) {
    this.code = code.trim();
    this.validCode = undefined;
  }
  setName(name: string) {
    this.name = name.trim();
  }
  setBirth(birth: string) {
    if (birth.length <= 8) {
      this.birth = ReplaceUtils.replaceNumber(birth);
    }
  }
  setPhoneNumber(phoneNumber: string) {
    if (phoneNumber.length <= 11) {
      this.phoneNumber = ReplaceUtils.replaceNumber(phoneNumber);
    }
  }

  validateBirth() {
    return BirthUtils.isValid(this.birth);
  }

  validatePhoneNumber() {
    if (this.phoneNumber) {
      return PhoneNumberUtils.isValid(this.phoneNumber);
    }

    return this.requiredPhoneNumber ? false : true;
  }

  get isValid() {
    if (this.checkCode) {
      return !!this.name && this.validateBirth() && this.validatePhoneNumber() && !!this.code && !!this.validCode;
    } else {
      return !!this.name && this.validateBirth() && this.validatePhoneNumber();
    }
  }
}
