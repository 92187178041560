import { Handedness } from '@MP/api/survey';

const HandednessUtils = {
  displayName: (handedness?: Handedness | null) => {
    switch (handedness) {
      case Handedness.Left:
        return '왼손';
      case Handedness.Right:
        return '오른손';
      case Handedness.Ambidextrous:
        return '양손';
      default:
        return '';
    }
  },
};

export default HandednessUtils;
