import { convert, DateTimeFormatter, LocalDateTime, ZonedDateTime, ZoneId } from '@js-joda/core';

export enum DateType {
  LocalDateTime,
  ZonedDateTime,
}
const DateUtils = {
  to_mmss: (second: number) => {
    return `${Math.floor(second / 60)
      .toString()
      .padStart(2, '0')}:${Math.floor(second % 60)
      .toString()
      .padStart(2, '0')}`;
  },
  toDatetime: (date: string, type: DateType = DateType.ZonedDateTime) => {
    let dateTime = DateUtils.parse(date, type);
    return dateTime.format(DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm:ss'));
  },

  toDate: (date: string) => {
    const zonedDateTime = ZonedDateTime.parse(date).withZoneSameInstant(ZoneId.SYSTEM);
    return zonedDateTime.format(DateTimeFormatter.ofPattern('yyyy-MM-dd'));
  },

  toEpochSecond: (date: string) => {
    const zonedDateTime = ZonedDateTime.parse(date).withZoneSameInstant(ZoneId.SYSTEM);
    return zonedDateTime.toEpochSecond();
  },

  toInstant: (str?: string) => ZonedDateTime.parse(str ? str : '').toInstant(),

  nowDateTime: () => ZonedDateTime.now().toInstant().toString(),

  toNativeDate: (date: string) => {
    const zonedDateTime = ZonedDateTime.parse(date).withZoneSameInstant(ZoneId.SYSTEM);
    return convert(zonedDateTime).toDate();
  },

  compare: (a: string, b: string, type: DateType = DateType.ZonedDateTime) => {
    if (type === DateType.ZonedDateTime) {
      return ZonedDateTime.parse(a)
        .withZoneSameInstant(ZoneId.SYSTEM)
        .compareTo(ZonedDateTime.parse(b).withZoneSameInstant(ZoneId.SYSTEM));
    } else {
      return LocalDateTime.parse(a).compareTo(LocalDateTime.parse(b));
    }
  },

  parse: (date: string, type: DateType = DateType.ZonedDateTime) => {
    if (type === DateType.ZonedDateTime) {
      return ZonedDateTime.parse(date).withZoneSameInstant(ZoneId.SYSTEM);
    } else {
      return LocalDateTime.parse(date);
    }
  },
};

export default DateUtils;
