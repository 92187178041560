import DropDownIcon from '@MP/icon/DropDownIcon';
import { extendTheme } from '@mui/joy/styles';
import { FaCheck } from 'react-icons/fa6';

declare module '@mui/joy/styles' {
  interface Palette {
    white: {
      primary: string;
    };
  }
}

const theme = extendTheme({
  fontFamily: {
    body: "'Pretendard','Pretendard Variable'",
    display: "'Pretendard','Pretendard Variable'",
    code: "'Pretendard','Pretendard Variable'",
    fallback: "'Pretendard','Pretendard Variable'",
  },
  components: {
    JoyFormControl: {
      styleOverrides: {
        root: {
          '--FormLabel-asteriskColor': '#26A7E3',
        },
      },
    },
    JoyRadio: {
      styleOverrides: {
        root: {
          '--joy-fontSize-md': '14px',
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: '#FFF',
        },
        input: {
          '::placeholder': { fontSize: 16, color: '#999' },
          fontSize: 14,
        },
      },
    },
    JoyModal: {
      defaultProps: {
        slotProps: {
          backdrop: {
            sx: {
              background: '#000',
              opacity: 0.4,
              backdropFilter: 'none',
            },
          },
        },
      },
    },
    JoySelect: {
      defaultProps: {
        color: 'neutral',
        indicator: <DropDownIcon />,
        slotProps: { button: { sx: { fontSize: 14 } }, listbox: { sx: { fontSize: 14, maxHeight: 200 } } },
      },
    },
    JoySnackbar: {
      defaultProps: {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        startDecorator: <FaCheck color="#FFF" />,
        autoHideDuration: 2000,
        sx: {
          background: 'rgba(0, 0, 0, 0.80)',
          color: '#FFF',
          minWidth: '0px',
          borderRadius: '8px',
          p: '16px 24px',
        },
      },
    },
  },
  colorSchemes: {
    light: {
      palette: {
        background: {
          surface: '#FFF',
        },
        text: { primary: '#333', secondary: '#666', tertiary: '#333', icon: '#333' },
        danger: {
          500: '#DE3232',
        },
        neutral: {
          softColor: '#FFF',
          solidColor: '#FFF',
          100: '#f1f1f1',
          200: '#ebe7e7',
          300: '#b5b2b2',
          400: '#9b9898',
          500: '#333',
          600: '#232323',
          700: '#151515',
          800: '#101010',
          900: '#000',
          outlinedBorder: '#EDEDED',
        },
        primary: {
          50: '#ecf7fb',
          100: '#e0f1f9',
          200: '#c9e9f8',
          300: '#95d2ee',
          400: '#5ecffc',
          500: '#26A7E3',
          600: '#16709b',
          700: '#115777',
          800: '#092d3d',
          900: '#04151d',
        },
        divider: '#EDEDED',
      },
    },
  },
});

export default theme;
