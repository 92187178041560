import { ChannelHeaderField } from './type';

// 단위는 byte

export const ID_PREFIX_BYTE = 1;
export const ID_POSTFIX_BYTE = 7;
export const ID_BYTE = ID_PREFIX_BYTE + ID_POSTFIX_BYTE;

export const SUBJECT_ID_BYTE = 80;
export const RECORDING_ID_BYTE = 80;

export const START_DATE_BYTE = 8;
export const START_TIME_BYTE = 8;

export const ALL_HEADER_SIZE_BYTE = 8;

export const VERSION_FORMAT_BYTE = 44;

export const RECORD_COUNT_BYTE = 8;

export const RECORD_INTERVAL_SECOND_BYTE = 8;

export const CHANNEL_COUNT_BYTE = 4;

export const CHANNEL_LABEL_BYTE = 16;
export const CHANNEL_LABEL_NAME = 'label';

export const TRANSDUCER_TYPE_BYTE = 80;
export const TRANSDUCER_TYPE_NAME = 'transducer';

export const PHYSICAL_DIMENSION_BYTE = 8;
export const PHYSICAL_DIMENSION_NAME = 'physicalDimensions';

export const PHYSICAL_MIN_BYTE = 8;
export const PHYSICAL_MIN_NAME = 'physicalMin';

export const PHYSICAL_MAX_BYTE = 8;
export const PHYSICAL_MAX_NAME = 'physicalMax';

export const DIGITAL_MIN_BYTE = 8;
export const DIGITAL_MIN_NAME = 'digitalMin';

export const DIGITAL_MAX_BYTE = 8;
export const DIGITAL_MAX_NAME = 'digitalMax';

export const PREFILTERING_BYTE = 80;
export const PREFILTERING_NAME = 'preFiltering';

export const SAMPLE_COUNT_BYTE = 8;
export const SAMPLE_COUNT_NAME = 'samples';

export const RESERVED_BYTE = 32;
export const RESERVED_NAME = 'reserved';

/** 순서 중요함 */
export const CHANNEL_HEADER_FIELDS: ChannelHeaderField[] = [
  {
    name: CHANNEL_LABEL_NAME,
    length: CHANNEL_LABEL_BYTE,
  },
  {
    name: TRANSDUCER_TYPE_NAME,
    length: TRANSDUCER_TYPE_BYTE,
  },
  {
    name: PHYSICAL_DIMENSION_NAME,
    length: PHYSICAL_DIMENSION_BYTE,
  },
  {
    name: PHYSICAL_MIN_NAME,
    length: PHYSICAL_MIN_BYTE,
  },
  {
    name: PHYSICAL_MAX_NAME,
    length: PHYSICAL_MAX_BYTE,
  },
  {
    name: DIGITAL_MIN_NAME,
    length: DIGITAL_MIN_BYTE,
  },
  {
    name: DIGITAL_MAX_NAME,
    length: DIGITAL_MAX_BYTE,
  },
  {
    name: PREFILTERING_NAME,
    length: PREFILTERING_BYTE,
  },
  {
    name: SAMPLE_COUNT_NAME,
    length: SAMPLE_COUNT_BYTE,
  },
  {
    name: RESERVED_NAME,
    length: RESERVED_BYTE,
  },
];

export const FILE_HEADER_BYTE =
  ID_BYTE +
  SUBJECT_ID_BYTE +
  RECORDING_ID_BYTE +
  START_DATE_BYTE +
  START_TIME_BYTE +
  ALL_HEADER_SIZE_BYTE +
  VERSION_FORMAT_BYTE +
  RECORD_COUNT_BYTE +
  RECORD_INTERVAL_SECOND_BYTE +
  CHANNEL_COUNT_BYTE;

export const CHANNEL_HEADER_BYTE = CHANNEL_HEADER_FIELDS.reduce((acc, curr) => acc + curr.length, 0);

export const BDF_RECORD_DATA_BYTE = 3;
export const BDF_ID_PREFIX = 255;
export const BDF_ID_POSTFIX = 'BIOSEMI';
export const BDF_ID_FULL_TEXT = `${BDF_ID_PREFIX}${BDF_ID_POSTFIX}`;

export const EDF_RECORD_DATA_BYTE = 2;
export const EDF_ID_PREFIX = 0;
export const EDF_ID_POSTFIX = '';
