import { FC } from 'react';
import { ic_sleep, ic_sleep_active } from '.';

const SleepMenuIcon: FC<{ width?: number; primary?: boolean }> = ({ width = 24, primary }) => {
  if (primary) {
    return <img src={ic_sleep_active} width={width} alt="" />;
  }
  return <img src={ic_sleep} width={width} alt="" />;
};

export default SleepMenuIcon;
