import { useFetchOrganizationOfCurrentUser } from '@MP/api/authApi';
import { Handedness, LastSession, SubjectMeta } from '@MP/api/survey';
import { RectangleButton } from '@MP/component/Button';
import NewPatientQRDialog from '@MP/feature/patient/NewPatientQRDialog';
import PatientAddDialog from '@MP/feature/patient/PatientAddDialog';
import { usePatientStore } from '@MP/feature/patient/PatientProvider';
import { useModuleStore } from '@MP/feature/scan/ScanModuleProvider';
import ModuleUtils from '@MP/feature/scan/utils/module-utils';
import GenderUtils from '@MP/util/GenderUtils';
import HandednessUtils from '@MP/util/HandednessUtils';
import PhoneNumberUtils from '@MP/util/PhoneNumberUtils';
import { Box, Card, CardContent, Divider, Input, Stack, Tooltip, Typography } from '@mui/joy';
import { useVirtualizer } from '@tanstack/react-virtual';
import TimeAgo from 'javascript-time-ago';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import React, { FC, useDeferredValue } from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { HiOutlineSearch } from 'react-icons/hi';
import { TbQrcode } from 'react-icons/tb';

const PatientLeftContents = observer(() => {
  const [filter, setFilter] = React.useState('');
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openQR, setOpenQR] = React.useState(false);
  const patientStore = usePatientStore();
  const moduleStore = useModuleStore();
  const { data: org } = useFetchOrganizationOfCurrentUser();

  const parentRef = React.useRef(null);

  const deferredFilter = useDeferredValue(filter);

  const filteredUsers = React.useMemo(() => {
    return patientStore.users.filter((user) => {
      return user.name.includes(deferredFilter) || user.phoneNumber?.includes(deferredFilter);
    });
  }, [patientStore.users, deferredFilter]);

  const handleChange = (user: SubjectMeta) => {
    patientStore.setUser(user);
  };

  const rowVirtualizer = useVirtualizer({
    count: filteredUsers.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 120,
  });

  return (
    <Stack sx={{ px: '16px', py: '20px', width: 304, maxWidth: 304, bgcolor: 'common.white', height: '100%' }}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#333' }}>환자 관리</Typography>
      </Stack>
      <Stack direction={'row'} spacing={'16px'} my={'12px'}>
        <RectangleButton
          variant="outlined"
          startDecorator={<TbQrcode />}
          color="neutral"
          sx={{ height: 40, minWidth: 130 }}
          onClick={() => setOpenQR(true)}>
          <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>QR 코드</Typography>
        </RectangleButton>
        <RectangleButton
          variant="outlined"
          startDecorator={<AiOutlineUserAdd />}
          color="neutral"
          sx={{ height: 40, minWidth: 130 }}
          onClick={() => setOpenAdd(true)}>
          <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>환자 추가</Typography>
        </RectangleButton>
      </Stack>
      <Divider sx={{ mb: '12px' }} />
      <Box sx={{ mb: '20px' }}>
        <Input
          value={filter}
          startDecorator={<HiOutlineSearch />}
          placeholder="이름, 전화번호로 검색"
          sx={{ height: '48px' }}
          onChange={(e) => setFilter(e.target.value)}
        />
      </Box>
      <Box ref={parentRef} sx={{ flexGrow: 1, mx: '-16px', px: '16px', overflow: 'auto', minWidth: 310 }}>
        <Box sx={{ height: `${rowVirtualizer.getTotalSize()}px`, position: 'relative' }}>
          {rowVirtualizer.getVirtualItems().map((virtualItem) => {
            const user = filteredUsers[virtualItem.index];

            const module = moduleStore.getModuleByUser(user.id);
            return (
              <Box
                key={user.id}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}>
                <Card
                  sx={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    bgcolor: 'common.white',
                    borderColor: user.id === patientStore.user?.id ? 'primary.500' : undefined,
                    mb: '12px',
                  }}>
                  <CardContent sx={{ rowGap: 0 }} onClick={() => handleChange(user)}>
                    <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: '8px' }} spacing={'16px'}>
                      <Tooltip title={`${user.code}`}>
                        <Typography sx={{ fontSize: '13px', color: '#848484' }} noWrap>
                          [{user.code}]
                        </Typography>
                      </Tooltip>

                      {module && (
                        <Typography sx={{ fontSize: '13px', color: 'red' }}>
                          {`${ModuleUtils.toSensorText(module)} 사용 중`}
                        </Typography>
                      )}

                      {!module && <LastSessionStatus session={user.lastSession} />}
                    </Stack>
                    <Box>
                      <Tooltip title={`${user.name}`}>
                        <Typography sx={{ fontSize: '18px', fontWeight: '600' }} noWrap>
                          {user.name}
                        </Typography>
                      </Tooltip>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        divider={<Divider orientation="vertical" sx={{ height: '10px', alignSelf: 'center' }} />}
                        spacing={'4px'}>
                        <Typography sx={{ fontSize: '13px' }}>{user.birthYear}년생</Typography>
                        {user.gender && (
                          <Typography sx={{ fontSize: '13px' }}>{GenderUtils.displayName(user.gender)}</Typography>
                        )}
                        {user.phoneNumber && (
                          <Typography sx={{ fontSize: '13px' }}>
                            {PhoneNumberUtils.appendDash(user.phoneNumber)}
                          </Typography>
                        )}
                        {user.handedness !== Handedness.None && (
                          <Typography sx={{ fontSize: '13px' }}>
                            {HandednessUtils.displayName(user.handedness)}
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            );
          })}
        </Box>
      </Box>
      {org?.id && <PatientAddDialog open={openAdd} orgId={org.id} onClose={() => setOpenAdd(false)} />}
      <NewPatientQRDialog open={openQR} onClose={() => setOpenQR(false)} />
    </Stack>
  );
});

const LastSessionStatus: FC<{ session?: LastSession }> = ({ session }) => {
  if (session?.status === 'RESPONSE') {
    return <TimeAgoLabel date={moment(session.lastAnswerTime)} postPix={'설문 응답'} />;
  }
  if (session?.status === 'NO_RESPONSE') {
    return <TimeAgoLabel date={moment(session.lastAnswerTime)} postPix={'설문 발송'} />;
  }
  if (session?.status === 'IN_PROGRESS') {
    return <TimeAgoLabel date={moment(session.lastAnswerTime)} postPix={'설문 응답중'} />;
  }

  return <></>;
};

const TimeAgoLabel: FC<{ date?: Moment; postPix: string }> = ({ date, postPix }) => {
  const today = moment();
  const timeAgo = new TimeAgo('ko');
  if (date && today.year() === date.year()) {
    const label = `${timeAgo.format(date.toDate())} ${postPix}`;
    return (
      <Tooltip title={label}>
        <Typography level="body-sm" sx={{ color: '#666', textOverflow: 'ellipsis' }} noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  }

  if (date) {
    const label = `${moment(date).format('YY년 MM월')} ${postPix}`;
    return (
      <Tooltip title={label}>
        <Typography level="body-sm" sx={{ color: '#666', textOverflow: 'ellipsis' }} noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  }

  return <></>;
};

export default PatientLeftContents;
