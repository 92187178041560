/* tslint:disable */
/* eslint-disable */
/**
 * MINDD Survey API Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { SubjectMeta } from '../model';
// @ts-ignore
import type { SubjectMetaCreateRequest } from '../model';
// @ts-ignore
import type { SubjectMetaPage } from '../model';
// @ts-ignore
import type { SubjectMetaUpdateRequest } from '../model';
/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {SubjectMetaCreateRequest} subjectMetaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserFromBody: async (code: string, subjectMetaCreateRequest: SubjectMetaCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('createUserFromBody', 'code', code)
            // verify required parameter 'subjectMetaCreateRequest' is not null or undefined
            assertParamExists('createUserFromBody', 'subjectMetaCreateRequest', subjectMetaCreateRequest)
            const localVarPath = `/api/v1/users/body`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subjectMetaCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserFromToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 환자 삭제(orgId를 음수로 변경, 환자 이름에 employee id 추가)
         * @summary 환자 메타데이터 삭제(soft delete)
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubjectMeta: async (subjectMetaId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectMetaId' is not null or undefined
            assertParamExists('deleteSubjectMeta', 'subjectMetaId', subjectMetaId)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subjectMetaId !== undefined) {
                localVarQueryParameter['subjectMetaId'] = subjectMetaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 환자 중복 검사
         * @param {string} [code] 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existToSubjectList: async (code?: string, phoneNumber?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/exist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary subjectMetaId 를 사용하여 사용자(MinddSurveyUser) 조회. 동의서 정보 포함 안됨
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (subjectMetaId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectMetaId' is not null or undefined
            assertParamExists('getUser', 'subjectMetaId', subjectMetaId)
            const localVarPath = `/api/v1/users/{subjectMetaId}`
                .replace(`{${"subjectMetaId"}}`, encodeURIComponent(String(subjectMetaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 특정 병원의 내 정보(MinddSurveyUser) 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser1: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 병원 사용자 목록 조회
         * @param {string} [searchKeyword] 검색 키워드. null 이면 전체를 반환. 키워드가 있다면 이름과 전화번호에서 검색
         * @param {number} [pageSize] 
         * @param {string} [pageTargetSubjectMetaId] null인 경우 가장 최근 부터, 아닌 경우 subjectMetaId 이후 부터 검색
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (searchKeyword?: string, pageSize?: number, pageTargetSubjectMetaId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchKeyword !== undefined) {
                localVarQueryParameter['searchKeyword'] = searchKeyword;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageTargetSubjectMetaId !== undefined) {
                localVarQueryParameter['pageTargetSubjectMetaId'] = pageTargetSubjectMetaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubjectMetaCreateRequest} subjectMetaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCreateUserLink: async (subjectMetaCreateRequest: SubjectMetaCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectMetaCreateRequest' is not null or undefined
            assertParamExists('sendCreateUserLink', 'subjectMetaCreateRequest', subjectMetaCreateRequest)
            const localVarPath = `/api/v1/users/send-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subjectMetaCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 기존 환자의 메타데이터(별칭, 환자코드, 생년, 성별 등)를 수정
         * @param {string} subjectId 
         * @param {SubjectMetaUpdateRequest} subjectMetaUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubjectMeta: async (subjectId: string, subjectMetaUpdateRequest: SubjectMetaUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectId' is not null or undefined
            assertParamExists('updateSubjectMeta', 'subjectId', subjectId)
            // verify required parameter 'subjectMetaUpdateRequest' is not null or undefined
            assertParamExists('updateSubjectMeta', 'subjectMetaUpdateRequest', subjectMetaUpdateRequest)
            const localVarPath = `/api/v1/users/{subjectId}/metadata`
                .replace(`{${"subjectId"}}`, encodeURIComponent(String(subjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subjectMetaUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {SubjectMetaCreateRequest} subjectMetaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserFromBody(code: string, subjectMetaCreateRequest: SubjectMetaCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserFromBody(code, subjectMetaCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.createUserFromBody']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserFromToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserFromToken(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.createUserFromToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 환자 삭제(orgId를 음수로 변경, 환자 이름에 employee id 추가)
         * @summary 환자 메타데이터 삭제(soft delete)
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubjectMeta(subjectMetaId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSubjectMeta(subjectMetaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.deleteSubjectMeta']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 환자 중복 검사
         * @param {string} [code] 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async existToSubjectList(code?: string, phoneNumber?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.existToSubjectList(code, phoneNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.existToSubjectList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary subjectMetaId 를 사용하여 사용자(MinddSurveyUser) 조회. 동의서 정보 포함 안됨
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(subjectMetaId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(subjectMetaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 특정 병원의 내 정보(MinddSurveyUser) 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser1(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser1(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.getUser1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 병원 사용자 목록 조회
         * @param {string} [searchKeyword] 검색 키워드. null 이면 전체를 반환. 키워드가 있다면 이름과 전화번호에서 검색
         * @param {number} [pageSize] 
         * @param {string} [pageTargetSubjectMetaId] null인 경우 가장 최근 부터, 아닌 경우 subjectMetaId 이후 부터 검색
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(searchKeyword?: string, pageSize?: number, pageTargetSubjectMetaId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectMetaPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(searchKeyword, pageSize, pageTargetSubjectMetaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.getUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SubjectMetaCreateRequest} subjectMetaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendCreateUserLink(subjectMetaCreateRequest: SubjectMetaCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendCreateUserLink(subjectMetaCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.sendCreateUserLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 기존 환자의 메타데이터(별칭, 환자코드, 생년, 성별 등)를 수정
         * @param {string} subjectId 
         * @param {SubjectMetaUpdateRequest} subjectMetaUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubjectMeta(subjectId: string, subjectMetaUpdateRequest: SubjectMetaUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubjectMeta(subjectId, subjectMetaUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.updateSubjectMeta']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {SubjectMetaCreateRequest} subjectMetaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserFromBody(code: string, subjectMetaCreateRequest: SubjectMetaCreateRequest, options?: any): AxiosPromise<SubjectMeta> {
            return localVarFp.createUserFromBody(code, subjectMetaCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserFromToken(options?: any): AxiosPromise<SubjectMeta> {
            return localVarFp.createUserFromToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 환자 삭제(orgId를 음수로 변경, 환자 이름에 employee id 추가)
         * @summary 환자 메타데이터 삭제(soft delete)
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubjectMeta(subjectMetaId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSubjectMeta(subjectMetaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 환자 중복 검사
         * @param {string} [code] 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existToSubjectList(code?: string, phoneNumber?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.existToSubjectList(code, phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary subjectMetaId 를 사용하여 사용자(MinddSurveyUser) 조회. 동의서 정보 포함 안됨
         * @param {string} subjectMetaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(subjectMetaId: string, options?: any): AxiosPromise<SubjectMeta> {
            return localVarFp.getUser(subjectMetaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 특정 병원의 내 정보(MinddSurveyUser) 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser1(options?: any): AxiosPromise<SubjectMeta> {
            return localVarFp.getUser1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 병원 사용자 목록 조회
         * @param {string} [searchKeyword] 검색 키워드. null 이면 전체를 반환. 키워드가 있다면 이름과 전화번호에서 검색
         * @param {number} [pageSize] 
         * @param {string} [pageTargetSubjectMetaId] null인 경우 가장 최근 부터, 아닌 경우 subjectMetaId 이후 부터 검색
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(searchKeyword?: string, pageSize?: number, pageTargetSubjectMetaId?: string, options?: any): AxiosPromise<SubjectMetaPage> {
            return localVarFp.getUsers(searchKeyword, pageSize, pageTargetSubjectMetaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubjectMetaCreateRequest} subjectMetaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCreateUserLink(subjectMetaCreateRequest: SubjectMetaCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendCreateUserLink(subjectMetaCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 기존 환자의 메타데이터(별칭, 환자코드, 생년, 성별 등)를 수정
         * @param {string} subjectId 
         * @param {SubjectMetaUpdateRequest} subjectMetaUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubjectMeta(subjectId: string, subjectMetaUpdateRequest: SubjectMetaUpdateRequest, options?: any): AxiosPromise<SubjectMeta> {
            return localVarFp.updateSubjectMeta(subjectId, subjectMetaUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} code 
     * @param {SubjectMetaCreateRequest} subjectMetaCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public createUserFromBody(code: string, subjectMetaCreateRequest: SubjectMetaCreateRequest, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).createUserFromBody(code, subjectMetaCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public createUserFromToken(options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).createUserFromToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 환자 삭제(orgId를 음수로 변경, 환자 이름에 employee id 추가)
     * @summary 환자 메타데이터 삭제(soft delete)
     * @param {string} subjectMetaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteSubjectMeta(subjectMetaId: string, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).deleteSubjectMeta(subjectMetaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 환자 중복 검사
     * @param {string} [code] 
     * @param {string} [phoneNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public existToSubjectList(code?: string, phoneNumber?: string, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).existToSubjectList(code, phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary subjectMetaId 를 사용하여 사용자(MinddSurveyUser) 조회. 동의서 정보 포함 안됨
     * @param {string} subjectMetaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUser(subjectMetaId: string, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUser(subjectMetaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 특정 병원의 내 정보(MinddSurveyUser) 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUser1(options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUser1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 병원 사용자 목록 조회
     * @param {string} [searchKeyword] 검색 키워드. null 이면 전체를 반환. 키워드가 있다면 이름과 전화번호에서 검색
     * @param {number} [pageSize] 
     * @param {string} [pageTargetSubjectMetaId] null인 경우 가장 최근 부터, 아닌 경우 subjectMetaId 이후 부터 검색
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUsers(searchKeyword?: string, pageSize?: number, pageTargetSubjectMetaId?: string, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUsers(searchKeyword, pageSize, pageTargetSubjectMetaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubjectMetaCreateRequest} subjectMetaCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public sendCreateUserLink(subjectMetaCreateRequest: SubjectMetaCreateRequest, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).sendCreateUserLink(subjectMetaCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 기존 환자의 메타데이터(별칭, 환자코드, 생년, 성별 등)를 수정
     * @param {string} subjectId 
     * @param {SubjectMetaUpdateRequest} subjectMetaUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateSubjectMeta(subjectId: string, subjectMetaUpdateRequest: SubjectMetaUpdateRequest, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateSubjectMeta(subjectId, subjectMetaUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

