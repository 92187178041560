/* tslint:disable */
/* eslint-disable */
/**
 * MINDD Survey API Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Invoice } from '../model';
// @ts-ignore
import type { InvoiceEntity } from '../model';
// @ts-ignore
import type { InvoicePage } from '../model';
// @ts-ignore
import type { InvoiceUpdateRequest } from '../model';
/**
 * InvoiceControllerApi - axios parameter creator
 * @export
 */
export const InvoiceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary [Backoffice] 모든 병원의 예상 청구 내역을 가져온다.
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimatedInvoicesForAllOrganizations: async (yearMonth: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('getEstimatedInvoicesForAllOrganizations', 'yearMonth', yearMonth)
            const localVarPath = `/api/v1/invoices/admin/estimated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 발행된 청구서들 pagination
         * @param {number} pageNumber 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices: async (pageNumber: number, limit: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getInvoices', 'pageNumber', pageNumber)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getInvoices', 'limit', limit)
            const localVarPath = `/api/v1/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 조건에 맞는 발행된 청구서들 전달
         * @param {string} [yearMonth] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<GetInvoicesByStatusEnum>} [status] 
         * @param {GetInvoicesBySortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesBy: async (yearMonth?: string, from?: string, to?: string, status?: Array<GetInvoicesByStatusEnum>, sort?: GetInvoicesBySortEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/invoices/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Backoffice] 조건에 맞는 발행된 청구서를 전달
         * @param {number} [orgId] 
         * @param {string} [yearMonth] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<GetInvoicesForYbrainByStatusEnum>} [status] 
         * @param {GetInvoicesForYbrainBySortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesForYbrainBy: async (orgId?: number, yearMonth?: string, from?: string, to?: string, status?: Array<GetInvoicesForYbrainByStatusEnum>, sort?: GetInvoicesForYbrainBySortEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/invoices/admin/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Backoffice] 청구서의 상태를 ISSUED 혹은 EXEMPTED 로 갱신
         * @param {string} invoiceId 
         * @param {InvoiceUpdateRequest} invoiceUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceStatus: async (invoiceId: string, invoiceUpdateRequest: InvoiceUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('updateInvoiceStatus', 'invoiceId', invoiceId)
            // verify required parameter 'invoiceUpdateRequest' is not null or undefined
            assertParamExists('updateInvoiceStatus', 'invoiceUpdateRequest', invoiceUpdateRequest)
            const localVarPath = `/api/v1/invoices/{invoiceId}/status`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceControllerApi - functional programming interface
 * @export
 */
export const InvoiceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary [Backoffice] 모든 병원의 예상 청구 내역을 가져온다.
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstimatedInvoicesForAllOrganizations(yearMonth: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEstimatedInvoicesForAllOrganizations(yearMonth, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.getEstimatedInvoicesForAllOrganizations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 발행된 청구서들 pagination
         * @param {number} pageNumber 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoices(pageNumber: number, limit: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoices(pageNumber, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.getInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 조건에 맞는 발행된 청구서들 전달
         * @param {string} [yearMonth] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<GetInvoicesByStatusEnum>} [status] 
         * @param {GetInvoicesBySortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicesBy(yearMonth?: string, from?: string, to?: string, status?: Array<GetInvoicesByStatusEnum>, sort?: GetInvoicesBySortEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicesBy(yearMonth, from, to, status, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.getInvoicesBy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Backoffice] 조건에 맞는 발행된 청구서를 전달
         * @param {number} [orgId] 
         * @param {string} [yearMonth] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<GetInvoicesForYbrainByStatusEnum>} [status] 
         * @param {GetInvoicesForYbrainBySortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicesForYbrainBy(orgId?: number, yearMonth?: string, from?: string, to?: string, status?: Array<GetInvoicesForYbrainByStatusEnum>, sort?: GetInvoicesForYbrainBySortEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicesForYbrainBy(orgId, yearMonth, from, to, status, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.getInvoicesForYbrainBy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Backoffice] 청구서의 상태를 ISSUED 혹은 EXEMPTED 로 갱신
         * @param {string} invoiceId 
         * @param {InvoiceUpdateRequest} invoiceUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvoiceStatus(invoiceId: string, invoiceUpdateRequest: InvoiceUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvoiceStatus(invoiceId, invoiceUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.updateInvoiceStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvoiceControllerApi - factory interface
 * @export
 */
export const InvoiceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary [Backoffice] 모든 병원의 예상 청구 내역을 가져온다.
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimatedInvoicesForAllOrganizations(yearMonth: string, options?: any): AxiosPromise<Array<Invoice>> {
            return localVarFp.getEstimatedInvoicesForAllOrganizations(yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 발행된 청구서들 pagination
         * @param {number} pageNumber 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices(pageNumber: number, limit: number, options?: any): AxiosPromise<InvoicePage> {
            return localVarFp.getInvoices(pageNumber, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 조건에 맞는 발행된 청구서들 전달
         * @param {string} [yearMonth] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<GetInvoicesByStatusEnum>} [status] 
         * @param {GetInvoicesBySortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesBy(yearMonth?: string, from?: string, to?: string, status?: Array<GetInvoicesByStatusEnum>, sort?: GetInvoicesBySortEnum, options?: any): AxiosPromise<Array<Invoice>> {
            return localVarFp.getInvoicesBy(yearMonth, from, to, status, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Backoffice] 조건에 맞는 발행된 청구서를 전달
         * @param {number} [orgId] 
         * @param {string} [yearMonth] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {Array<GetInvoicesForYbrainByStatusEnum>} [status] 
         * @param {GetInvoicesForYbrainBySortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesForYbrainBy(orgId?: number, yearMonth?: string, from?: string, to?: string, status?: Array<GetInvoicesForYbrainByStatusEnum>, sort?: GetInvoicesForYbrainBySortEnum, options?: any): AxiosPromise<Array<Invoice>> {
            return localVarFp.getInvoicesForYbrainBy(orgId, yearMonth, from, to, status, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Backoffice] 청구서의 상태를 ISSUED 혹은 EXEMPTED 로 갱신
         * @param {string} invoiceId 
         * @param {InvoiceUpdateRequest} invoiceUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceStatus(invoiceId: string, invoiceUpdateRequest: InvoiceUpdateRequest, options?: any): AxiosPromise<InvoiceEntity> {
            return localVarFp.updateInvoiceStatus(invoiceId, invoiceUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceControllerApi - object-oriented interface
 * @export
 * @class InvoiceControllerApi
 * @extends {BaseAPI}
 */
export class InvoiceControllerApi extends BaseAPI {
    /**
     * 
     * @summary [Backoffice] 모든 병원의 예상 청구 내역을 가져온다.
     * @param {string} yearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public getEstimatedInvoicesForAllOrganizations(yearMonth: string, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).getEstimatedInvoicesForAllOrganizations(yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 발행된 청구서들 pagination
     * @param {number} pageNumber 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public getInvoices(pageNumber: number, limit: number, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).getInvoices(pageNumber, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 조건에 맞는 발행된 청구서들 전달
     * @param {string} [yearMonth] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {Array<GetInvoicesByStatusEnum>} [status] 
     * @param {GetInvoicesBySortEnum} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public getInvoicesBy(yearMonth?: string, from?: string, to?: string, status?: Array<GetInvoicesByStatusEnum>, sort?: GetInvoicesBySortEnum, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).getInvoicesBy(yearMonth, from, to, status, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Backoffice] 조건에 맞는 발행된 청구서를 전달
     * @param {number} [orgId] 
     * @param {string} [yearMonth] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {Array<GetInvoicesForYbrainByStatusEnum>} [status] 
     * @param {GetInvoicesForYbrainBySortEnum} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public getInvoicesForYbrainBy(orgId?: number, yearMonth?: string, from?: string, to?: string, status?: Array<GetInvoicesForYbrainByStatusEnum>, sort?: GetInvoicesForYbrainBySortEnum, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).getInvoicesForYbrainBy(orgId, yearMonth, from, to, status, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Backoffice] 청구서의 상태를 ISSUED 혹은 EXEMPTED 로 갱신
     * @param {string} invoiceId 
     * @param {InvoiceUpdateRequest} invoiceUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public updateInvoiceStatus(invoiceId: string, invoiceUpdateRequest: InvoiceUpdateRequest, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).updateInvoiceStatus(invoiceId, invoiceUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetInvoicesByStatusEnum = {
    Estimated: 'ESTIMATED',
    Issued: 'ISSUED',
    Confirmed: 'CONFIRMED',
    Complete: 'COMPLETE',
    PaymentRequested: 'PAYMENT_REQUESTED',
    PaymentCancelRequested: 'PAYMENT_CANCEL_REQUESTED',
    Exempted: 'EXEMPTED'
} as const;
export type GetInvoicesByStatusEnum = typeof GetInvoicesByStatusEnum[keyof typeof GetInvoicesByStatusEnum];
/**
 * @export
 */
export const GetInvoicesBySortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetInvoicesBySortEnum = typeof GetInvoicesBySortEnum[keyof typeof GetInvoicesBySortEnum];
/**
 * @export
 */
export const GetInvoicesForYbrainByStatusEnum = {
    Estimated: 'ESTIMATED',
    Issued: 'ISSUED',
    Confirmed: 'CONFIRMED',
    Complete: 'COMPLETE',
    PaymentRequested: 'PAYMENT_REQUESTED',
    PaymentCancelRequested: 'PAYMENT_CANCEL_REQUESTED',
    Exempted: 'EXEMPTED'
} as const;
export type GetInvoicesForYbrainByStatusEnum = typeof GetInvoicesForYbrainByStatusEnum[keyof typeof GetInvoicesForYbrainByStatusEnum];
/**
 * @export
 */
export const GetInvoicesForYbrainBySortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetInvoicesForYbrainBySortEnum = typeof GetInvoicesForYbrainBySortEnum[keyof typeof GetInvoicesForYbrainBySortEnum];
