import { useFetchCurrentUser, useFetchOrganizationOfCurrentUser, useUpdatePassword } from '@MP/api/authApi';
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import { RectangleButton } from '../Button';
import React, { FC, FormEvent } from 'react';
import usePasswordInputProps from './usePasswordInputProps';
import { AxiosError } from 'axios';
import { useMinddSnackbar } from '../MinddSnackbarProvider';
import PasswordInput from './PasswordInput';

const AccountDialog: FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const [mismatchPw, setMismatchPw] = React.useState(false);
  const [editPw, setEditPw] = React.useState(false);
  const [currPw, setCurrPw] = React.useState('');
  const [newPw, setNewPw] = React.useState('');
  const [confirmPw, setConfirmPw] = React.useState('');

  const snackbar = useMinddSnackbar();
  const { data: user } = useFetchCurrentUser();
  const { data: org } = useFetchOrganizationOfCurrentUser();
  const { mutate: updatePassword, isPending } = useUpdatePassword();

  const { currPwProps, newPwProps, confirmPwProps, currPwRef, isValid } = usePasswordInputProps(
    currPw,
    newPw,
    confirmPw,
    (pw) => {
      setMismatchPw(false);
      setCurrPw(pw);
    },
    (pw, confirm) => {
      setNewPw(pw);
      setConfirmPw(confirm);
    }
  );

  const handleUpdatePw = (e: FormEvent) => {
    e.preventDefault();

    updatePassword(
      {
        oldPassword: currPw,
        newPassword: newPw,
      },
      {
        onSuccess: () => {
          snackbar.add('비밀번호가 변경되었습니다.');
          setEditPw(false);
        },
        onError: (e: AxiosError<{ errorCode: string }>) => {
          if (e.response?.data?.errorCode === 'PasswordIsNotCorrectedException') {
            setMismatchPw(true);
            currPwRef.current?.focus();
          }
        },
      }
    );
  };

  return (
    <Modal open={open}>
      <ModalDialog component={'form'} onSubmit={handleUpdatePw}>
        <Typography level="h4" fontWeight={600}>
          {editPw ? '비밀번호 변경' : '내 정보'}
        </Typography>
        <DialogContent>
          <Stack width={500} spacing={'8px'}>
            {!editPw && (
              <>
                <FormControl orientation="horizontal">
                  <FormLabel sx={{ minWidth: 100 }}>이메일: </FormLabel>
                  <Input value={user?.email} readOnly size="lg" fullWidth />
                </FormControl>
                <FormControl orientation="horizontal">
                  <FormLabel sx={{ minWidth: 100 }}>이름: </FormLabel>
                  <Input value={user?.name} readOnly size="lg" fullWidth />
                </FormControl>
                <FormControl orientation="horizontal">
                  <FormLabel sx={{ minWidth: 100 }}>병원 이름: </FormLabel>
                  <Input value={org?.name} readOnly size="lg" fullWidth />
                </FormControl>
                <FormControl orientation="horizontal">
                  <FormLabel sx={{ minWidth: 100 }}>병원 진료과: </FormLabel>
                  <Input value={org?.department} readOnly size="lg" fullWidth />
                </FormControl>
                <FormControl orientation="horizontal">
                  <FormLabel sx={{ minWidth: 100 }}>병원 코드: </FormLabel>
                  <Input value={org?.code} readOnly size="lg" fullWidth />
                </FormControl>
              </>
            )}
            {editPw && (
              <>
                <PasswordInput
                  {...currPwProps}
                  error={mismatchPw}
                  helperText={mismatchPw ? '비밀번호가 올바르지 않습니다.' : ''}
                />
                <PasswordInput {...newPwProps} />
                <PasswordInput {...confirmPwProps} />
              </>
            )}
          </Stack>
        </DialogContent>
        {!editPw && (
          <DialogActions>
            <RectangleButton onClick={onClose}>닫기</RectangleButton>
            <RectangleButton variant="outlined" onClick={() => setEditPw(true)}>
              비밀번호 변경
            </RectangleButton>
          </DialogActions>
        )}
        {editPw && (
          <DialogActions>
            <RectangleButton type="submit" disabled={!isValid} loading={isPending}>
              확인
            </RectangleButton>
            <RectangleButton variant="outlined" onClick={() => setEditPw(false)}>
              취소
            </RectangleButton>
          </DialogActions>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default AccountDialog;
