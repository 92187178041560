/* tslint:disable */
/* eslint-disable */
/**
 * MINDD Survey API Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { PaidRecordStatus } from '../model';
// @ts-ignore
import type { PaidSurveyRecord } from '../model';
/**
 * PaidSurveyRecordControllerApi - axios parameter creator
 * @export
 */
export const PaidSurveyRecordControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 월별 이용 내역 전달
         * @param {string} yearMonth 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyRecords: async (yearMonth: string, statusList?: Array<PaidRecordStatus>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('getMonthlyRecords', 'yearMonth', yearMonth)
            const localVarPath = `/api/v1/paid-surveys/monthly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }

            if (statusList) {
                localVarQueryParameter['statusList'] = statusList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 기간별 이용 내역 전달
         * @param {string} from 
         * @param {string} to 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRangeRecords: async (from: string, to: string, statusList?: Array<PaidRecordStatus>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getRangeRecords', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getRangeRecords', 'to', to)
            const localVarPath = `/api/v1/paid-surveys/range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (statusList) {
                localVarQueryParameter['statusList'] = statusList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 청구서별 이용 내역 전달
         * @param {string} invoiceId 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordByInvoice: async (invoiceId: string, statusList?: Array<PaidRecordStatus>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getRecordByInvoice', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/paid-surveys/invoice/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (statusList) {
                localVarQueryParameter['statusList'] = statusList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaidSurveyRecordControllerApi - functional programming interface
 * @export
 */
export const PaidSurveyRecordControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaidSurveyRecordControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 월별 이용 내역 전달
         * @param {string} yearMonth 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyRecords(yearMonth: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaidSurveyRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyRecords(yearMonth, statusList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaidSurveyRecordControllerApi.getMonthlyRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 기간별 이용 내역 전달
         * @param {string} from 
         * @param {string} to 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRangeRecords(from: string, to: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaidSurveyRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRangeRecords(from, to, statusList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaidSurveyRecordControllerApi.getRangeRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 청구서별 이용 내역 전달
         * @param {string} invoiceId 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordByInvoice(invoiceId: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaidSurveyRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordByInvoice(invoiceId, statusList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaidSurveyRecordControllerApi.getRecordByInvoice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaidSurveyRecordControllerApi - factory interface
 * @export
 */
export const PaidSurveyRecordControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaidSurveyRecordControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 월별 이용 내역 전달
         * @param {string} yearMonth 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyRecords(yearMonth: string, statusList?: Array<PaidRecordStatus>, options?: any): AxiosPromise<Array<PaidSurveyRecord>> {
            return localVarFp.getMonthlyRecords(yearMonth, statusList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 기간별 이용 내역 전달
         * @param {string} from 
         * @param {string} to 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRangeRecords(from: string, to: string, statusList?: Array<PaidRecordStatus>, options?: any): AxiosPromise<Array<PaidSurveyRecord>> {
            return localVarFp.getRangeRecords(from, to, statusList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 청구서별 이용 내역 전달
         * @param {string} invoiceId 
         * @param {Array<PaidRecordStatus>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordByInvoice(invoiceId: string, statusList?: Array<PaidRecordStatus>, options?: any): AxiosPromise<Array<PaidSurveyRecord>> {
            return localVarFp.getRecordByInvoice(invoiceId, statusList, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaidSurveyRecordControllerApi - object-oriented interface
 * @export
 * @class PaidSurveyRecordControllerApi
 * @extends {BaseAPI}
 */
export class PaidSurveyRecordControllerApi extends BaseAPI {
    /**
     * 
     * @summary 월별 이용 내역 전달
     * @param {string} yearMonth 
     * @param {Array<PaidRecordStatus>} [statusList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaidSurveyRecordControllerApi
     */
    public getMonthlyRecords(yearMonth: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig) {
        return PaidSurveyRecordControllerApiFp(this.configuration).getMonthlyRecords(yearMonth, statusList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 기간별 이용 내역 전달
     * @param {string} from 
     * @param {string} to 
     * @param {Array<PaidRecordStatus>} [statusList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaidSurveyRecordControllerApi
     */
    public getRangeRecords(from: string, to: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig) {
        return PaidSurveyRecordControllerApiFp(this.configuration).getRangeRecords(from, to, statusList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 청구서별 이용 내역 전달
     * @param {string} invoiceId 
     * @param {Array<PaidRecordStatus>} [statusList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaidSurveyRecordControllerApi
     */
    public getRecordByInvoice(invoiceId: string, statusList?: Array<PaidRecordStatus>, options?: RawAxiosRequestConfig) {
        return PaidSurveyRecordControllerApiFp(this.configuration).getRecordByInvoice(invoiceId, statusList, options).then((request) => request(this.axios, this.basePath));
    }
}

