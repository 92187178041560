const PhoneNumberUtils = {
  appendDash: (phoneNumber: string) => {
    return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  },

  isValid: (phoneNumber?: string) => {
    if (phoneNumber) {
      return new RegExp(/(^010-(?:\d{4})-\d{4}$)|(^(010)\d{4}\d{4}$)/).test(phoneNumber);
    }
    return false;
  },
};

export default PhoneNumberUtils;
