import Cookies from 'js-cookie';

const CookieUtils = {
  setOneDay: (key: string) => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    Cookies.set(key, '1', { expires: date });
  },
};
export default CookieUtils;
