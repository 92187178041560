import { Agreement, SubjectMeta } from '@MP/api/survey';
import { makeAutoObservable } from 'mobx';

class PatientStore {
  expanded: boolean = false;
  users: SubjectMeta[] = [];
  private _userId: string | undefined;
  private agreements: Record<string, Agreement[]> = {};

  constructor() {
    makeAutoObservable(this);
  }

  setUsers(users: SubjectMeta[] = []) {
    this.users = users;
  }

  setUserAgreements(subjectMetaId: string, agreements: Agreement[]) {
    this.agreements[subjectMetaId] = agreements;
  }

  addUser(patient: SubjectMeta) {
    this.users = [patient, ...this.users];
    this._userId = patient.id;
  }

  deleteUser(subjectMetaId: string) {
    this.users = this.users.filter((u) => u.id !== subjectMetaId);
    if (this._userId === subjectMetaId && this.users.length > 0) {
      this._userId = this.users[0].id;
    }
  }

  updateUser(user: SubjectMeta, editInfo: boolean = false) {
    if (editInfo) {
      const currentIdx = this.users.findIndex((u) => u.id === user.id);

      if (currentIdx > -1) {
        this.users[currentIdx] = { ...user };

        this.users = [...this.users];
      }
    } else {
      this.users = [user, ...this.users.filter((u) => u.id !== user.id)];
    }
  }

  setUser(user: SubjectMeta) {
    this._userId = user.id;
  }

  setUserId(userId?: string) {
    if (userId) {
      this._userId = userId;
    } else {
      this._userId = undefined;
    }
  }

  get user() {
    if (this._userId) {
      return this.users.find((v) => v.id === this._userId);
    }
    return this.users[0];
  }

  get userAgreements() {
    if (this.user) {
      return this.agreements[this.user.id];
    }
    return undefined;
  }

  get isSurveyAgreed() {
    return !!this.userAgreements?.find((v) => v.service === 'THIRD_PARTY');
  }

  get isExpiredSurveyAgree() {
    return this.userAgreements?.find((v) => v.service === 'THIRD_PARTY')?.expired;
  }

  get isSleepAgreed() {
    return (
      !!this.userAgreements?.find((v) => v.service === 'THIRD_PARTY') &&
      !!this.userAgreements?.find((v) => v.service === 'SENSITIVE_INFO')
    );
  }

  get isExpiredSleepAgree() {
    return (
      this.userAgreements?.find((v) => v.service === 'THIRD_PARTY')?.expired ||
      this.userAgreements?.find((v) => v.service === 'SENSITIVE_INFO')?.expired
    );
  }

  get isStimAgreed() {
    return !!this.userAgreements?.find((v) => v.service === 'STIM');
  }
}

export default PatientStore;
