const ReplaceUtils = {
  replaceName: (name: string) => {
    return name.replace(/[^ㄱ-힣a-zA-Z]/gi, '');
  },
  replaceNumber: (value: string) => {
    return value.replace(/[^0-9]/gi, '');
  },
  replaceDepartment: (department: string) => {
    return department.replace(/[^ㄱ-힣a-zA-Z0-9]/gi, '').replace(' ', '');
  },
  format: (str: string, ...args: string[]): string => {
    return str.replace(/{(\d+)}/g, (match, number) => {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  },
  bizCodeFormat: (value: string) => {
    return value.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
  },
};

export default ReplaceUtils;
