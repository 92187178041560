import { makeAutoObservable } from 'mobx';
import React from 'react';
import {
  CreateEmployeeDto,
  DepartmentDto,
  EmployeeResponseDto,
  EmployeeResponseDtoRoleEnum,
  OrganizationSummaryDto,
} from '@MP/api/auth/public1.0';
import ReplaceUtils from '@MP/util/ReplaceUtils';
import { AutoDepartment, OrganizationItem, OrgItem } from '../types';

const defaultDepartmentName: string[] = ['정신건강의학과', '신경정신과'];

export default class SignUpStore {
  organizations: OrganizationItem[] = [];
  selectedOrganization: OrgItem | undefined;
  employee: CreateEmployeeDto = {};
  confirmPassword: string = '';
  department: AutoDepartment | undefined;
  existDepartments: AutoDepartment[] = [];
  certNumber: string = '';
  certId: number | undefined;

  certOK: boolean | undefined;
  isMaster: boolean = false;

  createdEmployee: EmployeeResponseDto | undefined;

  eulaAgree = false;
  privacyAgree = false;

  constructor() {
    makeAutoObservable(this);
  }

  setAllAgree(check: boolean) {
    this.setEulaAgree(check);
    this.setPrivacyAgree(check);
    this.setSubscribeMarketingEvent(check);
  }

  setEulaAgree(check: boolean) {
    this.eulaAgree = check;
  }

  setPrivacyAgree(check: boolean) {
    this.privacyAgree = check;
  }

  get isRequiredAllAgree() {
    return this.eulaAgree && this.privacyAgree;
  }

  setSubscribeMarketingEvent(check: boolean) {
    this.employee.isSubscribeMarketingEvent = check;
  }

  get subscribeMarketingEvent() {
    return this.employee.isSubscribeMarketingEvent;
  }

  get isAllAgree() {
    return this.isRequiredAllAgree && !!this.subscribeMarketingEvent;
  }

  setEmail(email: string) {
    this.employee.email = email.trim();
  }

  setName(name: string) {
    this.employee.name = name.trim();
  }

  setPassword(pw: string, confirm: string) {
    this.employee.password = pw.trim();
    this.confirmPassword = confirm.trim();
  }

  setDepartment(department: AutoDepartment | undefined) {
    if (department) {
      department.add = false;
    }
    this.department = department;
  }

  setTel(tel: string) {
    this.employee.tel = ReplaceUtils.replaceNumber(tel);
    this.setCertOK(undefined);
  }

  setCertNumber(certNumber: string) {
    this.certNumber = ReplaceUtils.replaceNumber(certNumber);
    this.setCertOK(undefined);
  }

  setOrganizations(organizations: OrganizationSummaryDto[]) {
    this.setSelectedOrganization(undefined);
    this.organizations = organizations.map((org) => new OrganizationItem(org));
  }

  setDepartments(departments: DepartmentDto[] | undefined) {
    if (departments) {
      this.existDepartments = departments;

      const names = this.existDepartments.map((v) => v.name);

      defaultDepartmentName.forEach((v) => {
        if (!names.includes(v)) {
          this.existDepartments.push({ name: v });
        }
      });
    } else {
      this.existDepartments = defaultDepartmentName.map((v) => {
        return { name: v };
      });
    }
    this.setDepartment(this.existDepartments[0]);
  }

  setSelectedOrganization(organization: OrgItem | undefined) {
    this.selectedOrganization = organization;
    this.setDepartment(undefined);
  }

  setCertId(certId: number) {
    this.certId = certId;
  }

  setCertOK(ok?: boolean) {
    this.certOK = ok;
  }

  setCreatedEmployee(createdEmployee: EmployeeResponseDto) {
    this.createdEmployee = createdEmployee;
    this.isMaster = createdEmployee?.role === EmployeeResponseDtoRoleEnum.UAdmin;
  }

  get creationEmployeeModel() {
    const model = { ...this.employee };
    model.orgzId = this.department?.orgzId;
    this.selectedOrganization?.setDepartment(this.department?.name || '');
    model.organization = this.selectedOrganization?.getRequest();
    return model;
  }

  get isPossibleSignup() {
    return (
      this.selectedOrganization &&
      this.certId &&
      this.certNumber &&
      this.certOK &&
      this.employee.password === this.confirmPassword
    );
  }
}

export const SignUpStoreContext: React.Context<SignUpStore> = React.createContext<SignUpStore>({} as SignUpStore);

export const useSignUpStore = () => React.useContext(SignUpStoreContext);
