/* tslint:disable */
/* eslint-disable */
/**
 * MINDD Survey API Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { InvoiceEntity } from '../model';
// @ts-ignore
import type { LastPaymentStatus } from '../model';
/**
 * PaymentControllerApi - axios parameter creator
 * @export
 */
export const PaymentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 청구서 결제 취소
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel: async (invoiceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('cancel', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/payments/api/v1/payments/cancel                 `;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedback: async (body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('feedback', 'body', body)
            const localVarPath = `/api/v1/payments/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 가장 최근 청구서의 가장 최근 결제 상태 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastPaymentStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payments/last/payment-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentControllerApi - functional programming interface
 * @export
 */
export const PaymentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 청구서 결제 취소
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancel(invoiceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancel(invoiceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentControllerApi.cancel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedback(body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedback(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentControllerApi.feedback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 가장 최근 청구서의 가장 최근 결제 상태 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastPaymentStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastPaymentStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastPaymentStatus(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentControllerApi.getLastPaymentStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentControllerApi - factory interface
 * @export
 */
export const PaymentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 청구서 결제 취소
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel(invoiceId: string, options?: any): AxiosPromise<Array<InvoiceEntity>> {
            return localVarFp.cancel(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedback(body: string, options?: any): AxiosPromise<string> {
            return localVarFp.feedback(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 가장 최근 청구서의 가장 최근 결제 상태 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastPaymentStatus(options?: any): AxiosPromise<LastPaymentStatus> {
            return localVarFp.getLastPaymentStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentControllerApi - object-oriented interface
 * @export
 * @class PaymentControllerApi
 * @extends {BaseAPI}
 */
export class PaymentControllerApi extends BaseAPI {
    /**
     * 
     * @summary 청구서 결제 취소
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public cancel(invoiceId: string, options?: RawAxiosRequestConfig) {
        return PaymentControllerApiFp(this.configuration).cancel(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public feedback(body: string, options?: RawAxiosRequestConfig) {
        return PaymentControllerApiFp(this.configuration).feedback(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 가장 최근 청구서의 가장 최근 결제 상태 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public getLastPaymentStatus(options?: RawAxiosRequestConfig) {
        return PaymentControllerApiFp(this.configuration).getLastPaymentStatus(options).then((request) => request(this.axios, this.basePath));
    }
}

